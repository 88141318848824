import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TenderPageService } from '../vendor-mapping-page/tendor-page.service';
import { TenderMapping } from '../vendor-mapping-page/vendor-mapping-model';
import { SearchResult } from 'src/app/shared/models/search-result';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';
import { userRoles } from 'src/app/shared/models/user-role-code';

@Component({
  selector: 'app-vendor-mapping-table',
  templateUrl: './vendor-mapping-table.component.html',
  styleUrls: ['./vendor-mapping-table.component.scss'],
})
export class VendorMappingTableComponent implements OnInit {
  userContextSubscription: Subscription;
  itemsPerPage: number = 10;
  user: any;
  page: number;
  searchResult: SearchResult<TenderMapping> = new SearchResult<TenderMapping>();
  cols:any;
  allowEdit: boolean;
  enableApprove: boolean;
  isChecker: boolean;
  workId: any;
  levelMasterId: number;
  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    protected paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    public fontService: FontService,
    private loginService: SigninService,
    private tenderPageService: TenderPageService,
    private userRoleService: UserRoleService,
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        field: 'workId',
        header: 'Work Id',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'work',
        header: 'Name Of Work',
        isSortable: true,
        isFilterable: false,
        width: 10,
        type: "dropDown_with_other_field",
        Dfield: "projectName",
        isSelectcolumn: true,
      },
      {
        field: 'tenderNo',
        header: 'Tender No',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
      {
        field: 'tenderDate',
        header: 'Tender Date',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        type:'date'
      },
     
      {
        field: 'workProcurementDate',
        header: 'Work Order Date',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
        type:'date'
      },
     
    ];
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    let Query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      ...(this.user?.role?.code !== userRoles.roleState && {levelMasterId: this.user?.levelMaster.id}),
     
    };
    if(this.workId) {
      Query['workId'] = this.workId;
    }
    
    this.tenderPageService.filter({ ...Query }).subscribe(
      (res: HttpResponse<TenderMapping[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      () => {
        this.onError();
        this.spinner.hide();
      }
    );
  }

  protected onSuccess(data:TenderMapping[]  | null, headers: HttpHeaders, page: number, navigate: boolean): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    // this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
   
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    console.log('event',event.filterDefinitions[0]?.value);
    if(event.filterDefinitions[0]?.value) {
      this.workId = event.filterDefinitions[0]?.value;
    }
    else {
      this.workId = null;
    }
    this.userContextSubscription = this.userRoleService.getuserContext().subscribe(
      res => {
        this.user = res;
        console.log('usera',res.levelMaster.id)
        this.levelMasterId = res.levelMaster.id;
        if (res.role.code==userRoles.roleDMaker || res.role.code==userRoles.roleBMaker) {
          this.allowEdit = true;
        } else if(res.role.code==userRoles.roleDChecker1){
          this.enableApprove=true;
        } else if (res.role.code== userRoles.roleBChecker2){
          this.enableApprove=true;
        }
        else if(res.role.code == userRoles.roleState){
          this.enableApprove=true;
        }
        if(res.role.code==userRoles.roleBChecker1 || res.role.code==userRoles.roleBChecker2 
          || res.role.code==userRoles.roleDChecker1 || res.role.code==userRoles.roleDChecker2
          || res.role.code==userRoles.roleZonal1 || res.role.code==userRoles.roleZonal2 ||  res.role.code==userRoles.roleZonal3
          || res.role.code==userRoles.roleZonal4 || res.role.code==userRoles.roleZonal5)
        {
          this.isChecker=true;
        }
        this.loadPage(event.page);
      },
      () => {}
    );
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.router.navigate(['/tender-page'], {
        queryParams: {
          id: $event.value.id,
        },
      });
    }
    else if ($event.type === "view") {
      this.router.navigate(["/tender-page"], {
        queryParams: {
          id: $event.value.id,
          view: true,
        },
      });
    }

    else if ($event.type === 'approve') {
      this.router.navigate(["/tender-page"], {
        queryParams: {
          id: $event.value.id,
          view: true,
          approve:true
        },
      });
    }
  }

  openNew() {
    this.router.navigate(['/tender-page']);
  }
}
