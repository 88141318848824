<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="funds.opening.balance.name">Opening Balance
                    </h5>

                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="enableAdd" pButton pRipple icon="pi pi-plus" class="p-button-success custom-button"
                        (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton">
                        </span>
                    </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult"
                (emitRow)="onSelectRow($event)" [disableDeleteIcon]="true"
                [disableEditIcon]="true"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="openingBalanceDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog"
        [style]="{ width: '850px' }">
        <p-header [ngClass]="fontService.headingClass">
            {{"funds.Add.opening.balance.name" | translate}}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass" jhiTranslate="funds.budget.for.scheme.name">Budget
                                for Scheme</h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass"
                                jhiTranslate="funds.department.name">Department</label>
                            <ng-select id="department" [(ngModel)]="openingBalance.departmentDto"
                                [items]="departmentList" bindLabel="name" appearance="outline" [closeOnSelect]="true"
                                class="form-control" [clearable]="true"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !openingBalance.departmentDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass"
                                jhiTranslate="funds.scheme.name">Scheme</label>
                            <ng-select id="scheme" [(ngModel)]="openingBalance.schemeDto" [items]="schemeList"
                                bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control"
                                [clearable]="true"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !openingBalance.schemeDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass"
                                jhiTranslate="date.name">Date</label>
                            <p-calendar [(ngModel)]="openingBalance.dateOfAob" [minDate]="today"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !openingBalance.dateOfAob }"
                                appendTo="body"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="openingBalance"
                            [ngClass]="fontService.labelClass" jhiTranslate="funds.opening.balance.name">Opening Balance</label>
                            <input type="text" id="openingBalance" name="openingBalance" autocomplete="off" class="form-control"
                            oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                            maxlength="25"
                                [(ngModel)]="openingBalance.openingBalance"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !openingBalance.openingBalance }" />

                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
                    class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton">
                </span> </button>
            <button pButton pRipple *ngIf="openingBalance.id" icon="pi pi-check" class="p-button-text custom-button"
                (click)="saveData()"><span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.savebutton">
                </span> </button>
            <button pButton pRipple *ngIf="!openingBalance.id" icon="pi pi-check" class="p-button-text custom-button"
                (click)="saveData()"><span class="button-label" [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.addbutton">
                </span> </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>