import { DashboardCard, FundTransactions, ProjectSummary,ProjectSummaryByLevel } from './../dashboard/dashboard';
import { DashboardService } from 'src/app/dashBoards/dashboard/dashboard.service';

import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserContext, UserRoleService } from 'src/app/shared/service/user-role.service';
import { FundsService } from 'src/app/module/funds/funds.service';
import { Funds } from 'src/app/module/funds/funds';
import enIN from '@angular/common/locales/en-IN';
import {registerLocaleData } from '@angular/common';
import { LevelMaster } from 'src/app/module/level-master/level-master';

import { filterQuery } from 'src/app/shared/common.model';
import { LimitSetService } from 'src/app/module/limit-set/limit-set.service';
import { LimitSet } from 'src/app/module/limit-set/limit-set';

@Component({
  selector: 'app-block-dashboard',
  templateUrl: './block-dashboard.component.html',
  styleUrls: ['./block-dashboard.component.scss']
})
export class BlockDashboardComponent implements OnInit {
  dashboardCardDetails:DashboardCard;
  fundTransactions:FundTransactions;
  activeIndex = 0;
  fundLimitActiveIndex = 0;
  exprLimitActiveIndex = 0;
  balLimitActiveIndex = 0;
  workActiveIndex = 0;
  limitFundByYear: any;
  fundExpenditure: FundTransactions;
  fundBalance: FundTransactions;
  expenditureByYear: any;
  balanceByYear: any;
  projectSummary: ProjectSummary;
  projectSummaryByLevel:ProjectSummaryByLevel;
  levelMasterId:number;
  funds: LimitSet[];
  userContextSubscription: Subscription;
  filterProps: any[];
  
  constructor(private dashboardService:DashboardService, private fundsService: FundsService,
    public fontService:FontService, public userRoleService:UserRoleService,
    public limitSetService:LimitSetService) { 
    registerLocaleData(enIN);
    }

  
  
  ngOnInit(): void {
    this.userContextSubscription=this.userRoleService.getuserContext().subscribe(
      (res)=>{
        this.levelMasterId = res.levelMaster.id;
    },
    (error)=>{
      
    })
    this.dashboardService.getDashboardCardDetails(this.levelMasterId).subscribe(
      (res)=>{
        this.dashboardCardDetails=res.body;
      },
      (error)=>{}
    )
    let query = {
		  page: 0,
		  size: 10,
		};
    this.filterProps = this.filterProps ?? []
    let filquery = new filterQuery();
    filquery.key = 'levelMaster.id';
    filquery.operation = 'equals';
    filquery.value =this.levelMasterId ;
    this.filterProps.push(filquery);
		this.limitSetService
		  .filter({ ...query},this.filterProps)
		  .subscribe(
			(res: HttpResponse<LimitSet[]>) => {
        this.funds = res.body;
      },
      () => {
       
      }
    );
    this.dashboardService.fundsTransactionByYear(this.levelMasterId).subscribe(
      (res)=>{
        this.fundTransactions=res.body;
      },
      (error)=>{}
    )
    this.dashboardService.fundsExpenditureByYear(this.levelMasterId).subscribe(
      (res)=>{
        this.fundExpenditure=res.body;
      },
      (error)=>{}
    )

    this.dashboardService.fundsBalanceByYear(this.levelMasterId).subscribe(
      (res)=>{
        this.fundBalance=res.body;
      },
      (error)=>{}
    )
    this.dashboardService.getProjectSummary(this.levelMasterId).subscribe(
      (res)=>{
        this.projectSummary=res.body;
      },
      (error)=>{}
    )
  }

  loadfundLimitDistrict(id){
    this.fundLimitActiveIndex=1;
    this.dashboardService.limitFundByYear(id,this.levelMasterId).subscribe(
      (res)=>{
        this.limitFundByYear=res.body;
      },
      (error)=>{}
    )
    
  }

  loadExpenditureDistrict(id){
    this.exprLimitActiveIndex=1;
    this.dashboardService.expenditureByYear(id,this.levelMasterId).subscribe(
      (res)=>{
        this.expenditureByYear=res.body;
      },
      (error)=>{}
    )
    
  }

  loadBalanceDistrict(id){
    this.balLimitActiveIndex=1;
    this.dashboardService.balanceByYear(id,this.levelMasterId).subscribe(
      (res)=>{
        this.balanceByYear=res.body;
      },
      (error)=>{}
    )
    
  }

  loadWorkDistrict(id){
    this.workActiveIndex=1;
    this.dashboardService.getProjectSummaryByLevel(id,this.levelMasterId).subscribe(
      (res)=>{
        this.projectSummaryByLevel=res.body;
      },
      (error)=>{}
    )
    
  }

  onCardTabChange(){
    this.fundLimitActiveIndex=0;
    this.limitFundByYear=null;
    this.exprLimitActiveIndex=0;
    this.expenditureByYear=null;
    this.balLimitActiveIndex=0;
    this.balanceByYear=null;
    this.workActiveIndex=0;
    this.projectSummaryByLevel=null;
  }

  onfundLimitTabChange(event: any){
    if (event.index === 0){
      this.limitFundByYear=null;
    }
  }
  onExpenditureTabChange(event: any){
    if (event.index === 0){
      this.expenditureByYear=null;
    }
  }
  onBalanceTabChange(event: any){
    if (event.index === 0){
      this.balanceByYear=null;
    }
  }
  onWorkTabChange(event: any){
    if (event.index === 0){
      this.projectSummaryByLevel=null;
    }
  }

  formatCash = n => {
    return +(n / 1e7).toFixed(3);
  };
  
  formatCashLakh = n => {
    return +(n / 1e5).toFixed(3) ;
  }; 

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }

}
