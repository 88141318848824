import { Project } from "src/app/project/project.model";
import { CommercialVendors } from "../commercial-vendors/commercial-vendors";
import { LevelMaster } from "../level-master/level-master";
import { FinYear } from "../limit-translist/limit-translist.module";

export class VendorMapping {
    id: number;
    tenderMapping: number;
    commercialVendor: CommercialVendors;
    activeFlag: string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
}

export class TenderMapping {
    id: number;
    workId: string;
    work: Project;
    finYear: FinYear;
    levelMaster: LevelMaster;
    workProcurementDate: Date;
    workProcurementNo:string;
    tenderNo: string;
    tenderDate: Date;
    fileUpload: string;
    fileUploadTendor: string;
    activeFlag: string;
    createdOn: Date;
    updatedOn: Date;
    createdBy: number;
    updatedBy: number;
}

export class tender {
    tenderMapping:TenderMapping;
    vendorMappings:VendorMapping[];
}
  