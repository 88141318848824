import { Component, OnInit, ViewChild } from '@angular/core';
import {
  LevelMaster,
  LevelMasterDto,
} from '../module/level-master/level-master';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SchemeList } from '../module/scheme-list/scheme-list';
import { DepartmentList } from '../module/department-list/department-list';
import { LevelType } from '../module/level-type/level-type';
import { LevelMasterService } from '../module/level-master/level-master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { SchemeListService } from '../module/scheme-list/scheme-list.service';
import { LevelTypeService } from '../module/level-type/level-type.service';
import { DepartmentListService } from '../module/department-list/department-list.service';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from '../shared/font-service/font.service';
import { ValidationService } from '../shared/validation/validation.service';
import { NotificationService } from '../alert/notification.service';
import { Dialog } from 'primeng/dialog';
import { SearchResult } from '../shared/models/search-result';
import { Project } from './project.model';
import { CommercialVendorService } from '../module/commercial-vendors/commercial-vendors.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ValidationResponse } from '../module/commercial-vendors/commercial-vendors';
import { FinYear, limitTransDto } from '../module/limit-translist/limit-translist.module';
import { LimitTransListService } from '../module/limit-translist/limit-translist.service';
import { SigninService } from '../auth/signin/signin.service';
import { PaymentService } from '../payments/payment-voucher/payment-voucher.service';
import { ProjectService } from './project.service';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';
import { userRoles } from '../shared/models/user-role-code';
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;

  projectDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Project> = new SearchResult<Project>();
  checked: boolean;
  project?: Project;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any;
  commercialList: Project[];
  validationRes: ValidationResponse;
  readonly: boolean;
  workCategoryList:any;
  workTypeList:any;
  // validation fields properties
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public cPinError = false;
  public showBMobile = false;
  public aadhaarError = false;
  public tanError = false;
  public tinError = false;
  public gstError = false;
  public ifscError = false;
  today: Date;
  bankName: any;
  //required fields property
  ngSelectInvalid: boolean = false;
  showNameError: string;
  showCompanyNameError: string;
  showPanError: string;
  showeEmailError: string;
  showAadhaarNoError: string;
  showGstError: string;
  showaccountNoError: string;
  bankList: any;
  schemeList: SchemeList[];
  finYear: FinYear[];
  villageLevelDto: null;
  user: any;
  villageLevelList: LevelMasterDto[];
  isFile: boolean;
  vFileExt: any;
  viewDoc: boolean;
  vFileUrl: string;
  enablepublicContributionAmount: boolean=false;
  publicContributionAmount = [
    {
      id: '1',
      name: '1/3',
      value:0.33
    },
    { id: 2,
      name: '1/5',
      value:0.2
   },
    {
      id: 3,
      name: '1/2',
      value: 0.5
    },
  ];
  selectedpublicContribution:any;
  enableView: boolean=false;
  blockLevelDto: null;
  blockLevelList: LevelMasterDto[];
  limitAmount: number;
  viewOnly: boolean=false;
  edit: boolean;
  userContextSubscription: Subscription;
  allowEdit: boolean;
  isEditWorkCategory: boolean;
  isEditWorkType: boolean;
  constructor(
    private commercialVendorservice: CommercialVendorService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private schemeListService: SchemeListService,
    private limitTransListService: LimitTransListService,
    private levelMasterService: LevelMasterService,
    private loginService: SigninService,
    private paymentService: PaymentService,
    private projectService:ProjectService,
    private userRoleService:UserRoleService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.userRoleService.getuserContext().subscribe(
      (res) => {
        this.user = res;
        this.loadPage(1);
        if (res.role.code == userRoles.roleBChecker1 ||
          res.role.code ==userRoles.roleZonal1 || 
          res.role.code ==userRoles.roleZonal2 ||
          res.role.code ==userRoles.roleZonal3 ||
          res.role.code ==userRoles.roleZonal4 ||
          res.role.code ==userRoles.roleZonal5 ||
          res.role.code == userRoles.roleBChecker2 ||
          res.role.code == userRoles.roleState ||
          res.role.code == userRoles.roleStateChecker ||
          res.role.code == userRoles.roleStateApprover ||
          res.role.code == userRoles.roleDMaker ||
          res.role.code == userRoles.roleDChecker1 ||
          res.role.code == userRoles.roleDChecker2)
      {
        this.allowEdit = false;
      } else {
        this.allowEdit = true;
      }
      },
      (onError) => {}
    );
    this.cols = [
      {
        field: 'districtMaster',
        header: 'District',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
        type: 'dropDown'
      },
      {
        field: 'blockMaster',
        header: 'BLOCK',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
        type: 'dropDown'
      },
      {
        field: 'villageMaster',
        header: 'Village',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
        type: 'dropDown'
      },
      {
        field: 'finYear',
        header: 'Fin Year',
        isSortable: true,
        isFilterable: true,
        width: 10,
        isSelectcolumn: true,
        type: 'dropDown'
      },

      {
        field: 'scheme',
        header: 'Scheme',
        isSortable: true,
        isFilterable: true,
        width: 15,
        isSelectcolumn: true,
        type: 'dropDown'
      },
      {
        field: 'workId',
        header: 'Work Id',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'projectName',
        header: 'Work Name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
      },
     
      {
        field: 'administrativeSanctionAmount',
        header: 'AS Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type:'amount'
      },
      {
        field: 'administrativeSanctionDate',
        header: 'AS Date',
        isSortable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'createdOn',
        header: 'Created On',
        jhiTranslate: 'tableHead.createdon',
        isSortable: true,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {}
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => {}
    );
   
  }

  onWorkTypeChange(){
    this.project.workCategory=null;
    this.projectService.getWorkCategoryByParentId(this.project.finYear.id,this.project.workType.id).subscribe(
      (res)=>{
        this.workCategoryList=res.body;
      },
      (error)=>{}
    );
  }

  onchangeFinYear($event?) {
    if(!$event){
      this.project.workType = null;
      this.project.workCategory = null;
      this.projectService.getWorkCategoryByParentId(this.project.finYear.id).subscribe(
        (res)=>{
          this.workTypeList=res.body;
        },
        (error)=>{}
      );
    }
    
    this.blockLevelDto = null;
    let filterQuery = {
      page: 0,
      size: 20,
    };
    this.levelMasterService
      .LevelMasterDtoFilter({ ...filterQuery }, [
        {
          key: 'id',
          operation: 'equals',
          value: this.user.levelMaster.id
        },
      ])
      .subscribe(
        (res: HttpResponse<any>) => {
          this.project.blockMaster = res.body[0];
          let filterQuery = {
            page: 0,
            size: 20,
          };
          this.levelMasterService
            .LevelMasterDtoFilter({ ...filterQuery }, [
              {
                key: 'id',
                operation: 'equals',
                value: res.body[0].parentId
              },
            ])
            .subscribe(
              (res: HttpResponse<any>) => {
                this.project.districtMaster = res.body[0];
                this.onBlockChange();
              },
              () => {}
            );
        },
        () => {}
      );
      
  }

  onBlockChange($event?){
    let payload={
      levelMasterId: this.project.blockMaster.id,
      schemeId: this.project.scheme.id,
      finYearId: this.project.finYear.id,
    }
      this.levelMasterService
        .villageListByScheme(payload)
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
  }

  calcAmount(){
    if(!this.enablepublicContributionAmount){
      this.project.administrativeSanctionAmount = this.project.totalCost;
      return
    }
    this.projectService
        .getAmount(this.selectedpublicContribution.id,this.project.totalCost)
        .subscribe(
          (res:any) => {
            this.project.publicContributionAmount = res.body;
            this.project.administrativeSanctionAmount = Number((this.project.totalCost - this.project.publicContributionAmount).toFixed(2));
          },
          () => {}
        );
  }

  openNew() {
    this.project = new Project();
    this.loadValue();
    this.projectDialog = true;
    this.readonly = false;
  }

  removeDocument() {
    this.paymentService.deleteimage(this.project.fileUpload).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const file = $event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.','');
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('File Upload SuccessFully', '');
        this.project.fileUpload = res.body.fileName;
        this.isFile = true;
      },
      (onerr) => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format','');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

  viewDocument() {
    this.paymentService
      .viewimage(this.project.fileUpload)
      .subscribe((res) => {
        const filename = res.body.fileName;
        this.vFileExt = filename.split('.').pop();
        this.vFileUrl =
          'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
        this.viewDoc = true;
      });
  }

  removeExtraSpaces(text: string): string {
    return text.replace(/\s+/g, ' ').trim();
  }
  hideDialog() {
    this.ngSelectInvalid = false;
    this.project = new Project();
    this.projectDialog = false;
    this.enablepublicContributionAmount=null; 
    this.selectedpublicContribution=null;
    this.isFile = false;
    this.viewOnly=false;
    this.edit=false;
    this.workCategoryList = null;
    this.workTypeList = null;
    this.isEditWorkType = false;
    this.isEditWorkCategory = false;
  }

  saveData(){
    const isFormValid =
    this.project.projectName &&
    this.project.workId &&
    this.project.scheme &&
    this.project.finYear &&
    this.project.blockMaster &&
    this.project.administrativeSanctionNo &&
    this.project.administrativeSanctionDate &&
    this.project.administrativeSanctionAmount &&
    this.project.technicalSanctionNo &&
    this.project.technicalSanctionAmount &&
    this.project.technicalSanctionDate &&
    this.project.agreementNo &&
    this.project.agreementAmount &&
    this.project.agreementDate &&
    this.project.workCategory &&
    this.project.workType &&
    this.isFile;

  if (!isFormValid) {
    this.ngSelectInvalid = true;
    return;
  }
  this.ngSelectInvalid = false;

    this.spinner.show();
    this.limitTransListService.getAmountAndLBC({
      levelMasterId: this.project.villageMaster.id,
      schemeId: this.project.scheme.id,
      finYearId: this.project.finYear.id,
    })
    .subscribe(
      (res: HttpResponse<limitTransDto>) => {
        this.spinner.hide();
        this.limitAmount= res.body.programmeFundAuthorizationAmount-res.body.programmeUtilizedAmount;
        if(this.project.totalCost>this.limitAmount && !this.project.id){
          this.notificationService.alertError('Limit amount exceeded, Work amount should be within limit amount: Rs. '+this.limitAmount,'')
          return;
        }
          this.project.publicContribution=this.enablepublicContributionAmount?'Yes':'No';
          this.project.contributionPercentage=this.selectedpublicContribution?this.selectedpublicContribution.value:0;
          // this.project.districtMaster=this.user.levelMasterId; 
          this.projectService.saveProject(this.project).subscribe(
          (res: HttpResponse<any>) => {
            this.projectDialog = false;
             this.loadPage(this.page);
            this.notificationService.alertSuccess('Saved Successfully', '');
          },
          (error) => {
            if(error.status === 403){
            this.notificationService.alertError('Work Id already Exist', '');
            }
            else{
            this.notificationService.alertError('Error!', '');
            }
            this.spinner.hide();
          }
        );
      },
      ()=>{this.spinner.hide();}
    );
  
  }
  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps = this.filterProps ? this.filterProps : [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    if(this.user.role.code === userRoles.roleDMaker ||
      this.user.role.code === userRoles.roleDChecker1 ||
      this.user.role.code === userRoles.roleDChecker2){
        this.filterProps.push({
          key: 'districtMaster',
          operation: 'equals',
          value:this.user.levelMaster.id,
        });
      } else if(this.user.role.code === userRoles.roleBMaker ||
        this.user.role.code === userRoles.roleBChecker1 ||
        this.user.role.code === userRoles.roleBChecker2){
          this.filterProps.push({
            key: 'blockMaster',
            operation: 'equals',
            value:this.user.levelMaster.id,
          });
        } 
        if(this.user.role.code== userRoles.roleZonal1 ||
          this.user.role.code== userRoles.roleZonal2 ||
          this.user.role.code== userRoles.roleZonal3 ||
          this.user.role.code== userRoles.roleZonal4 ||
          this.user.role.code== userRoles.roleZonal5){
            this.filterProps.push({
              key: 'blockMaster',
              operation: 'equals',
              value:this.user.levelMaster.id,
            });
            this.filterProps.push({
              key: 'zonalUser',
              operation: 'equals',
              value:this.user.zonalName.toUpperCase(),
            });
          }
    this.projectService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<Project[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.spinner.hide();
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: Project[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  protected onError(): void {}

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if($event.type === 'view'){
        this.viewOnly=true;
      } else if($event.type === 'select'){
        this.viewOnly=true;
        this.edit=true;
      }
    this.paymentService.getProjectByWorkid($event.value.workId).subscribe(
          (res: Project) => {
            this.project = res;
            if (this.project.technicalSanctionDate) {
              this.project.technicalSanctionDate = new Date(
                this.project.technicalSanctionDate);
            }
            if (this.project.agreementDate) {
              this.project.agreementDate = new Date(
                this.project.agreementDate);
            }
            this.projectDialog = true;
            if (this.project.administrativeSanctionDate) {
              this.project.administrativeSanctionDate = new Date(this.project.administrativeSanctionDate);
            }
            if(this.project.fileUpload){
              this.isFile = true;
            }
            this.publicContributionAmount.forEach(element => {
              if(element.value === this.project.contributionPercentage){
                this.selectedpublicContribution = element;
              }
            });
            if(!this.project.workType && this.edit) {
              this.isEditWorkType = true;
              this.onchangeFinYear();
            }
            else if(this.project.workType && this.edit && !this.project.workCategory) {
              this.isEditWorkCategory = true;
              this.onWorkTypeChange();
            } 
            if(this.project.publicContribution == 'Yes'){
              this.enablepublicContributionAmount =true;
            }
            this.loadValue();
          },
          () => {
            this.onError();
          }
        );
    } 
    // else {
    //   this.paymentService.delete($event.value.workId).subscribe(
    //     (res: Project) => {
    //       this.loadPage(this.page);
    //     },
    //     () => {
    //       this.onError();
    //     }
    //   );
    // }
  }
  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
}
