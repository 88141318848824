import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';

export class UserContext{
    id : number;
    userName : string;
    password : string;
    mobile : string;
    email : string;
    resetPassword : string;
    loginAttempt : number;
    zonalName : string;
    role : Role;
    resetDate : Date;
    createdOn : Date;
    updatedOn : Date;
    createdBy : number;
    updatedBy : number;
    activeFlag : string;
    profileStatus : string;
    implementingAgencyId : number;
    levelMaster : LevelMasterDto;
    keyCloakUuid : string;
  }
 class Role {
      id : number;
      code : string;
      name : string;
      createdOn : Date;
      updatedOn : Date;
 }
@Injectable({
  providedIn: 'root'
})
export class UserRoleService {

  constructor(private loginService:SigninService ){}

  // private userContext = new Subject<UserContext>();
   private userContext = new BehaviorSubject<UserContext>(new UserContext());

  public getuserContext(): BehaviorSubject<UserContext> {
    return this.userContext;
  }

  public async fetchAndSetuserContext(): Promise<void> {
    const response = await this.loginService.getuserDto().toPromise();
    this.userContext.next(response.body);
  }

  public clearuserContext(){
    this.userContext.next(null);
  }
}
