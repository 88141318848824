export const userRoles = {
    roleBMaker:'DYBDO',
    roleBChecker1:'ZBDO',
    roleZonal1:'Z1BDO',
    roleZonal2:'Z2BDO',
    roleZonal3:'Z3BDO',
    roleZonal4:'Z4BDO',
    roleZonal5:'Z5BDO',
    roleBChecker2:'BDO',
    roleState:'SA',
    roleStateMaker:'SMAKER',
    roleStateChecker:'SC1',
    roleStateApprover:'SC2',
    roleDMaker:'DMAKER',
    roleDChecker1:'DC1',
    roleDChecker2:'DC2'
}