import { LevelMaster, LevelMasterDto } from './../level-master/level-master';
import { FinYear, limitTransDto, limitTransList, limitTransListDto } from './limit-translist.module';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LimitTransListService } from './limit-translist.service';
import { SearchResult } from 'src/app/shared/models/search-result';
import { LevelType } from '../level-type/level-type';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelMasterService } from '../level-master/level-master.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';

@Component({
  selector: 'app-limit-translist',
  templateUrl: './limit-translist.component.html',
  styleUrls: ['./limit-translist.component.scss'],
})
export class LimitTranslistComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  formulaitemsPerPage: any = 10;
  formulapage: number;
  formulafilterProps: any;
  searchResult: SearchResult<limitTransList> = new SearchResult<limitTransList>();
  cols: any;
  formulaCol: any;
  submitted: boolean;
  limitSetDialog: boolean;
  limitTransDto: limitTransDto;
  levelTypeList: LevelType[];
  limitTranslist: limitTransListDto;
  levelMasterList: any;
  agency: any;
  ComponentType: any;
  finYear: FinYear[];
  enableForm: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  departmentDto: any;
  schemeDto: any;
  showText: boolean;
  enableTable: boolean;
  iaCompList: any;
  readonly: boolean = false;
  enableLbc: boolean = true;
  lbcPer: number;
  lbcAmount: number;
  districtLevelList: LevelMasterDto[];
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  villageLevelList: LevelMasterDto[];
  villageLevelDto: any;
  parentLimitTransDto: limitTransDto;
  existLimitTransDto: limitTransDto;
  //required fields property
  ngSelectInvalid: boolean = false;
  iaError: any;
  limitAmount: number;
  amount: number;
  balanceAuthorizationAmount: number;
  viewOnly: boolean;
  viewDoc: boolean;
  vFileExt: string;
  vFileUrl: string;
  isFile: boolean;
  allocationExist: boolean;
  districtAdminExist: boolean;
  existingFundAuth: number;
  existingAdminFundAuth: number;
  balanceAdminAuthorizationAmount: number;

  constructor(
    private limitTransListService: LimitTransListService,
    private spinner: NgxSpinnerService,
    private levelTypeService: LevelTypeService,
    private levelMasterService: LevelMasterService,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    protected paymentService: PaymentService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit(): void {
    this.cols = [
      {
        field: 'levelType',
        header: 'Level Type',
        jhiTranslate: 'tableHead.levelType',
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'levelMaster',
        header: 'Level Master',
        jhiTranslate: 'tableHead.levelMaster',
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },

      {
        field: 'finYear',
        header: 'Fin Year',
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },

      {
        field: 'implementingAgency',
        header: 'IA Name',
        jhiTranslate: 'tableHead.iA.name',
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
      {
        field: 'transactionDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        width: 13,
        type: 'date',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'programmeAllocationAmount',
        header: 'Programme Allocation Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'programmeFundAuthorizationAmount',
        header: 'Authorized Programme Fund Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'adminAllocationAmount',
        header: 'Admin Allocation Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'adminFundAuthorizationAmount',
        header: 'Authorized Admin Fund Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: '',
        header: 'District',
        isSortable: false,
        isFilterable: true,
        isSelectcolumn: false,
      },
      {
        field: '',
        header: 'Block',
        isSortable: false,
        isFilterable: true,
        isSelectcolumn: false,
      },
      {
        field: '',
        header: 'Village',
        isSortable: false,
        isFilterable: true,
        isSelectcolumn: false,
      },

      {
        field: 'lbcAmount',
        header: 'LBC Amount',
        width: 13,
        isSelectcolumn: true,
        isFilterable: false,
        type:'amount'
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach(label => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.filterProps = this.filterProps ?? [];
    this.limitTransListService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<limitTransList[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.onError();
      }
    );
  }
  protected onSuccess(data: limitTransList[] | null, headers: HttpHeaders, page: number, navigate: boolean): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onLevelTypeChangeTrans($event?) {
    this.districtListDto = null;
    this.BlockLevelDto = null;
    this.villageLevelDto = null;

    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.allocationExist = false;
    this.districtAdminExist = false;
    this.amount = null;
    if (this.limitTransDto.levelTypeDto?.name == 'District') {
      this.limitTransDto.levelMasterDto = this.districtListDto;
      this.limitTransListService
        .getAmountAndLBC({
          levelMasterId: this.districtListDto.id,
          schemeId: this.limitTransDto.schemeDto.id,
          finYearId: this.limitTransDto.finYearDto.id,
        })
        .subscribe(
          (res: HttpResponse<limitTransDto>) => {
            if (res.body.programmeAllocationAmount) {
              this.limitTransDto.programmeAllocationAmount = res.body.programmeAllocationAmount;
              this.existingFundAuth = res.body.programmeFundAuthorizationAmount;
              this.allocationExist = true;
            }
            if (res.body.adminAllocationAmount) {
              this.limitTransDto.adminAllocationAmount = res.body.adminAllocationAmount;
              this.existingAdminFundAuth = res.body.adminFundAuthorizationAmount;
              this.districtAdminExist = true;
            }
            this.parentLimitTransDto = res.body;
            if (this.parentLimitTransDto.lbcPercentage) this.limitTransDto.lbcPercentage = this.parentLimitTransDto.lbcPercentage;
          },
          () => {}
        );
    }
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onBlockLevelChange($event?) {
    this.allocationExist = false;
    this.amount = null;
    if (this.limitTransDto.levelTypeDto?.name == 'Block') {
      this.limitTransDto.levelMasterDto = this.BlockLevelDto;
      this.limitTransListService
        .getAmountAndLBC({
          levelMasterId: this.BlockLevelDto.id,
          schemeId: this.limitTransDto.schemeDto.id,
          finYearId: this.limitTransDto.finYearDto.id,
        })
        .subscribe(
          (res: HttpResponse<limitTransDto>) => {
            if (res.body.programmeAllocationAmount) {
              this.limitTransDto.programmeAllocationAmount = res.body.programmeAllocationAmount;
              this.existingFundAuth = res.body.programmeFundAuthorizationAmount;
              this.allocationExist = true;
            }
            if (res.body.adminAllocationAmount) {
              this.limitTransDto.adminAllocationAmount = res.body.adminAllocationAmount;
              this.existingAdminFundAuth = res.body.adminFundAuthorizationAmount;
              this.districtAdminExist = true;
            }
            this.existLimitTransDto = res.body;
          },
          () => {}
        );
      this.limitTransListService
        .getAmountAndLBC({
          levelMasterId: this.districtListDto.id,
          schemeId: this.limitTransDto.schemeDto.id,
          finYearId: this.limitTransDto.finYearDto.id,
        })
        .subscribe(
          (res: HttpResponse<limitTransDto>) => {
            this.parentLimitTransDto = res.body;
          },
          () => {}
        );
    }
    this.villageLevelDto = null;
    let payload = {
      levelMasterId: this.BlockLevelDto.id,
      schemeId: this.limitTransDto.schemeDto.id,
      finYearId: this.limitTransDto.finYearDto.id,
    };
    if ($event) {
      this.levelMasterService.villageListByScheme(payload).subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.villageLevelList = res.body;
        },
        () => {}
      );
    } else {
      this.levelMasterService.villageListByScheme(payload).subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.villageLevelList = res.body;
        },
        () => {}
      );
    }
  }

  loadIA(id: number) {
    if (this.limitTransDto.levelTypeDto?.name == 'Village') {
      this.amount = null;
      this.limitTransDto.levelMasterDto = this.villageLevelDto;
      this.limitTransListService
        .getAmountAndLBC({
          levelMasterId: this.villageLevelDto.id,
          schemeId: this.limitTransDto.schemeDto.id,
          finYearId: this.limitTransDto.finYearDto.id,
        })
        .subscribe(
          (res: HttpResponse<limitTransDto>) => {
            if (res.body.programmeAllocationAmount) {
              this.limitTransDto.programmeAllocationAmount = res.body.programmeAllocationAmount;
              this.existingFundAuth = res.body.programmeFundAuthorizationAmount;
              this.allocationExist = true;
            }
            if (res.body.adminAllocationAmount) {
              this.limitTransDto.adminAllocationAmount = res.body.adminAllocationAmount;
              this.existingAdminFundAuth = res.body.adminFundAuthorizationAmount;
              this.districtAdminExist = true;
            }
            this.existLimitTransDto = res.body;
          },
          () => {}
        );
      this.limitTransListService
        .getAmountAndLBC({
          levelMasterId: this.BlockLevelDto.id,
          schemeId: this.limitTransDto.schemeDto.id,
          finYearId: this.limitTransDto.finYearDto.id,
        })
        .subscribe(
          (res: HttpResponse<limitTransDto>) => {
            this.parentLimitTransDto = res.body;
          },
          () => {}
        );
    }
    this.limitTransDto.implementingAgencyDto = null;
    this.limitTransListService.getImplementingAgencyByLevelMaster(id).subscribe(
      (res: HttpResponse<any>) => {
        this.iaCompList = res.body;
      },
      () => {}
    );
  }

  async openNew() {
    this.submitted = false;
    this.limitSetDialog = true;
    this.limitTranslist = new limitTransListDto();
    this.enableForm = false;
    this.showText = false;
    this.limitTransDto = new limitTransDto();
    this.loadValue();
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<FinYear[]>) => {
        this.finYear = res.body;
      },
      () => {}
    );
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelTypeList = res.body;
      },
      () => {}
    );
  }

  hideDialog() {
    this.limitTranslist = new limitTransListDto();
    this.limitTransDto = new limitTransDto();
    this.ngSelectInvalid = false;
    this.amount = null;
    this.existingFundAuth = null;
    this.balanceAuthorizationAmount = null;
    this.limitSetDialog = false;
    this.submitted = false;
    this.viewOnly = false;
    this.isFile = false;
    this.existingAdminFundAuth = null;
    this.balanceAdminAuthorizationAmount = null;
  }

  // calcLbc() {
  //   if(this.amount && this.limitTransDto.lbcPercentage )
  //   this.limitTransDto.lbcAmount=Number(((Number(this.amount) * this.limitTransDto.lbcPercentage)/100).toFixed(2));
  // }
  calcBalance() {
    if (!this.amount || !this.limitTransDto.programmeAllocationAmount) {
      this.balanceAuthorizationAmount = null;
    }
    if (this.allocationExist && this.limitTransDto.programmeFundAuthorizationAmount && this.limitTransDto.programmeAllocationAmount) {
      this.balanceAuthorizationAmount =
        Number(this.limitTransDto.programmeAllocationAmount) -
        Number(this.existingFundAuth) -
        Number(this.limitTransDto.programmeFundAuthorizationAmount);
    } else if (this.limitTransDto.programmeFundAuthorizationAmount && this.limitTransDto.programmeAllocationAmount) {
      this.balanceAuthorizationAmount =
        Number(this.limitTransDto.programmeAllocationAmount) - Number(this.limitTransDto.programmeFundAuthorizationAmount);
    }
    if (!this.limitTransDto.adminFundAuthorizationAmount || !this.limitTransDto.adminAllocationAmount) {
      this.balanceAdminAuthorizationAmount = null;
    }
    if (this.districtAdminExist && this.limitTransDto.adminFundAuthorizationAmount && this.limitTransDto.adminAllocationAmount) {
      this.balanceAdminAuthorizationAmount =
        Number(this.limitTransDto.adminAllocationAmount) -
        Number(this.existingAdminFundAuth) -
        Number(this.limitTransDto.adminFundAuthorizationAmount);
    } else if (this.limitTransDto.adminFundAuthorizationAmount && this.limitTransDto.adminAllocationAmount) {
      this.balanceAdminAuthorizationAmount =
        Number(this.limitTransDto.adminAllocationAmount) - Number(this.limitTransDto.adminFundAuthorizationAmount);
    }
  }

  // checkAuthorizedLimit(){
  //   if(this.amount && this.limitTransDto.programmeAllocationAmount)
  //   {
  //     if(!this.allocationExist){
  //       if(Number(this.amount)>Number(this.limitTransDto.programmeAllocationAmount)){
  //         this.notificationService.alertError("Programme Fund Authorization Amount should not be greater than the Allocated Amount: "+this.limitTransDto.programmeAllocationAmount,'');
  //         this.amount=0;
  //         this.balanceAuthorizationAmount=0;
  //       }
  //     } else if(this.allocationExist){
  //         if(Number(this.amount)>(Number(this.limitTransDto.programmeAllocationAmount)-Number(this.existingFundAuth))){
  //         this.notificationService.alertError("Programme Fund Authorization Amount should not be greater than the Amount: "+(Number(this.limitTransDto.programmeAllocationAmount)-Number(this.existingFundAuth)),'');
  //         this.amount=0;
  //         this.balanceAuthorizationAmount=0;
  //       }
  //     }
  //   }
  //   if(this.limitTransDto.adminFundAuthorizationAmount && this.limitTransDto.adminAllocationAmount)
  //   {
  //     if(!this.districtAdminExist){
  //       if(Number(this.limitTransDto.adminFundAuthorizationAmount)>Number(this.limitTransDto.adminAllocationAmount)){
  //         this.notificationService.alertError("Admin Fund Authorization Amount should not be greater than the Allocated Amount: "+this.limitTransDto.adminAllocationAmount,'');
  //         this.limitTransDto.adminFundAuthorizationAmount=0;
  //         this.balanceAdminAuthorizationAmount=0;
  //       }
  //     } else if(this.districtAdminExist){
  //         if(Number(this.limitTransDto.adminFundAuthorizationAmount)>(Number(this.limitTransDto.adminAllocationAmount)-Number(this.existingAdminFundAuth))){
  //         this.notificationService.alertError("Admin Fund Authorization Amount should not be greater than the Amount: "+(Number(this.limitTransDto.adminAllocationAmount)-Number(this.existingAdminFundAuth)),'');
  //         this.limitTransDto.adminFundAuthorizationAmount=0;
  //         this.balanceAdminAuthorizationAmount=0;
  //       }
  //     }
  //   }
  // }

  saveData() {
    const isFormValid =
      this.limitTransDto.departmentDto &&
      this.limitTransDto.schemeDto &&
      this.limitTransDto.levelTypeDto &&
      this.limitTransDto.levelMasterDto &&
      this.limitTransDto.implementingAgencyDto &&
      this.limitTransDto.finYearDto &&
      this.limitTransDto.programmeAllocationAmount &&
      this.limitTransDto.adminFundAuthorizationAmount &&
      this.limitTransDto.adminAllocationAmount &&
      this.isFile &&
      this.limitTransDto.description;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;

    if (
      Number(this.limitTransDto.programmeAllocationAmount) + Number(this.limitTransDto.adminAllocationAmount) >
      Number(this.limitTransDto.totalAllocationAmount)
    ) {
      this.notificationService.alertError(
        'Limit Exceeded than the Total Allocation Amount: ' + this.limitTransDto.totalAllocationAmount,
        ''
      );
      return;
    }

    if (!this.allocationExist) {
      if (
        Number(this.limitTransDto.programmeFundAuthorizationAmount) +
          Number(this.limitTransDto.incentive) +
          Number(this.limitTransDto.lbcAmount) >
        Number(this.limitTransDto.programmeAllocationAmount)
      ) {
        this.notificationService.alertError(
          'Limit Exceeded than the Programme Allocation Amount: ' + this.limitTransDto.programmeAllocationAmount,
          ''
        );
        this.balanceAuthorizationAmount = 0;
        return;
      }
    } else if (this.allocationExist) {
      if (
        Number(this.limitTransDto.programmeFundAuthorizationAmount) >
        Number(this.limitTransDto.programmeAllocationAmount) - Number(this.existingFundAuth)
      ) {
        this.notificationService.alertError(
          'Limit Exceeded than the Programme Allocation Amount:' +
            (Number(this.limitTransDto.programmeAllocationAmount) - Number(this.existingFundAuth)),
          ''
        );
        this.balanceAuthorizationAmount = 0;
        return;
      }
    }
    if (!this.districtAdminExist) {
      if (Number(this.limitTransDto.adminFundAuthorizationAmount) > Number(this.limitTransDto.adminAllocationAmount)) {
        this.notificationService.alertError(
          'Admin Fund Authorization Amount should not be greater than the Allocated Amount: ' + this.limitTransDto.adminAllocationAmount,
          ''
        );
        this.limitTransDto.adminFundAuthorizationAmount = 0;
        this.balanceAdminAuthorizationAmount = 0;
        return;
      }
    } else if (this.districtAdminExist) {
      if (
        Number(this.limitTransDto.adminFundAuthorizationAmount) >
        Number(this.limitTransDto.adminAllocationAmount) - Number(this.existingAdminFundAuth)
      ) {
        this.notificationService.alertError(
          'Admin Fund Authorization Amount should not be greater than the Amount: ' +
            (Number(this.limitTransDto.adminAllocationAmount) - Number(this.existingAdminFundAuth)),
          ''
        );
        this.balanceAdminAuthorizationAmount = 0;
        return;
      }
    }
    this.limitTransDto.lbcFlag = this.enableLbc ? 'Yes' : 'No';
    // this.enableLbc?this.limitTransDto.programmeFundAuthorizationAmount=Number(this.amount) - Number(this.limitTransDto.lbcAmount):
    this.limitTransDto.lbcPercentage = 0;
    this.limitTransDto.programmeFundAuthorizationAmount = Number(this.amount);
    this.limitTransListService.save(this.limitTransDto).subscribe(
      (res: HttpResponse<any>) => {
        this.limitSetDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      error => {
        this.onError();
        this.notificationService.alertError('Error! ' + error.error.errorKey + ',' + error.error.title, '');
      }
    );
  }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if ($event.type === 'view') {
        this.viewOnly = true;
      }
      this.limitTransListService.getIAComponentLimitTransById($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.limitTransDto = res.body;
          this.amount = this.limitTransDto.programmeFundAuthorizationAmount;
          if (this.limitTransDto.levelTypeDto?.name == 'District') {
            this.districtListDto = this.limitTransDto.levelMasterDto;
          } else if (this.limitTransDto.levelTypeDto?.name == 'Block') {
            this.BlockLevelDto = this.limitTransDto.levelMasterDto;
          } else if (this.limitTransDto.levelTypeDto?.name == 'Village') {
            this.villageLevelDto = this.limitTransDto.levelMasterDto;
          }
          this.balanceAuthorizationAmount = Number(this.limitTransDto.programmeAllocationAmount) - Number(this.amount);
          this.balanceAdminAuthorizationAmount =
            Number(this.limitTransDto.adminAllocationAmount) - Number(this.limitTransDto.adminFundAuthorizationAmount);
          if (this.limitTransDto.fileName) {
            this.isFile = true;
          }
          this.limitSetDialog = true;
        },
        () => {
          this.onError();
        }
      );
    }
  }

  removeDocument() {
    this.paymentService.deleteimage(this.limitTransDto.fileName).subscribe(
      res => {
        this.notificationService.alertSuccess('File Deleted', '');
        this.isFile = false;
      },
      error => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUploadfile1($event) {
    const formData = new FormData();
    const i = $event.files.length - 1;
    const file = $event.files[i];
    const acceptedFormats = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    if (file && !acceptedFormats.includes(file.type)) {
      this.notificationService.alertError('Invalid file format. Please upload a valid file.', '');
      return;
    }
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        this.notificationService.alertSuccess('File Upload SuccessFully', '');
        this.limitTransDto.fileName = res.body.fileName;
        this.isFile = true;
      },
      onerr => {
        this.notificationService.alertError('Error, please make sure file size is within 5MB and in supported format', '');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

  viewDocument() {
    this.paymentService.viewimage(this.limitTransDto.fileName).subscribe(res => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl = 'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }
}
