<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<header *ngIf="showHead">
  <ib-navbar></ib-navbar>
</header>
<section id="content">
      <app-alert></app-alert>
      <router-outlet></router-outlet>  
  </section>
<footer *ngIf="showHead">
  <app-footer></app-footer>
</footer>