import { ComponentMasterDto } from './../../payments/payment-voucher/payment-voucher.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MajorComponentService } from './major-component.service';
import { MasterComponent } from './major-component';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { ComponentTypeService } from '../component-type/component-type.service';
import { LevelTypeService } from '../level-type/level-type.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelType } from '../level-type/level-type';
import { ComponentType } from '../component-type/component-type';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-major-component',
  templateUrl: './major-component.component.html',
  styleUrls: ['./major-component.component.scss'],
})
export class MajorComponentComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  majorComponentDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<MasterComponent> =
    new SearchResult<MasterComponent>();
  majorComponent: ComponentMasterDto;
  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  filterProps: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  levelType: LevelType[];
  levelTypeDto: any;
  grantLevel: ComponentType;
  grantlevelDto: any;
  schemeDto: SchemeList;
  departmentDto: DepartmentList;

  //required fields property
  ngSelectInvalid: boolean = false;

  constructor(
    private majorComponentService: MajorComponentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private componentTypeService: ComponentTypeService,
    private levelTypeService: LevelTypeService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
      this.changelanguageLoadHeader();
    });
  }

  ngOnInit() {
    this.changelanguageLoadHeader();
  }

  changelanguageLoadHeader() {
    const lang = this.translateService.currentLang;
    if (lang === 'en') {
      this.cols = [
        {
          field: '4',
          header: 'Level Type',
          jhiTranslate: 'tableHead.levelType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: '3',
          header: 'Component Type',
          jhiTranslate: 'tableHead.compoType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },

        {
          field: '0',
          header: 'Major Component Code',
          jhiTranslate: 'tableHead.major.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: '1',
          header: 'Major Component Name',
          jhiTranslate: 'tableHead.major.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
      ];
    } else {
      this.cols = [
        {
          field: '4',
          header: 'Level Type',
          jhiTranslate: 'tableHead.levelType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },

        {
          field: '7',
          header: 'Component Type TN',
          jhiTranslate: 'tableHead.compoType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: '0',
          header: 'Major Component Code',
          jhiTranslate: 'tableHead.major.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },

        {
          field: '2',
          header: 'Major Component Name TN',
          jhiTranslate: 'tableHead.major.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
      ];
    }
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {}
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {}
    );
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => {}
    );
  }

  onLevelType() {
    this.componentTypeService.find(this.levelTypeDto.id).subscribe(
      (res: HttpResponse<ComponentType>) => {
        this.grantLevel = res.body;
      },
      () => {}
    );
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.majorComponentService
      .majorComponentfilter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<MasterComponent[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: MasterComponent[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/major-component'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  openNew() {
    this.majorComponent = new ComponentMasterDto();
    this.loadValue();
    this.majorComponentDialog = true;
  }

  hideDialog() {
    this.departmentDto = new DepartmentList();
    this.schemeDto = new SchemeList();
    this.departmentDto = null;
    this.schemeDto = null;
    this.levelTypeDto = null;
    this.levelType = null;
    this.grantlevelDto = null;
    this.grantLevel = null;
    this.majorComponent = new ComponentMasterDto();
    this.ngSelectInvalid = false;
    this.majorComponentDialog = false;
  }

  saveData() {
    const isFormValid =
      this.departmentDto &&
      this.schemeDto &&
      this.levelTypeDto &&
      this.majorComponent.name &&
      this.majorComponent.code;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.majorComponent.parentId = 0;
    this.majorComponent.componentTypeDto = this.grantlevelDto;
    this.majorComponentService.save(this.majorComponent).subscribe(
      (res: HttpResponse<any>) => {
        this.majorComponentDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
}
