import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/alert/notification.service';
import { ValidateService } from './validate.service';
import { SigninService } from '../signin/signin.service';
import * as forge from 'node-forge';
import { environment } from 'src/environments/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

 keyCloakUuid:string;
 public pwdMismatch:boolean=false;
 showOtp:boolean = false;
 inValidOtp:boolean = false;
 otpverified:boolean=false;
 hide:boolean=true;
 phide:boolean=true;
 inValidUser:boolean=false;
 passwordError:string;
otpTimer:any;
timer:any;
resendOtp:boolean=false;
otpButtonName:string = "Send OTP";
disableButton:boolean=false;

 otpForm= this.fb.group({
  username: [null, [Validators.required]],
  otp: [null, [Validators.required]]
});
 forgotPwdForm = this.fb.group({
    password: [null, [Validators.required]],
    confirmPassword: [null, [Validators.required]],
  });
  otpId: string;
  keyCloakId: string;
  constructor( private fb: FormBuilder,private route: ActivatedRoute,private router:Router,
    private validateService: ValidateService,private notificationService:NotificationService,
    private loginService: SigninService,private localStorageService:LocalStorageService,
    private sessionStorageService:SessionStorageService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.otpForm.controls['username'].setValue(params['username']);
    });
  }
  get username(){
    return this.otpForm.get('username')!.value;
  }
  get otp(){
    return this.otpForm.get('otp')!.value;
  }
  get password(){
    return this.forgotPwdForm.get('password')!.value;
  }
  get confirmPassword(){
    return this.forgotPwdForm.get('confirmPassword')!.value;
  }
  navToLogin() {
    this.router.navigate(['/login']);
  }
  startTimer() {
    this.otpTimer = 30; 

    this.timer = setInterval(() => {
      this.otpTimer--; 

      if (this.otpTimer === 0) {
        this.stopTimer();
      }
    }, 1000);
  }

  stopTimer() {
    if(this.resendOtp){
      this.otpButtonName="Resend OTP";
      this.disableButton=false;
    }
    else{
      this.localStorageService.clear();
      this.sessionStorageService.clear();
      this.showOtp = false;
      // this.loginForm.reset();
      // this.otpError=null;
      this.otpForm.controls['otp'].setValue(null);
    }
    clearInterval(this.timer);
  }

  sendotp(){
    this.inValidUser=false;
    if(!this.resendOtp)
    {
    this.validateService.getUserDetails(this.username).subscribe(
      (res)=>{
        this.otpId = res.body.otpId;
        this.keyCloakId = res.body.keyCloakId;
        this.getUserOtp();
       this.showOtp = true;
    },
    (onError) => {
      if(onError.error.errorCode==="INVALID_USER"){
        this.inValidUser=true;
      }
    }
    );
  }
  else{
    this.getUserOtp();
       this.showOtp = true;
  }
  }

  getUserOtp() {
    this.validateService.getUserOtp(this.username).subscribe(
      (res) => {       
          this.startTimer();
          this.resendOtp=true;
          this.disableButton=true;
      },
      (onError) => {}
    );
  }
  verifyotp(){
    this.inValidOtp=false;
    if (!this.otpForm.get('otp')!.value) {
      this.inValidOtp=true;
      return;
    }
    this.validateService.validateOtp(this.otpId,this.otpForm.get('otp')!.value,this.keyCloakId).subscribe(
      (res) => {
            this.otpverified=true;
            this.showOtp=false;
      },
      (onError) => {
        if (onError.status == 400)
        {
          this.inValidOtp=true;
        }
        else{
          this.notificationService.alertError('Error!', '');
        }
      }
    );
    
  }
 
  submit(){
    this.passwordError=null;
    this.pwdMismatch=null;
    if (this.forgotPwdForm.invalid) {
    return;
    }
        if(this.password==this.confirmPassword){
          this.validateService.setPassword(this.keyCloakId,this.password).subscribe(
          ()=>{
            this.notificationService.alertSuccess('Password successfully changed', '');
            this.router.navigateByUrl('login');
          },
          (onError)=>{
            if (onError.status == 400)
            {
              this.passwordError = onError.error.errorMessage;
            }
            else{
              this.passwordError='Password reset failed!';
            }
      }
        );
        }
        else{
          this.pwdMismatch=true;
        }       

  
    
  
  }
 
}
