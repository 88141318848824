import { AgencyDto } from './../agency/agency';
import { RoleDto } from './../limit-set/limit-set';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserService } from './user.service';
import { User, Role, userDto, LoginDto } from './user';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { LevelMasterService } from '../level-master/level-master.service';
import { LevelMasterDto } from '../level-master/level-master';
import { AgencyService } from '../agency/agency.service';
import { Agency } from '../agency/agency';
import { NotificationService } from 'src/app/alert/notification.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { Login } from 'src/app/auth/signin/login.model';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  userDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<User> = new SearchResult<User>();

  user?: userDto;

  selectedUsers: User[] = [];

  submitted?: boolean;

  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  role: Role[];
  schemeDto: SchemeList;
  departmentDto: DepartmentList;
  levelType: LevelType[];
  stateList: LevelMasterDto[];
  stateListDto: any;
  districtLevelList: LevelMasterDto[];
  districtListDto: any;
  blockLevelList: LevelMasterDto[];
  BlockLevelDto: any;
  levelTypeDto: LevelType;
  villageLevelDto: any;
  zonalDto:any;
  zonalList: any = [
    { name: 'Zonal-1' },
    { name: 'Zonal-2' },
    { name: 'Zonal-3' },
    { name: 'Zonal-4' },
    { name: 'Zonal-5' },
  ];
  selectOption: any;
  iaLIst: Agency[];
  iaDto: AgencyDto;
  villageLevelList: LevelMasterDto[];
   // validation fields properties
   public showMobileError: boolean = false;
   public emailError = false;
   //required fields property
  ngSelectInvalid = false;
  invalidLevelType =true;
  optionInvalid=true;
  isStateRole:boolean=false;
  viewOnly: boolean;
  userContextSubscription: Subscription;
  userPasswordDialog: boolean;
  userResetPassword: any;
  hide:boolean=true;
  currentPath: string;
  routerEventsSubscription: Subscription;
  levelmasterId: any;
  headingText: string;
  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private levelMasterService: LevelMasterService,
    public fontService: FontService,
    private agencyService: AgencyService,
    protected notificationService: NotificationService,
    public validationService: ValidationService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private loginService:SigninService,
    private userRoleService:UserRoleService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.routerEventsSubscription =this.route.url.subscribe(urlSegments => {
      this.currentPath = urlSegments.map(segment => segment.path).join('/');
    });
    this.userRoleService.getuserContext().subscribe((res) => { 
      this.levelmasterId = res.levelMaster.id;
      if (res.role.code?.startsWith('SA')) {
        this.isStateRole = true;
      }
    },
    ()=>{
      this.onError();
    })
    this.cols = [
      {
        field: 'userName',
        header: 'User Name',
        jhiTranslate: 'tableHead.user.name',
        isSortable: true,
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'mobile',
        header: 'Mobile',
        jhiTranslate: 'tableHead.mobile.number',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'email',
        header: 'Email',
        jhiTranslate: 'tableHead.email',
        isSortable: true,
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'role',
        header: 'Role Name',
        jhiTranslate: 'tableHead.role',
        isSortable: false,
        isFilterable: false,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: '',
        header: 'District',
        isSortable: false,
        isFilterable: false,
        isSelectcolumn: false,
        
      },
      {
        field: '',
        header: 'Block',
        isSortable: false,
        isFilterable: false,
        isSelectcolumn: false,
      },
    ];
  }

  openNew() {
    this.user = new userDto();
    this.submitted = false;
    this.userDialog = true;
    this.loadValue();
  }
  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.user.mobile
    );
    !this.user.mobile ? (this.showMobileError = false) : true;
    return !this.showMobileError ;
  }

  validateEmail() {
    this.emailError = !this.validationService.isEmailValid(this.user.email);
    !this.user.email ? (this.emailError = false) : true;
    return !this.emailError ;
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {}
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {}
    );
    this.userService.getRole().subscribe(
      (res: HttpResponse<Role[]>) => {
        this.role = res.body;
      },
      () => {}
    );
    let filterQuery = {
      page: 0,
      size: 10,
    };
    this.levelMasterService
      .LevelMasterDtoFilter({ ...filterQuery }, [
        {
          key: 'id',
          operation: 'equals',
          value: 1,
        },
      ])
      .subscribe(
        (res: HttpResponse<LevelMasterDto[]>) => {
          this.stateList = res.body;
          this.stateListDto = res.body[0];
        },
        () => {}
      );
  }

  changeRole() {
    this.onStateLevelChange();
    this.levelTypeDto = null;
    this.districtListDto = null;
    this.BlockLevelDto = null;
    this.villageLevelDto = null;
    this.iaDto = null;
    let index = 0;
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
        switch (true) {
          case this.user.roleDto.name.startsWith('State'):
            this.selectOption = 'ia';
            index = res.body.findIndex((_) => _.name === 'State');
            this.user.levelMasterId = 1;
            this.getIa();
            this.levelTypeDto = res.body[index];

            break;
          case this.user.roleDto.name.startsWith('District'):
            this.selectOption = 'ia';
            index = res.body.findIndex((_) => _.name === 'District');

            this.levelTypeDto = res.body[index];
            if (this.user.id) {
              this.levelMasterService
                .getLevelMaster(this.user.levelMasterId)
                .subscribe(
                  (res: HttpResponse<userDto[]>) => {
                    this.districtListDto = res.body;
                  },
                  () => {}
                );
            }
            break;
          case this.user.roleDto.name.startsWith('Block') || this.user.roleDto.name.startsWith('Deputy'):
            this.selectOption = 'ia';
            index = res.body.findIndex((_) => _.name === 'Block');
            this.levelTypeDto = res.body[index];
            if (this.user.id) {
              this.levelMasterService
                .getLevelMaster(this.user.levelMasterId)
                .subscribe(
                  (res: HttpResponse<userDto[]>) => {
                    this.BlockLevelDto = res.body;
                    this.levelMasterService
                      .getLevelMaster(this.BlockLevelDto.parentId)
                      .subscribe(
                        (res: HttpResponse<userDto[]>) => {
                          this.districtListDto = res.body;
                        },
                        () => {}
                      );
                  },
                  () => {}
                );
            }
            break;
          case this.user.roleDto.name.startsWith('Village'):
            this.selectOption = 'ia';
            index = res.body.findIndex((_) => _.name === 'Village');
            this.levelTypeDto = res.body[index];
            if (this.user.id) {
              this.levelMasterService
                .getLevelMaster(this.user.levelMasterId)
                .subscribe(
                  (res: HttpResponse<userDto[]>) => {
                    this.villageLevelDto = res.body;
                    this.levelMasterService
                      .getLevelMaster(this.villageLevelDto.parentId)
                      .subscribe(
                        (res: HttpResponse<userDto[]>) => {
                          this.BlockLevelDto = res.body;
                          this.levelMasterService
                            .getLevelMaster(this.BlockLevelDto.parentId)
                            .subscribe(
                              (res: HttpResponse<userDto[]>) => {
                                this.districtListDto = res.body;
                                this.onStateLevelChange();
                                this.onBlockLevelChange();
                                this.onDistrictLevelChange();
                              },
                              () => {}
                            );
                        },
                        () => {}
                      );
                  },
                  () => {}
                );
            }
            break;

          case this.user.roleDto.name.startsWith('Zonal'):
            this.selectOption = 'Zonal';
            index = res.body.findIndex((_) => _.name === 'Village');
            this.levelTypeDto = res.body[index];
            if (this.user.id) {
              this.levelMasterService
                .getLevelMaster(this.user.levelMasterId)
                .subscribe(
                  (res: HttpResponse<userDto[]>) => {
                    this.villageLevelDto = res.body;
                    this.levelMasterService
                      .getLevelMaster(this.villageLevelDto.parentId)
                      .subscribe(
                        (res: HttpResponse<userDto[]>) => {
                          this.BlockLevelDto = res.body;
                          this.onBlockLevelChange();
                        },
                        () => {}
                      );
                    this.levelMasterService
                      .getLevelMaster(this.BlockLevelDto.parentId)
                      .subscribe(
                        (res: HttpResponse<userDto[]>) => {
                          this.districtListDto = res.body;
                          this.onDistrictLevelChange();
                        },
                        () => {}
                      );
                  },
                  () => {}
                );
            }

            break;

          default:
        }
      },
      () => {}
    );
  }

  hideDialog() {
    this.userDialog = false;
    this.submitted = false;
    this.invalidLevelType=false;
    this.optionInvalid=false;
    this.ngSelectInvalid = false;
    this.districtListDto=null;
    this.BlockLevelDto=null;
    this.villageLevelDto=null;
    this.zonalDto=null;
    this.iaDto=null;
    this.stateListDto=null;
    this.levelTypeDto=null;
    this.selectOption=null;
    this.showMobileError=false;
    this.emailError=false;
    this.viewOnly=false;
  }

  saveUser() {
    if(this.levelTypeDto?.name === "District"||
    this.levelTypeDto?.name==="Block"||
    this.levelTypeDto?.name==="Village"){
      if(!this.districtListDto){
        this.invalidLevelType=false;
      }
    }
    if(this.levelTypeDto?.name === "Block"||
    this.levelTypeDto?.name ==="Village"){
      if(!this.BlockLevelDto){
        this.invalidLevelType=false;
      }
    }
    if(this.levelTypeDto?.name === "Village"){
      if(!this.villageLevelDto){
        this.invalidLevelType=false;
      }
    }
    if(this.selectOption=='Zonal'){
      if(!this.zonalDto)
      this.optionInvalid=false;
    }
    if(this.selectOption=='ia'){
      if(!this.iaDto)
      this.optionInvalid=false;
    }
    const isFormValid =
    this.validateMobile() &&
    this.validateEmail() &&
    this.user.userName &&
    this.user.roleDto &&
    this.levelTypeDto &&
    this.stateListDto &&    
    this.invalidLevelType &&
    this.selectOption &&
    this.optionInvalid &&
    this.user.password 
  if (!isFormValid) {
    this.ngSelectInvalid = true;
    return;
  }
  this.user.implementingAgencyId = this.iaDto?.id;
  this.user.levelId = this.levelTypeDto.id;
  this.user.zonalName = this.zonalDto?.name
  this.ngSelectInvalid = false;
  if(!this.user.id){
    this.userService.saveUser(this.user).subscribe(
      (res: HttpResponse<userDto[]>) => {
        this.userDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }
  else{
    this.userService.updateUser(this.user).subscribe(
      (res: HttpResponse<userDto[]>) => {
        this.userDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );
  }
    
  }

  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if($event.type === 'view'){
        this.viewOnly=true;
      }
      this.spinner.show();
      this.userService.getUser($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.user = res.body;
          this.userDialog = true;
          this.loadValue();
          this.changeRole();
          this.agencyService
            .getAgency(this.user.implementingAgencyId)
            .subscribe(
              (res: HttpResponse<AgencyDto>) => {
                this.iaDto = res.body;
              },
              () => {}
            );
          this.spinner.hide();
        },
        () => {
          this.onError();
        }
      );
    } 
    else if($event.type === 'activate'){
      this.spinner.show();
      this.userService.userActivate($event.value).subscribe(
        (res)=>{
          this.spinner.hide();
          this.userPasswordDialog=true;
          this.userResetPassword=res;
        },
        (onError)=>{
          this.spinner.hide();
          if (onError.status == 400){
            this.notificationService.alertError(onError.error.errorKey,'');
          } else{
            this.notificationService.alertError("An unexpected error occured",'');
          }
        }
      )
    }else if($event.type === 'deactivate'){
      this.spinner.show();
      this.userService.userDeactivate($event.value).subscribe(
        (res)=>{
          this.spinner.hide();
          this.notificationService.alertInfo('User Deactivated','');
          this.loadPage(1);
        },
        (onError)=>{
          this.spinner.hide();
          if (onError.status == 400){
            this.notificationService.alertError(onError.error,'');
          } else{
            this.notificationService.alertError("An unexpected error occured",'');
          }
        }
      )
    }
    else if($event.type === 'unlock'){
      this.spinner.show();
      this.userService.userUnlock($event.value).subscribe(
        (res)=>{
          this.spinner.hide();
          this.notificationService.alertInfo('User unlock','');
          this.loadPage(1);
        },
        (onError)=>{
          this.spinner.hide();
          if (onError.status == 400){
            this.notificationService.alertError(onError.error.Message,'');
          } else{
            this.notificationService.alertError("An unexpected error occured",'');
          }
        }
      )
    }
    else {
      const userDto = new LoginDto();
      userDto.keyCloakUserId = $event.value.keyCloakUuid;
      userDto.username = $event.value.username;
      userDto.password = $event.value.password;
      userDto.refreshToken =
        this.localStorageService.retrieve('refreshtoken') ??
        this.sessionStorageService.retrieve('refreshtoken');

      this.userService.deleteUser(userDto).subscribe(
        (res: HttpResponse<userDto[]>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

   
    this.filterProps = this.filterProps ?? [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    if(this.currentPath === 'user'){
      this.headingText = 'User List';
      let filterQuery = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
      };
      this.userService.filter({ ...filterQuery }, this.filterProps).subscribe(
        (res: HttpResponse<User[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
    else{
      this.headingText = 'Block User List';
      this.isStateRole = true;
      let filterQuery = {
        page: pageToLoad - 1,
        size: this.itemsPerPage,
        parentId:this.levelmasterId
      };
      this.userService.getBlockUser({ ...filterQuery }).subscribe(
        (res: HttpResponse<User[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
    
  }

  protected onSuccess(
    data: User[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  onLevelType() {}
  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  getIa() {
    let filterQuery = {
      page: 0,
      size: 10,
    };
    const filter = [
      {
        key: 'levelMaster',
        operation: 'equals',
        value: this.user.levelMasterId,
      },
      {
        key: 'activeFlag',
        operation: 'equals',
        value: 'YES',
      },
    ];
    this.agencyService.filter({ ...filterQuery }, filter).subscribe(
      (res: HttpResponse<Agency[]>) => {
        this.iaLIst = res.body;
      },
      () => {
        this.onError();
      }
    );
  }

  onDistrictLevelChange($event?) {
    if (this.levelTypeDto.name === 'District') {
      this.user.levelMasterId = this.districtListDto.id;
      this.getIa();
    }
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.districtListDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onVillageLevelChange() {
    this.user.levelMasterId = this.villageLevelDto.id;
    this.getIa();
  }

  onBlockLevelChange($event?) {
    if (this.levelTypeDto.name === 'Block') {
      this.user.levelMasterId = this.BlockLevelDto.id;
      this.getIa();
    }

    if ($event) {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.BlockLevelDto.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.villageLevelList = res.body;
          },
          () => {}
        );
    }
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
  }
}