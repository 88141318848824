import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { OpeningBalanceService } from './opening-balance.service';

import { SearchResult } from 'src/app/shared/models/search-result';
import {
  OpeningBalance,
  OpeningBalanceDto,
  SnaFundsDto,
} from './opening-balance';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { AccountSnaDto } from '../account-sna/account-sna';
import { AccountSnaService } from '../account-sna/account-sna.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-opening-balance',
  templateUrl: './opening-balance.component.html',
  styleUrls: ['./opening-balance.component.scss'],
})
export class OpeningBalanceComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  openingBalanceDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<OpeningBalance> =
    new SearchResult<OpeningBalance>();
  openingBalance?: OpeningBalanceDto;
  submitted?: boolean;
  selectedMapping: string;
  page: number;
  itemsPerPage: number = 10;
  filterProps: any = [];
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  enableAdd: boolean;
  today: Date;

  //required fields property
  ngSelectInvalid: boolean = false;

  constructor(
    private openingBalanceService: OpeningBalanceService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    protected router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private accountSnaService: AccountSnaService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage(1);

    this.cols = [
      {
        field: 'department',
        header: 'Dept Name',
        jhiTranslate: 'tableHead.department.name',
        isSortable: false,
        isFilterable: true,
        width: 13,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'scheme',
        header: 'Scheme Name',
        jhiTranslate: 'tableHead.scheme.name',
        isSortable: true,
        isFilterable: true,
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'openingBalance',
        header: 'Opening Balance',
        jhiTranslate: 'tableHead.openingBal',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type:'amount'
      },
      {
        field: 'dateOfAob',
        header: 'Date of Account Opening Balance',
        jhiTranslate: 'tableHead.date.account.openingBal',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
    this.today = new Date();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => {
        this.onError();
      }
    );
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.openingBalanceService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<OpeningBalance[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: OpeningBalance[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/opening-balance'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    if (data.length === 0) {
      this.enableAdd = true;
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {}

  openNew() {
    this.loadValue();
    this.openingBalance = new OpeningBalanceDto();
    this.openingBalanceDialog = true;
  }

  hideDialog() {
    this.openingBalance = new OpeningBalanceDto();
    this.ngSelectInvalid = false;
    this.openingBalanceDialog = false;
    this.submitted = false;
  }

  async saveData() {
    const isFormValid =
      this.openingBalance.departmentDto &&
      this.openingBalance.schemeDto &&
      this.openingBalance.dateOfAob &&
      this.openingBalance.openingBalance;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    let snaFund = new SnaFundsDto();
    snaFund.openingFundsDto = this.openingBalance;
    snaFund.snaDto = await this.getSna();
    if (snaFund.snaDto) {
      this.openingBalanceService.save(snaFund).subscribe(
        (res: HttpResponse<OpeningBalanceDto[]>) => {
          this.openingBalanceDialog = false;
          this.enableAdd = false;
          this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
    }
	else{
		this.notificationService.alertError('Please Add  SNA', '');
	}
  }

  getSna(): Promise<AccountSnaDto> {
    return new Promise<any>((resolve) => {
      this.accountSnaService.getAllSnaList().subscribe(
        (res: any) => {
          resolve(res.body[0]);
        },
        (err) => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.openingBalanceService.getOpeningBalance($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.openingBalance = res.body;
          this.openingBalanceDialog = true;
          if (this.openingBalance.dateOfAob) {
            this.openingBalance.dateOfAob = new Date(
              this.openingBalance.dateOfAob
            );
          }
          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.openingBalanceService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
}
