import { LevelMaster } from "../level-master/level-master";

export class StatutoryAccountDetail {
     id:number;
     levelMaster:LevelMaster;
     deductionType:StatutoryDeductionType;
     bankAccountNumber:string;
     bankName:string;
     bankBranchName:string;
     bankAccountName:string;
     bankIfscCode:string;
     tinNumber:string;
     tanNumber:string;
     gstNumber:string;
     panNumber:string;
     approve:boolean;
     reject:boolean;
     rejectReason:string;
     status:string;
     activeFlag:string;
     createdBy:number;
     updatedBy:number;
     createdOn:Date;
     updatedOn:Date;
}

export class StatutoryDeductionType{
    id:number;
    name:string;
    code:string;
    activeFlag:string;
    createdOn:Date;
    updatedOn:Date;
}