<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" >Statutory Payment</h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="input-row">
        <p-calendar [(ngModel)]="date" view="month" dateFormat="mm/yy" [readonlyInput]="true"></p-calendar>
        <button pButton pRipple label="Search" (click)="onSearch()" class="p-button-sm"></button>
        <button *ngIf="statutoryData" pButton pRipple label="Export to Excel" (click)="export()"  class="p-button-success p-button-sm export-button"></button>
    </div>
    <div class="row">
       
                <div class="col-12 px-4" *ngIf="statutoryData">
                    <p-table
                    id="statutoryDataTable"
                    [columns]="cols"
                    [value]="statutoryData.statutoryPaymentDtos"
                    [resizableColumns]="true"
                    responsiveLayout="scroll"
                    styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th class="middle-align-custom">S.No</th>
                            <th class="middle-align-custom">Work Id</th>
                            <th class="middle-align-custom" filterMatchMode="icon">Vendor Name
                                <p-columnFilter type="text" field="vendorName" display="menu"></p-columnFilter>
                            </th>
                            <th class="middle-align-custom">PAN No</th>
                            <th class="middle-align-custom">GST No</th>
                            <th class="middle-align-custom">TDS On GST</th>
                            <th class="middle-align-custom">IT Amount</th>
                            <th class="middle-align-custom">Labour Cess</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-index="rowIndex" let-rowData let-columns="columns">
                        <tr>
                            <td class="middle-align-custom">{{index+1}}</td>
                            <td class="middle-align-custom" *ngFor="let col of columns">
                                {{ rowData[col.field] }}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="5" class="middle-align-custom">Total</td>
                            <td class="middle-align-custom">{{ statutoryData.gstOnTDSTotal | currency:'INR':'':'1.2-2':'en-IN' }}</td>
                            <td class="middle-align-custom">{{ statutoryData.itTotal | currency:'INR':'':'1.2-2':'en-IN' }}</td>
                            <td class="middle-align-custom">{{ statutoryData.labourCessTotal | currency:'INR':'':'1.2-2':'en-IN' }}</td>
                            
                        </tr>
                    </ng-template>
                    </p-table>
                </div>
            </div>

