import { StatutoryPaymentVoucherService } from './../statutory-payment-voucher/statutory-payment-voucher.service';
import { Component, OnInit } from '@angular/core';
import {
  StatutoryAccountDetail,
  StatutoryDeductionType,
} from 'src/app/module/statutory-account-config/statutory-account-model';
import { FontService } from 'src/app/shared/font-service/font.service';
import {
  StatutoryBillDescription,
  StatutoryDeductionDto,
  StatutoryTransaction,
  StatutoryVoucherGeneration,
} from '../statutory-payment-voucher/statutory-payment-voucher.model';
import { ActivatedRoute, Router } from '@angular/router';
import { StatutoryAccountService } from 'src/app/module/statutory-account-config/statutory-account.service';
import { HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { catchError, map } from 'rxjs/operators';
import { PaymentService } from '../payment-voucher/payment-voucher.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { filterQuery } from 'src/app/shared/common.model';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';
import * as _ from 'lodash';
import { LevelMaster } from 'src/app/module/level-master/level-master';

@Component({
  selector: 'app-miscellaneous-payment-voucher',
  templateUrl: './miscellaneous-payment-voucher.component.html',
  styleUrls: ['./miscellaneous-payment-voucher.component.scss'],
})
export class MiscellaneousPaymentVoucherComponent implements OnInit {
  statutoryDeductionList: StatutoryDeductionType[];
  statutoryVoucherGeneration: StatutoryVoucherGeneration;
  date: Date;
  month: Date;
  enableRevertButton: boolean;
  enableSignButton: boolean;
  ngSelectInvalid: boolean;
  filterProps: any;
  statutoryDeductionDto: StatutoryDeductionDto;
  readonly: boolean;
  param1: any;
  param2: any;
  param3: any;
  signButtonName: string;
  enableApproved: boolean;
  checkAmount: boolean;
  checkstatusButton: boolean;
  downloadButton: boolean;
  enableZonalButton: boolean;
  requiredError: boolean;
  reasonDialog: boolean;
  rejectionReason: string;
  isEditable: boolean = true;
  paymentSucessDialog: boolean;
  paymentSucessText: any;
  voucherConfirmDialog: boolean = false;
  adminuserDto: any;
  accountTypeList: string[];
  vendorList: any;
  currentPage: number = 0;
  loadingMore: boolean = false;
  statutoryDeductionListDtos: StatutoryBillDescription[];
  

  constructor(
    public fontService: FontService,
    public activatedRoute: ActivatedRoute,
    protected statutoryAccountService: StatutoryAccountService,
    protected statutoryPaymentVoucherService: StatutoryPaymentVoucherService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private loginService: SigninService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private commercialVendorService: CommercialVendorService,
  ) {}

  ngOnInit(): void {
    this.loadDeductionType();
    this.getRole().subscribe((roleCode) => {
      if (roleCode) {
        this.adminuserDto = roleCode;
        this.paymentService
          .getByImplemeting(roleCode.implementingAgencyId)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.statutoryVoucherGeneration.statutoryTransactionDto.implementingAgency =
                res.body;
            },
            () => {}
          );
        if (
          roleCode.role.code === userRoles.roleBMaker ||
          roleCode.role.code === userRoles.roleStateMaker ||
          roleCode.role.code === 'VMAKER' ||
          roleCode.role.code === userRoles.roleDMaker
        ) {
          this.signButtonName = 'Perform e-Sign Administrative Officer';
        } else if (
          roleCode.role.code === userRoles.roleBChecker1 ||
          roleCode.role.code === userRoles.roleStateChecker ||
          roleCode.role.code === userRoles.roleDChecker1 ||
          roleCode.role.code === 'VC1' ||
          roleCode.role.code === 'ZC1'
        ) {
          this.enableApproved = true;
          if (roleCode.role.code === 'BC1' || roleCode.role.code === 'ZC1') {
            this.checkAmount = true;
          }
          this.signButtonName = 'Perform e-Sign Project Director';
          this.enableRevertButton = true;
          this.readonly = true;
        } else if (
          roleCode.role.code === userRoles.roleDChecker2 ||
          roleCode.role.code === userRoles.roleStateApprover ||
          roleCode.role.code === userRoles.roleBChecker2 ||
          roleCode.role.code === 'VC2'
        ) {
          this.enableApproved = true;
          this.signButtonName = 'Perform e-Sign District Collector';
          this.enableRevertButton = true;
          this.downloadButton = true;
          this.readonly = true;
        }
        if (
          roleCode.role.code === 'BMAKER' ||
          roleCode.role.code === 'BC1' ||
          roleCode.role.code === 'BC2' ||
          roleCode.role.code === 'ZC1'
        ) {
          this.enableZonalButton = true;
        }
        if (this.activatedRoute.snapshot.queryParamMap.get('response')) {
          this.paymentSucessDialog = true;
          this.paymentSucessText =
            this.activatedRoute.snapshot.queryParamMap.get('response');
        }
        if (this.activatedRoute.snapshot.queryParamMap.get('error')) {
          this.notificationService.alertError(
            'Error Occurred: ' +
              this.activatedRoute.snapshot.queryParamMap.get('error'),
            ''
          );
        }
        if (!this.activatedRoute.snapshot.queryParamMap.get('id')) {
          this.statutoryVoucherGeneration = new StatutoryVoucherGeneration();
          this.statutoryVoucherGeneration.statutoryTransactionDto =
            new StatutoryTransaction();
            this.statutoryVoucherGeneration.statutoryTransactionDto.levelMaster=
                new LevelMaster();
                this.statutoryVoucherGeneration.statutoryTransactionDto.levelMaster.id
                = roleCode.levelMaster.id;
          this.date = new Date();
          this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber =
            this.activatedRoute.snapshot.queryParamMap.get('VoucherNo');
        }
        if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
          this.spinner.show();
          this.statutoryPaymentVoucherService
            .getStatutoryPayment(
              Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
            )
            .subscribe(
              (res: HttpResponse<StatutoryVoucherGeneration>) => {
                this.spinner.hide();
                this.statutoryVoucherGeneration = res.body;
                this.isEditable =
                  this.statutoryVoucherGeneration.statutoryTransactionDto.isEditable;
                if (
                  this.checkAmount &&
                  res.body.statutoryTransactionDto.transactionAmount <=
                    500000 &&
                  res.body.statutoryTransactionDto.levelOneSign
                ) {
                  res.body.statutoryTransactionDto.levelTwoSign = 'signed';
                }
                if (this.statutoryVoucherGeneration.statutoryTransactionDto)
                  if (
                    this.statutoryVoucherGeneration.statutoryTransactionDto
                      .signStatus
                  ) {
                    this.readonly = true;
                    this.enableSignButton = true;
                  }
                this.date = new Date(
                  this.statutoryVoucherGeneration.statutoryTransactionDto.transactionDate
                );
              },
              (error) => {
                this.spinner.hide();
              }
            );
        }
      }
    });
  }

  getRole(): Observable<any> {
    return this.loginService.getuserDto().pipe(
      map((res: any) => res.body),
      catchError((error) => {
        return of(null);
      })
    );
  }

  loadDeductionType() {
    let query = {
      page: 0,
      size: 10,
    };
    let filterProps = [];
    let filquery = new filterQuery();
    filquery.key = 'isMiscellaneous';
    filquery.operation = 'equals';
    filquery.value = true;
    filterProps.push(filquery);
    filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    filterProps.push(filquery);
    this.statutoryAccountService
      .getAllDeductionTypesByFilter(query, filterProps)
      .subscribe(
        (res) => {
          this.statutoryDeductionList = res.body;
        },
        () => {}
      );
  }

  loadAccountType() {
    this.vendorList = null;
    this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail = new StatutoryAccountDetail();
    this.statutoryVoucherGeneration.statutoryTransactionDto.vendor = null;
    this.statutoryVoucherGeneration.statutoryTransactionDto.paymentType = null;
    this.statutoryDeductionListDtos = [];
    switch (
      this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType.code
    ) {
      case 'MD':
        this.accountTypeList = [
          'Holding Account',
          
        ];
        break;
      case 'CWD':
        this.accountTypeList = ['To Mapped Vendor'];
        break;
    }
  }

  clearData() {
    this.vendorList = null;
    this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail = new StatutoryAccountDetail();
    this.statutoryVoucherGeneration.statutoryTransactionDto.vendor = null;
    this.statutoryVoucherGeneration.statutoryTransactionDto.paymentType = null;
    this.statutoryDeductionListDtos = [];
  }

  loadAccountDetails() {
    this.vendorList = null;
    this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail = new StatutoryAccountDetail();
    this.statutoryVoucherGeneration.statutoryTransactionDto.vendor = null;
    this.statutoryDeductionListDtos = [];
    switch (
      this.statutoryVoucherGeneration.statutoryTransactionDto.paymentType
    ) {
      case 'Holding Account':
        this.loadAccount();
        break;
      case 'To Mapped Vendor':
        this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail = null;
        this.paymentService.getVendorMappingByWorkId(this.statutoryVoucherGeneration.statutoryTransactionDto.workId).subscribe(
          (res: HttpResponse<any>) => {
            this.vendorList = [];
            res.body.forEach(element => {
              this.vendorList.push(element.commercialVendor);
            });
          },
          () => {}
        );
        break;
      
    }
  }

  popupValue() {

  }

  

  loadAccount(){
    let filter=[];
   filter = [ {
    key: 'activeFlag',
    operation: 'equals',
    value: 'YES',
  },{
    key: 'approve',
    operation: 'equals',
    value: true,
  },{
    key: 'deductionType.code',
    operation: 'equals',
    value:'HA' ,
  },
  {
    key: 'levelMaster.id',
    operation: 'equals',
    value:this.statutoryVoucherGeneration.statutoryTransactionDto.levelMaster.id,
  }
];
    this.statutoryAccountService.getAllStatutoryDetailsfilter({ page:0,size:1 }, filter)
      .subscribe(
        (res: HttpResponse<StatutoryAccountDetail[]>) => {
          this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail=new StatutoryAccountDetail();
          if(res.body.length>0){
            this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail=res.body[0];
            this.loadStatutoryBillDescription();
          } else {
            this.notificationService.alertError('Please configure the account for the selected dection type to proceed','');
            this.statutoryDeductionListDtos = [];
            this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount = null;
            if(this.statutoryVoucherGeneration.statutoryBillDescription){
              this.statutoryVoucherGeneration.statutoryBillDescription=[];
            }
            return;
          }
        },
        ()=>{
          this.notificationService.alertError('Error in loading account details','');
        }
      );
      
  }

  loadStatutoryBillDescription(){
      this.statutoryPaymentVoucherService.getMiscellaneousDeductionList
      (this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType.code,
        this.statutoryVoucherGeneration.statutoryTransactionDto.workId).subscribe(
        (response)=>{
          this.spinner.hide();
          this.statutoryVoucherGeneration.statutoryBillDescription = [];
          this.statutoryDeductionListDtos =  _.cloneDeep(response.body.statutoryDeductionListDtos);
          this.statutoryDeductionListDtos.forEach(element => {
            element.isDefault = true
            this.statutoryVoucherGeneration.statutoryBillDescription.push(element);
          });
          this.statutoryVoucherGeneration.statutoryBillDescription=response.body.statutoryDeductionListDtos;
          this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount=response.body.totalAmount;
          this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount=response.body.totalAmount;
        },
        (onError)=>{
          this.spinner.hide();
          this.statutoryVoucherGeneration.statutoryBillDescription=[];
          this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount=0;
          this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount=0;
          if (onError.status == 400) {
                this.notificationService.alertError(
                  onError.error.errorKey,'');
                  this.statutoryVoucherGeneration.statutoryTransactionDto.workId = null;
                  this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType = null;
                  this.statutoryVoucherGeneration.statutoryTransactionDto.paymentType = null;
                  this.statutoryVoucherGeneration.statutoryTransactionDto.statutoryAccountDetail =null;
                  this.statutoryVoucherGeneration.statutoryTransactionDto.vendor = null;
          }else {
            this.notificationService.alertError('Error!','');
          }
        }
      )
    
  }

  VoucherCheckStatus(element:StatutoryBillDescription){
    if(element.isDefault){
      this.statutoryVoucherGeneration.statutoryBillDescription.push(element);
    }
    else{
      const index = this.statutoryVoucherGeneration.statutoryBillDescription.findIndex((item) => item.voucherNumber === element.voucherNumber);
      this.statutoryVoucherGeneration.statutoryBillDescription.splice(index, 1);
    }
    let totalAmount = 0; 
    this.statutoryVoucherGeneration.statutoryBillDescription.forEach(element => {
      totalAmount += element.deductionAmount
    });
    this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount = totalAmount;
    this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount =totalAmount;
  }


  

  saveVoucher() {
    this.ngSelectInvalid = false;
    const isFormValid =
      this.statutoryVoucherGeneration.statutoryTransactionDto.workId &&
      this.statutoryVoucherGeneration.statutoryTransactionDto.remarks &&
      this.statutoryVoucherGeneration.statutoryTransactionDto.deductionType &&
      this.statutoryVoucherGeneration.statutoryTransactionDto.paymentType &&
      this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      this.notificationService.alertError(
        'Please fill all the mandotory fields marked with *',
        ''
      );
      return;
    }
   
    // if(!this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount){
    //   this.notificationService.alertError('Total Amount is not generated','');
    //   return;
    // }
    // if(this.statutoryVoucherGeneration.statutoryTransactionDto.totalAmount<this.statutoryVoucherGeneration.statutoryTransactionDto.transactionAmount){
    //   this.notificationService.alertError('Transaction Amount should not exceed total Amount','');
    //   return;
    // }

    if (
      this.statutoryVoucherGeneration.statutoryTransactionDto
        .transactionAmount <= 0
    ) {
      this.notificationService.alertError(
        'Transaction Amount should be greater than 0',
        ''
      );
      return;
    }
    if (
      this.adminuserDto.role.code === userRoles.roleBMaker ||
      this.adminuserDto.role.code === userRoles.roleStateMaker ||
      this.adminuserDto.role.code === userRoles.roleDMaker  

    ) {
      this.voucherConfirmDialog = true;
      return;
    } else {
      this.generateVoucher();
    }
  }

  submitForm(){
    this.spinner.show();
    this.statutoryPaymentVoucherService
    .statutoryCdacRequest(this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber)
    .subscribe(
      (res: HttpResponse<any>) => {
        console.log('ress',res.body);
        this.spinner.hide();
        this.param1 = res.body.paramOne;
        this.param2 = res.body.paramTwo;
        this.param3 = res.body.paramThree;
        if (!this.statutoryVoucherGeneration.statutoryTransactionDto.id) {
          this.router.navigate(['/miscellaneous-payment-page'], {
            queryParams: {
              id: res.body.paramFour,
            },
          });
        }
        this.localStorageService.store('refresh', 'Yes');
        const formElement = document.createElement('form');
        formElement.method = 'POST';
        formElement.action = environment.CDAC_URL;
        let inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "eSignRequest";
        inputElement.value = res.body.paramOne;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "aspTxnID";
        inputElement.value = res.body.paramTwo;
        formElement.appendChild(inputElement);
        inputElement = document.createElement('input');
        inputElement.type = 'hidden';
        inputElement.name = "Content-Type";
        inputElement.value = res.body.paramThree;
        formElement.appendChild(inputElement);
        document.body.appendChild(formElement);
        formElement.submit();
      },
      (error) => {
        this.spinner.hide();
        
      }
    );
    
  }

  generateVoucher() {
    this.voucherConfirmDialog = false;
    this.spinner.show();
    this.statutoryPaymentVoucherService
      .generateVoucher(this.statutoryVoucherGeneration)
      .subscribe(
        (response: any) => {
          this.readonly = true;
          this.spinner.hide();
          this.enableSignButton = true;
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (onError) => {
          this.spinner.hide();
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.title, '');
          } else {
            this.notificationService.alertError(
              'An unexpected error occurred.',
              ''
            );
          }
        }
      );
  }

  getfile() {
    this.statutoryPaymentVoucherService
      .statutoryCdacRequest(
        this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.param1 = res.body.paramOne;
          this.param2 = res.body.paramTwo;
          this.param3 = res.body.paramThree;
          this.enableSignButton = true;
          if (!this.statutoryVoucherGeneration.statutoryTransactionDto.id) {
            this.router.navigate(['/miscellaneous-payment-page'], {
              queryParams: {
                id: res.body.paramFour,
              },
            });
          }
        },
        () => {}
      );
  }

  getActionUrl() {
    this.localStorageService.store('refresh', 'Yes');
    return environment.CDAC_URL;
  }

  getPdf() {
    this.paymentService
      .getPdfFile(
        this.statutoryVoucherGeneration.statutoryTransactionDto.voucherNumber,
        false
      )
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
        },
        (err) => {
          this.notificationService.alertError('Error!', '');
        }
      );
  }

  revert() {
    this.spinner.show();
    this.statutoryPaymentVoucherService
      .revertTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/miscellaneous-payment-table'], {});
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  rejectDialog() {
    if (!this.rejectionReason) {
      this.requiredError = true;
      return;
    } else {
      this.reasonDialog = false;
      this.reject();
    }
  }

  reject() {
    this.spinner.show();
    this.statutoryPaymentVoucherService
      .rejectTranaction(
        Number(this.activatedRoute.snapshot.queryParamMap.get('id')),
        this.rejectionReason
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          this.spinner.hide();
          this.router.navigate(['/miscellaneous-payment-table'], {});
        },
        () => {
          this.spinner.hide();
          this.notificationService.alertError('Unexpected error occured!!', '');
        }
      );
  }

  redirect() {
    this.router.navigate(['/miscellaneous-payment-table'], {
      queryParams: {
        id: String(this.statutoryVoucherGeneration.statutoryTransactionDto.id),
      },
    });
  }
}
