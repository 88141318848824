<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Vendor Mapping
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card p-5">
                    <div class="row">
                        <div class="col-2">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Work ID
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="tenderMapping.tenderMapping.workId" name="name" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" [disabled]="tenderMapping.tenderMapping.id" uppercase style="text-transform:uppercase" class="form-control dropdown-width" (change)="onprojectid()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tenderMapping.tenderMapping.workId}"
                                id="name" />
                        </div>

                        <div *ngIf="tenderMapping.tenderMapping.workId" class="col-2">
                            <p [ngClass]="fontService.regularBoldClass">
                                Work Name:<br />{{tenderMapping?.tenderMapping?.work?.projectName}}
                            </p>
                        </div>
                        <div *ngIf="tenderMapping.tenderMapping.workId" class="col-2">
                            <p [ngClass]="fontService.regularBoldClass">
                                Work Total Amount:
                                <br />{{tenderMapping?.tenderMapping?.work?.totalCost}}
                            </p>
                        </div>
                        <div *ngIf="tenderMapping.tenderMapping.workId" class="col-lg-2 col-md-6 col-sm-4">
                            <p [ngClass]="fontService.regularBoldClass">
                                Work Total Utilized Amount:
                                <br />{{tenderMapping?.tenderMapping?.work?.utilizedTotalCost}}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tenderMapping?.tenderMapping?.work" class="row">
            <div class="col-lg-5">
                <div class="card p-5 invoice-card">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.name">Vendor Name</label>
                            <ng-select id="scheme" [disabled]="readonly || loadingMore" [(ngModel)]="selectedVendor" [multiple]="true" [items]="vendorList" bindLabel="name" appearance="outline" [readonly]="readonly" [searchable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !this.selectedVendor}"
                                [closeOnSelect]="true" [clearable]="false" (search)="vendorFilter($event)" (change)="popupValue()" (scrollToEnd)="onScrollToEnd()" class="form-control dropdown-width">
                            </ng-select>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Total
                                Selected Vendor</label>
                            <p>{{tenderMapping?.vendorMappings?.length}}</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label class="form-control-label req" for="tenderNo" [ngClass]="fontService.labelClass">Tender No</label>
                            <input type="text" class="form-control" maxlength="15" [(ngModel)]="tenderMapping.tenderMapping.tenderNo" name="tenderNo" autocomplete="off" id="levelType" [readonly]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tenderMapping.tenderMapping.tenderNo}"
                            />
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label req" for="tenderDate" [ngClass]="fontService.labelClass">Tender Date</label>
                            <p-calendar styleClass="datepicker" class="same_line_fix" [maxDate]="maxDate" [minDate]="minDate" [(ngModel)]="tenderMapping.tenderMapping.tenderDate" [disabled]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tenderMapping.tenderMapping.tenderDate }"
                                appendTo="body"></p-calendar>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label class="form-control-label req" for="tenderNo" [ngClass]="fontService.labelClass">Work/Procurement No</label>
                            <input type="text" class="form-control" maxlength="15" [(ngModel)]="tenderMapping.tenderMapping.workProcurementNo" name="tenderNo" autocomplete="off" id="levelType" [readonly]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tenderMapping.tenderMapping.workProcurementNo}"
                            />
                        </div>

                        <div class="col-md-6">
                            <label class="form-control-label req" for="workProcurement" [ngClass]="fontService.labelClass">Work/Procurement Date</label>
                            <p-calendar styleClass="datepicker" class="same_line_fix" [(ngModel)]="tenderMapping.tenderMapping.workProcurementDate" [maxDate]="maxDate" [minDate]="minDate" [disabled]="readonly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !tenderMapping.tenderMapping.workProcurementDate}"
                                appendTo="body"></p-calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="tenderMapping?.tenderMapping?.work" class="col-lg-7">
                <div class="card p-5 invoice-card">
                    <div class="row">
                        <p-table id="tableslector" [value]="tenderMapping.vendorMappings" styleClass="p-datatable-gridlines p-datatable-striped" [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>S.No</th>
                                    <th [ngClass]="fontService.labelClass">
                                        Vendor Name
                                    </th>

                                    <th [ngClass]="fontService.labelClass">
                                        Account No

                                    </th>
                                    <th [ngClass]="fontService.labelClass">
                                        IFSC Code
                                    </th>
                                    <th [ngClass]="fontService.labelClass">
                                        GST No
                                    </th>
                                    <th [ngClass]="fontService.labelClass">
                                        PAN No
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list let-index="rowIndex">
                                <tr>
                                    <td>{{ index + 1 }}</td>
                                    <td>

                                        {{list?.commercialVendor?.name}}
                                    </td>


                                    <td>
                                        {{list?.commercialVendor?.accountNumber}}
                                    </td>
                                    <td>
                                        {{list?.commercialVendor?.ifscCode}}
                                    </td>
                                    <td>
                                        {{list?.commercialVendor?.gstNo}}
                                    </td>
                                    <td>
                                        {{list?.commercialVendor?.panNo}}
                                    </td>

                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="selectedVendor?.length !== 0 && tenderMapping?.tenderMapping?.work" class="row">
            <div class="col-md-12">
                <div class="card p-5">
                    <div class="row">
                        <div class="col-lg-4">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Upload Tendor</label>
                            <div>
                                <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                                    Maximum file size should be 1MB</small>
                            </div>
                            <p-fileUpload *ngIf="!readonly" accept="image/png,image/jpeg,image/jpg,application/pdf" (onUpload)="onUpload($event,'tendor')" [customUpload]="true" [disabled]="isFileUploaded" (uploadHandler)="onUpload($event,'tendor')" auto="true" multiple="false" [showUploadButton]="false"
                                [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                            </p-fileUpload>
                            <div class="row mt-2" *ngIf="isFile">
                                <div class="col">
                                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument('tendor')">{{tenderMapping?.tenderMapping?.fileUploadTendor}}</a>
                                    <i class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument('tendor')"></i>
                                    <i class="pi pi-download add-margin" (click)="downloadDocument('tendor')"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Upload Workorder</label>
                            <div>
                                <small class="mb-2" *ngIf="!readonly" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                                    Maximum file size should be 1MB</small>
                            </div>
                            <p-fileUpload *ngIf="!readonly" accept="image/png,image/jpeg,image/jpg,application/pdf" (onUpload)="onUpload($event,'workOrder')" [customUpload]="true" [disabled]="isFileUploaded" (uploadHandler)="onUpload($event,'workOrder')" auto="true" multiple="false"
                                [showUploadButton]="false" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                            </p-fileUpload>
                            <div class="row mt-2" *ngIf="isFileUploaded">
                                <div class="col">
                                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument('workOrder')">{{tenderMapping?.tenderMapping?.fileUpload}}</a>
                                    <i class="pi pi-times add-margin" *ngIf="!readonly" (click)="removeDocument('workOrder')"></i>
                                    <i class="pi pi-download add-margin" (click)="downloadDocument('workOrder')"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p-toolbar>
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!approveView" pButton pRipple icon="pi pi-times" class="p-button-raised p-button-danger" (click)="cancel()">
                                <span class="button-label" style="padding-right: inherit;" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton">
                                </span>
                            </button>
                            <button *ngIf="!readonly" pButton pRipple icon="pi pi-check" class="p-button-raised ms-4 p-button-success" (click)="save()">
                                <span class="button-label" style="padding-right: inherit;" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton">
                                </span>
                            </button>
                            <button *ngIf="approveView" pButton pRipple icon="pi pi-check" class="p-button-success custom-button" (click)="approveVendorMapping(tenderMapping.tenderMapping.id)">
                                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.approvebutton"></span>
                              </button>
                            <button *ngIf="approveView" pButton pRipple icon="pi pi-times" class="p-button-danger custom-button" (click)="rejectVendorMapping(tenderMapping.tenderMapping.id)">
                              <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.rejectbutton"></span>
                            </button>
                        </div>
                    </p-toolbar>
                </div>
            </div>
        </div>
    </div>
    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>

</div>