import { Component, OnInit } from '@angular/core';
import { DrillDownReportService } from '../drillDown.service';
import { VoucherTranacstionDto, countReportModel } from '../drillDown.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DatePipe } from '@angular/common';
import { SearchResult } from 'src/app/shared/models/search-result';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { HttpResponse } from '@angular/common/http';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommercialVendorService } from 'src/app/module/commercial-vendors/commercial-vendors.service';

@Component({
  selector: 'app-vendor-report',
  templateUrl: './vendor-report.component.html',
  styleUrls: ['./vendor-report.component.scss']
})
export class VendorReportComponent implements OnInit {
  activeIndex = 0;
  activeIndexBlock = 0;
  districtVoucherList: countReportModel[];
  toDate: Date;
  fromDate: Date;
  itemsPerPage: any = 10;
  VendorList: SearchResult<countReportModel> = new SearchResult<countReportModel>();
  currentLevelMasterId: any;
  VoucherDialog: boolean;
  currentStatus: any;
  districtLevelList: LevelMasterDto[];
  district:any;
  cols: any;
  sign2VoucherDialog: boolean;
  sign3VoucherDialog: boolean;
  districtVendorList: countReportModel[];
  dataLevel: string;
  filterLevel: string;
  blockVendorList: countReportModel[];
  constructor(private drillDownReportService: DrillDownReportService, public fontService: FontService, private datePipe: DatePipe, private levelMasterService: LevelMasterService, private spinner: NgxSpinnerService,commercialVendorService:CommercialVendorService) {}
  

  ngOnInit(): void {
    this.dataLevel = 'District';
    this.getVoucherStatusByDistrict();
    this.onStateLevelChange();
  }

  getVoucherStatusByDistrict(id?) {
    let filterQuery = {};
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    if(this.dataLevel === 'Block'){
      filterQuery['filterLevel'] = 'District';
      filterQuery['parentId'] = 1; 
    }
    if(this.district){
      filterQuery['levelMasterId'] = this.district?.id; 
    }
    else if(id){
      filterQuery['parentId'] = id;
      filterQuery['filterLevel'] = 'Block';
    }
    else{
      filterQuery['parentId'] = 1; 
    }
    filterQuery['dataLevel'] = this.dataLevel;
   
    this.drillDownReportService.getDistrictVendorCount(filterQuery).subscribe(
      res => {
        if(id){
          this.activeIndexBlock = 1;
          this.blockVendorList = res.body;
        }
        else{
          this.districtVendorList = res.body;
        }
      },
      error => {}
    );
  }

  loadDistrictVendor(id: number, pageToLoad?,status?:string) {
    this.cols = null;
    this.VoucherDialog = true;
    this.spinner.show();
    this.currentStatus = status;
    this.currentLevelMasterId = id;
    this.cols = [
      {
        field: 'levelMasterName',
        header: 'District',
        jhiTranslate: 'tableHead.district.name',
        isSortable: false,
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'commercialVendorName',
        header: 'Firm Name/Company Name',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },
     
      {
        field: 'mobile',
        header: 'Mobile No',
        jhiTranslate: 'tableHead.mobile.number',
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankName',
        header: 'Bank Name',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'bankBranch',
        header: 'Branch',
        jhiTranslate: 'tableHead.branch.name',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'accountNumber',
        header: 'Account No',
        jhiTranslate: 'tableHead.account.number',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
      },
      {
        field: 'panNo',
        header: 'Pan No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'gstNo',
        header: 'GST No',
        jhiTranslate: 'tableHead.gstNo',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'status',
        header: 'Status',
        isSortable: true,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
      },
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id};
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    filterQuery['status'] = status;
    this.drillDownReportService.getVendorCountWise(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.VendorList.total = Number(res.headers.get('X-Total-Count'));
        this.VendorList.items = res.body ?? [];
        this.VendorList = { ...this.VendorList};
      },
      error => {
        this.spinner.hide();
        this.VoucherDialog = false;
      }
    );
  }

 

  

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    if (this.currentStatus) {
      this.loadDistrictVendor(this.currentLevelMasterId, event.page,this.currentStatus);
    }
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onTabChange(event: any): void {
    switch (event.index) {
      case 0:
        this.dataLevel = 'District';
        
        break;
      case 1:
        this.dataLevel = 'Block';
        this.filterLevel = 'District';
        break;
    }
    this.getVoucherStatusByDistrict();
  }

}
