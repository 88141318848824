
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserContext, UserRoleService } from 'src/app/shared/service/user-role.service';
import {DashboardCard, FundTransactions, ProjectSummary, ProjectSummaryByLevel} from './dashboard';
import { DashboardService } from './dashboard.service';
import { FundsService } from 'src/app/module/funds/funds.service';
import { Funds } from 'src/app/module/funds/funds';
import enIN from '@angular/common/locales/en-IN';
import {registerLocaleData } from '@angular/common';
import { LevelMaster } from 'src/app/module/level-master/level-master';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  dashboardCardDetails:DashboardCard;
  funds: Funds[];
  fundTransactions:FundTransactions;
  activeIndex = 0;
  fundLimitActiveIndex = 0;
  exprLimitActiveIndex = 0;
  balLimitActiveIndex = 0;
  workActiveIndex = 0;
  limitFundByYear: any;
  limitFundBlock: any;
  fundExpenditure: FundTransactions;
  fundBalance: FundTransactions;
  expenditureByYear: any;
  expenditureBlock: any;
  balanceByYear: any;
  balanceBlock: any;
  projectSummary: ProjectSummary;
  projectSummaryByLevel:ProjectSummaryByLevel;
  projectSummaryByBlock:ProjectSummaryByLevel;
  levelMasterId=1;
  finYearId: number;

  
  constructor(private dashboardService:DashboardService, private fundsService: FundsService,
    public fontService:FontService) { 
    registerLocaleData(enIN);
    }

  
  
  ngOnInit(): void {
    this.dashboardService.getDashboardCardDetails(this.levelMasterId).subscribe(
      (res)=>{
        this.dashboardCardDetails=res.body;
      },
      (error)=>{}
    )
    let filterQuery = {
      page:0,
      size: 10,
    };
    this.fundsService.filter({ ...filterQuery }, []).subscribe(
      (res: HttpResponse<Funds[]>) => {
        this.funds = res.body;
      },
      () => {
       
      }
    );

    this.dashboardService.fundsTransactionByYear().subscribe(
      (res)=>{
        this.fundTransactions=res.body;
      },
      (error)=>{}
    )

    this.dashboardService.fundsExpenditureByYear().subscribe(
      (res)=>{
        this.fundExpenditure=res.body;
      },
      (error)=>{}
    )

    this.dashboardService.fundsBalanceByYear().subscribe(
      (res)=>{
        this.fundBalance=res.body;
      },
      (error)=>{}
    )
    this.dashboardService.getProjectSummary().subscribe(
      (res)=>{
        this.projectSummary=res.body;
      },
      (error)=>{}
    )
  }

  loadfundLimitDistrict(id,level?){
    if(level){
      this.dashboardService.limitFundByYear(this.finYearId,undefined,id).subscribe(
        (res)=>{
            this.fundLimitActiveIndex=2;
            this.limitFundBlock=res.body;
        },
        (error)=>{}
      )
    } else{
      this.finYearId=id;
      this.dashboardService.limitFundByYear(id,undefined,this.levelMasterId).subscribe(
        (res)=>{
          this.fundLimitActiveIndex=1;
          this.limitFundByYear=res.body;
        },
        (error)=>{}
      )
    }
  }

  loadExpenditureDistrict(id,level?){
    if(level){
      this.dashboardService.expenditureByYear(this.finYearId,undefined,id).subscribe(
        (res)=>{
          this.exprLimitActiveIndex=2;
          this.expenditureBlock=res.body;
        },
        (error)=>{}
      )
    } else{
      this.finYearId=id;
      this.dashboardService.expenditureByYear(id,undefined,this.levelMasterId).subscribe(
        (res)=>{
          this.exprLimitActiveIndex=1;
          this.expenditureByYear=res.body;
        },
        (error)=>{}
      )
    }
  }

  loadBalanceDistrict(id,level?){
    if(level){
      this.dashboardService.balanceByYear(this.finYearId,undefined,id).subscribe(
        (res)=>{
          this.balLimitActiveIndex=2;
          this.balanceBlock=res.body;
        },
        (error)=>{}
      )
    } else{
      this.finYearId=id;
      this.dashboardService.balanceByYear(id,undefined,this.levelMasterId).subscribe(
        (res)=>{
          this.balLimitActiveIndex=1;
          this.balanceByYear=res.body;
        },
        (error)=>{}
      )
    }
  }

  loadWorkDistrict(id,level?){
    if(level){
      this.dashboardService.getProjectSummaryByLevel(this.finYearId,undefined,id).subscribe(
        (res)=>{
          this.workActiveIndex=2;
          this.projectSummaryByBlock=res.body;
        },
        (error)=>{}
      )
    } else{
      this.finYearId=id;
      this.dashboardService.getProjectSummaryByLevel(id,undefined,this.levelMasterId).subscribe(
        (res)=>{
          this.workActiveIndex=1;
          this.projectSummaryByLevel=res.body;
        },
        (error)=>{}
      )
    }
  }

  onCardTabChange(){
    this.fundLimitActiveIndex=0;
    this.limitFundByYear=null;
    this.limitFundBlock=null;
    this.exprLimitActiveIndex=0;
    this.expenditureByYear=null;
    this.expenditureBlock=null;
    this.balLimitActiveIndex=0;
    this.balanceByYear=null;
    this.balanceBlock=null;
    this.workActiveIndex=0;
    this.projectSummaryByLevel=null;
    this.projectSummaryByBlock=null;
  }

  onfundLimitTabChange(event: any){
    if (event.index === 0){
      this.limitFundByYear=null;
      this.limitFundBlock=null;
    }
    else if(event.index === 1){
      this.limitFundBlock=null;
    }
  }
  onExpenditureTabChange(event: any){
    if (event.index === 0){
      this.expenditureByYear=null;
      this.expenditureBlock=null;
    }
    else if(event.index === 1){
      this.expenditureBlock=null;
    }
  }
  onBalanceTabChange(event: any){
    if (event.index === 0){
      this.balanceByYear=null;
      this.balanceBlock=null;
    }
    else if(event.index === 1){
      this.balanceBlock=null;
    }
  }
  onWorkTabChange(event: any){
    if (event.index === 0){
      this.projectSummaryByLevel=null;
      this.projectSummaryByBlock=null;
    }
    else if(event.index === 1){
      this.projectSummaryByBlock=null;
    }
  }

  formatCash = n => {
    return +(n / 1e7).toFixed(3);
  };
  
  formatCashLakh = n => {
    return +(n / 1e5).toFixed(3) ;
  }; 
  
}