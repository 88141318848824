<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="tableHead.iACompo.limit.set.list">Limit Set List</h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success" (click)="openNew()"
                        styleClass="button"></button> -->
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disableEditIcon]="true"></custom-filter-table>
            
        </div>
    </div>
    <p-dialog [(visible)]="limitSetDialog" [style]="{width: '1000px'}" header="User Details" [modal]="true"
        styleClass="ib-dialog">
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="aadhaar">Aadhaar Number</label>
                            <input type="text" class="form-control" name="aadhaar" autocomplete="off" id="aadhaar"  required autofocus/>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="otp">OTP</label>
                            <input type="text" class="form-control" name="otp" autocomplete="off" id="otp" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="otp">OTP</label>
                            <input type="text" class="form-control" name="otp" autocomplete="off" id="otp" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="name">Name</label>
                            <input type="text" class="form-control" name="name" autocomplete="off" id="name"  />
                        </div>
                         
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="dateOfBirth">Date of Birth</label>
                            <p-calendar styleClass="datepicker"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label" for="gender">Gender</label>
                            <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true">
                          </ng-select>
                          
                        </div>     
                                      
                    </div>
                 </div>   
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="saveAgency()"></button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
</div>