import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubComponent } from './sub-component';

@Injectable()
export class SubComponentService {
    status: string[] = ['OUTOFSTOCK', 'INSTOCK', 'LOWSTOCK'];

    subComponent: string[] = [
        "Bamboo Watch",
        "Black Watch",
        "Blue Band",
        "Blue T-Shirt",
        "Bracelet",
        "Brown Purse",
        "Chakra Bracelet",
        "Galaxy Earrings",
        "Game Controller",
        "Gaming Set",
        "Gold Phone Case",
        "Green Earbuds",
        "Green T-Shirt",
        "Grey T-Shirt",
        "Headphones",
        "Light Green T-Shirt",
        "Lime Band",
        "Mini Speakers",
        "Painted Phone Case",
        "Pink Band",
        "Pink Purse",
        "Purple Band",
        "Purple Gemstone Necklace",
        "Purple T-Shirt",
        "Shoes",
        "Sneakers",
        "Teal T-Shirt",
        "Yellow Earbuds",
        "Yoga Mat",
        "Yoga Set",

    ];

    constructor(private http: HttpClient) { }

    // getAgencysSmall() {
    //     return this.http.get<any>('assets/Agencys-small.json')
    //         .toPromise()
    //         .then(res => <Agency[]>res.data)
    //         .then(data => { return data; });
    // }

    getCommercialVendors() {
        return this.http.get<any>('./src/assets/Agencys.json')
            .toPromise()
            .then(res => <SubComponent[]>res.data)
            .then(data => { return data; });
    }

    // getAgencysWithOrdersSmall() {
    //     return this.http.get<any>('assets/Agencys-orders-small.json')
    //         .toPromise()
    //         .then(res => <Agency[]>res.data)
    //         .then(data => { return data; });
    // }

    generateCommercialVendors(): SubComponent {
        const accountSna: SubComponent = {
            // id: this.generateId(),
            agencyName: this.generateName(),
            email: "Agency Description",
            // price: this.generatePrice(),
            // quantity: this.generateQuantity(),
            branchName: "Agency Category",
            branchAddress: this.generateStatus(),
            // rating: this.generateRating()
        };

        // Agency.image = Agency.name.toLocaleLowerCase().split(/[ ,]+/).join('-')+".jpg";;
        return accountSna;
    }

    generateId() {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    generateName() {
        return this.subComponent[Math.floor(Math.random() * Math.floor(30))];
    }

    generatePrice() {
        return Math.floor(Math.random() * Math.floor(299) + 1);
    }

    generateQuantity() {
        return Math.floor(Math.random() * Math.floor(75) + 1);
    }

    generateStatus() {
        return this.status[Math.floor(Math.random() * Math.floor(3))];
    }

    generateRating() {
        return Math.floor(Math.random() * Math.floor(5) + 1);
    }

}