<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Failed Transaction List</h5>
                </ng-template>
                <ng-template pTemplate="right">

                </ng-template>
            </p-toolbar>
        </div>
    </div>

    <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
        <p-tabPanel header="Vendor Transaction">
            <div *ngIf="activeIndex === 0" class="row">
                <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchVendorResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disablePaymentEditIcon]="true" [paginator]="false" (emitRow)="onSelectRow($event)" [enableCheckTransaction]="user?.role?.code === userRoles.roleBChecker2 || user?.role?.code === userRoles.roleDChecker2"></custom-filter-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Statutory Transaction">
            <div class="row">
                <div #customerTable id="entities" class="col-12 px-4">
                    <custom-filter-table [columnsValues]="statutorycols" [filterSearchResult]="searchStatutoryResult" (emitRow)="onSelectRow($event)" [enableCheckTransaction]="user?.role?.code === userRoles.roleBChecker2" [paginator]="false"></custom-filter-table>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>



</div>

<p-dialog [(visible)]="otpBox" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        OTP page:
    </p-header>
    <div class="row">
        <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Enter Otp</label
      >
        <input
          type="text" 
          class="form-control"
          autocomplete="off"
          maxlength="6"
          [(ngModel)]="otp"
          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
          name="name"
          id="otp"
          (input)="validateOtp()"
        />
       
        <div *ngIf="otpError" class="mt-2">
            <small style="font-size: 30px;" class="text-danger">{{otpError}}</small>
        </div>
    </div>
    <div class="mb-4 mt-3">
        Resend OTP in: {{ otpTimer }} seconds
    </div>
    <div class="row">
        <div class="col">
            <button *ngIf="enableVerifyButton" (click)="verifyOtp()" class="btn btn-secondary">Verify Otp</button>
        </div>
        <div class="col text-end">
            <button type="button" [disabled]="!enableResendButton" (click)="getUserOtp('reSend')" class="btn btn-secondary">Resend OTP</button>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <!-- <button pButton pRipple class="p-button-danger">
            <span class="button-label" [ngClass]="fontService.buttonClass" label="Reject"
                (click)="rejectDialog()">Reject</span>
        </button> -->
    </ng-template>

</p-dialog>

<p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" (onhide)="loadPage()" styleClass="ib-dialog">
    <p-header [ngClass]="fontService.headingClass">
        Payment Alert Box
    </p-header>
    <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false;this.loadPage()">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Ok"
          (click)="paymentSucessDialog = false;this.loadPage()"
          >Ok</span
        >
      </button>
    </ng-template>
</p-dialog>