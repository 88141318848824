import {
  AdminUserDtoCreatedby2,
  AdminUserDtoUpdatedBy2,
  LevelTypeDto,
} from '../limit-set/limit-set';

export class LevelMaster {
  id: number;
  departmentId: number;
  departmentName: String;
  schemeId: number;
  schemeName: String;
  levelZeroId: number;
  levelZeroCode: string;
  levelZeroName: string;
  levelZeroNameTn: string;
  levelOneId: number;
  levelOneCode: number;
  levelOneName: number;
  levelOneNameTn: string;
  levelTwoId: number;
  levelTwoCode: string;
  levelTwoName: string;
  levelTwoNameTn: string;
  levelThreeId: number;
  levelThreeCode: string;
  levelThreeName: string;
  levelThreeNameTn: string;
  levelFourId: string;
  levelFourCode: string;
  levelFourName: string;
  levelFiveCode: string;
  levelFiveName: string;
}

export class LevelMasterDto {
  id: number;
  name: string;
  parentId: number;
  levelTypeDto: LevelTypeDto;
  code: string;
  createdOn: string;
  updatedOn: string;
  adminUserDtoCreatedBy: number;
  adminUserDtoUpdatedBy: number;
}
