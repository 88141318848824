import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';

import { DepartmentList } from './department-list';
export type EntityArrayResponseType = HttpResponse<DepartmentList[]>;
export type EntityResponseType = HttpResponse<DepartmentList>;

@Injectable()
export class DepartmentListService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/departmentList');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveDepartment');
    protected resourseGetUrl = environment.SERVER_API_URL+('api/')
    constructor(protected http: HttpClient) {}
    filter(): Observable<EntityArrayResponseType> {
      
        return this.http
        .get<DepartmentList[]>(this.resourceFliterUrl,{ observe: 'response' })
    }
    save(departmentList?:DepartmentList): Observable<EntityResponseType> {
        return this.http
        .post<DepartmentList>(this.resourceSaveUrl,departmentList, {observe: 'response' })
    }
}