<div class="row bg">
    <div class="col-7 mx-auto login-form ">
        <div class="text-center">
            <img style="width:40%" src="/./assets/img/logo.png" alt="Image">
        </div>
        <div class="font-login mb-4 mt-4" style="color:#455a64" *ngIf="!otpverified">Forgot Password?</div>
        <div class="font-login mb-4 mt-4" style="color:#455a64" *ngIf="otpverified">Enter your new Password</div>
        <div class="login-content" *ngIf="!otpverified">
            <form class="form" role="form" (ngSubmit)="verifyotp()" [formGroup]="otpForm">
                <div class="form-group mb-4">
                    <input id="username" type="text" class="form-control full" placeholder="USERNAME" formControlName="username" name="username" uppercase required autocomplete="off">
                    <div *ngIf="inValidUser" class="text-danger mt-2">
                        <small>Please enter valid user name</small>
                    </div>
                </div>
                <div class="form-group mb-4" *ngIf="showOtp">
                    <input id="otp" name="otp" maxlength="6" autocomplete="off" formControlName="otp" type="text" class="form-control full" placeholder="ENTER OTP">
                    <div *ngIf="inValidOtp" class="text-danger mt-2">
                        <small>Invalid OTP</small>
                    </div>
                    <div class="mt-2">
                        Resend OTP in: {{ otpTimer }} seconds
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col" *ngIf="showOtp">
                        <button type="submit" class="btn btn-color">Submit</button>
                    </div>
                    <div class="col text-end">
                        <button type="button" [disabled]="disableButton" class="btn btn-color" (click)="sendotp()">{{otpButtonName}}</button>
                    </div>
                </div>
                <div class="footer-logo">
                    <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                </div>
            </form>
        </div>
        <div class="login-content" *ngIf="otpverified">
            <form class="form" role="form" (ngSubmit)="submit()" [formGroup]="forgotPwdForm">

                <div class="form-group mb-4 eye-container">
                    <input id="password" name="password" autocomplete="off" formControlName="password" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,16}$" [type]="hide ? 'password' : 'text'" required class="form-control full" placeholder="NEW PASSWORD">
                    <i class="pi pi-eye" *ngIf="hide" (click)="hide=false"></i>
                    <i class="pi pi-eye-slash" *ngIf="!hide" (click)="hide=true"></i>
                </div>
                <div class="form-group mb-4 eye-container">
                    <input id="confirmPassword" name="confirmPassword" pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,16}$" autocomplete="off" formControlName="confirmPassword" class="form-control full" [type]="phide ? 'password' : 'text'" required
                        placeholder="CONFIRM NEW PASSWORD">
                    <i class="pi pi-eye" *ngIf="phide" (click)="phide=false"></i>
                    <i class="pi pi-eye-slash" *ngIf="!phide" (click)="phide=true"></i>
                </div>
                <div *ngIf="pwdMismatch" class="text-danger mb-2">
                    <small>The passwords you entered do not match. Please re-enter</small>
                </div>
                <div *ngIf="passwordError" class="text-danger mb-2">
                    <small>{{passwordError}}</small>
                </div>
                <div *ngIf="!forgotPwdForm.valid && password" class="text-danger mb-2">
                    <small>Passwords must contain at least one uppercase,one lowercase, one special characters, one Number ,minimum 8 characters and maximun 16 characters</small>
                </div>
                <div class="mb-4">
                    <button type="submit" [disabled]="!forgotPwdForm.valid" class="btn btn-secondary">Submit</button>
                </div>
                <div class="footer-logo">
                    <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                </div>
            </form>
        </div>
    </div>
</div>