import { ComponentMasterDto } from './../../payments/payment-voucher/payment-voucher.model';
import { MajorComponentService } from './../major-component/major-component.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SubComponentService } from './sub-component.service';
import { SubComponent } from './sub-component';
import { SearchResult } from 'src/app/shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { MasterComponent } from '../major-component/major-component';

import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DepartmentListService } from '../department-list/department-list.service';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { ComponentTypeService } from '../component-type/component-type.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelTypeService } from '../level-type/level-type.service';
import { LevelType } from '../level-type/level-type';
import { ComponentType } from '../component-type/component-type';

import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-sub-component',
  templateUrl: './sub-component.component.html',
  styleUrls: ['./sub-component.component.scss'],
})
export class SubComponentComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;
  subComponentDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<MasterComponent> =
    new SearchResult<MasterComponent>();

  subComponent?: ComponentMasterDto;

  selectedSubComponent: SubComponent[] = [];

  submitted?: boolean;

  selectedMapping: string;
  itemsPerPage: number = 10;
  page: number;
  filterProps: any;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  levelType: LevelType[];
  departmentDto: DepartmentList;
  schemeDto: SchemeList;
  levelTypeDto: LevelType;
  grantLevel: ComponentType;
  grantlevelDto: any;
  majaorComponent: ComponentType;
  majaorComponentdto: any;
  minorComponent: ComponentType;
  minorComponentDto: any;

  //required fields property
  ngSelectInvalid: boolean = false;

  constructor(
    private majorComponentService: MajorComponentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private schemeListService: SchemeListService,
    private componentTypeService: ComponentTypeService,
    private levelTypeService: LevelTypeService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
      this.changeLanguageLoadFields();
    });
  }

  ngOnInit() {
    this.changeLanguageLoadFields();
    
  }

  changeLanguageLoadFields() {
    const lang = this.translateService.currentLang;
    if(lang === 'en'){
      this.cols = [
        {
          field: 'leveltypename',
          header: 'Level Type',          
          jhiTranslate: 'tableHead.levelType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentmastername',
          header: 'Component Type',
          jhiTranslate: 'tableHead.compoType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentzerocode',
          header: 'Major Component Code',
          jhiTranslate: 'tableHead.major.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentzeroname',
          header: 'Major Component Name',
          jhiTranslate: 'tableHead.major.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentonecode',
          header: 'Minor Component Code',
          jhiTranslate: 'tableHead.minor.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentonename',
          header: 'Minor Component Name',
          jhiTranslate: 'tableHead.minor.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componenttwocode',
          header: 'Sub Component Code',
          jhiTranslate: 'tableHead.sub.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componenttwoname',
          header: 'Sub Component Name',
          jhiTranslate: 'tableHead.sub.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
      ];
    }
    else{
      this.cols = [
        {
          field: 'leveltypename',
          header: 'Level Type',
          jhiTranslate: 'tableHead.levelType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentmasternametn',
          header: 'Component Type',
          jhiTranslate: 'tableHead.compoType',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentzerocode',
          header: 'Major Component Code',
          jhiTranslate: 'tableHead.major.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentzeronameTn',
          header: 'Major Component Name',
          jhiTranslate: 'tableHead.major.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentonecode',
          header: 'Minor Component Code',
          jhiTranslate: 'tableHead.minor.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componentonenameTn',
          header: 'Minor Component Name',
          jhiTranslate: 'tableHead.minor.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componenttwocode',
          header: 'Sub Component Code',
          jhiTranslate: 'tableHead.sub.compCode',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
        {
          field: 'componenttwonameTn',
          header: 'Sub Component Name',
          jhiTranslate: 'tableHead.sub.compName',
          isSortable: true,
          isFilterable: true,
          isSelectcolumn: true,
        },
      ];
    }
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => { }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => { }
    );
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  onLevelType() {
    this.componentTypeService.find(this.levelTypeDto.id).subscribe(
      (res: HttpResponse<ComponentType>) => {
        this.grantLevel = res.body;
      },
      () => { }
    );
  }

  ongrandLevel() {
    this.componentTypeService
      .findbyMasterId(this.subComponent.componentTypeDto.id)
      .subscribe(
        (res: HttpResponse<ComponentType>) => {
          this.majaorComponent = res.body;
        },
        () => { }
      );
  }
  onMajorComponent() {
    this.componentTypeService
      .findbyParentId(this.majaorComponentdto.id)
      .subscribe(
        (res: HttpResponse<ComponentType>) => {
          this.minorComponent = res.body;
        },
        () => { }
      );
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    this.majorComponentService
      .filter({ ...filterQuery }, this.filterProps)
      .subscribe(
        (res: HttpResponse<MasterComponent[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: MasterComponent[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    if (navigate) {
      this.router.navigate(['/sub-component'], {
        queryParams: {
          page: this.page - 1,
          size: this.itemsPerPage,
        },
      });
    }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void { }

  openNew() {
    this.subComponent = new ComponentMasterDto();
    this.subComponentDialog = true;
    this.loadValue();
  }

  hideDialog() {    
    this.levelTypeDto = new LevelType();
    this.schemeDto = new SchemeList();
    this.departmentDto = new DepartmentList();
    this.subComponent = new ComponentMasterDto();
    this.ngSelectInvalid = false;
    this.departmentDto=null;
    this.schemeDto=null;
    this.levelTypeDto = null;
    this.levelType =null;
    this.grantlevelDto = null;
    this.grantLevel=null;
    this.majaorComponent=null;
    this.minorComponentDto = null;
    this.majaorComponentdto = null;
    this.minorComponent=null;
    this.subComponentDialog = false;
    this.submitted = false;
  }

  saveData() {
    const isFormValid =
      this.departmentDto &&
      this.schemeDto &&
      this.subComponent.componentTypeDto &&
      this.majaorComponentdto &&
      this.minorComponentDto &&
      this.subComponent.name &&
      this.subComponent.code

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.subComponent.parentId = this.minorComponentDto.id;
    this.majorComponentService.save(this.subComponent).subscribe(
      (res: HttpResponse<any>) => {
        this.subComponentDialog = false;
        this.loadPage(this.page);
        this.notificationService.alertSuccess('Saved Successfully', '');
      },
      () => {
        this.onError();
        this.notificationService.alertError('Error!', '');
      }
    );

  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }
}
