import { NgxSpinnerService } from 'ngx-spinner';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ComponentTypeService } from './component-type.service';
import { ComponentDto, ComponentType } from './component-type';
import { SearchResult } from 'src/app/shared/models/search-result';
import { HttpResponse } from '@angular/common/http';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { LevelTypeService } from '../level-type/level-type.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { DepartmentList } from '../department-list/department-list';
import { SchemeList } from '../scheme-list/scheme-list';
import { LevelType } from '../level-type/level-type';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { Dialog } from 'primeng/dialog';
import { NotificationService } from 'src/app/alert/notification.service';

@Component({
  selector: 'app-component-type',
  templateUrl: './component-type.component.html',
  styleUrls: ['./component-type.component.scss'],
})
export class ComponentTypeComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog') dialog: Dialog;
  componentTypeDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<ComponentType> = new SearchResult<ComponentType>();

  componentType?: ComponentDto;

  selectedComponentType: ComponentType[] = [];

  submitted?: boolean;

  selectedMapping: string;
  departmentList: DepartmentList[];
  schemeList: SchemeList[];
  levelType: LevelType[];
  departmentDto: any
  schemeDto: any

  //required fields property
  ngSelectInvalid: boolean = false;
  viewOnly: boolean;

  constructor(
    private componentTypeService: ComponentTypeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private departmentListService: DepartmentListService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService

  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.loadPage(1);
    this.cols = [
      {
        field: 'levelTypeDto',
        header: 'Level Type Name',
        jhiTranslate: 'tableHead.levelType',
        isSortable: true,
        isSelectcolumn: true,
        type: 'dropDown',
      },
      {
        field: 'name',
        header: 'Component Type Name',
        jhiTranslate: 'tableHead.compoType',
        isSortable: true,
        isSelectcolumn: true,
      },
    ];
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  // checkName(){
  //   let filterQuery = {
  //     page: 0,
  //     size: 10,
  //   };
  //   const filter = [
  //     {
  //       key: 'name',
  //       operation: 'equals',
  //       value: this.levelType.name.toUpperCase(),
  //     },
     
  //   ];
  //   if (this.levelType.id) {
  //     filter.push({
  //       key: 'id',
  //       operation: 'notEquals',
  //       value: String(this.levelType.id),
  //     });
  //   }
  //   this.levelTypeService.levelTypefilter({ ...filterQuery }, filter).subscribe(
  //     (res: HttpResponse<LevelType[]>) => {
  //       if(res.body.length != 0){
  //         this.showNameError = "Name Already Exists"
  //       }
  //       else{
  //         this.showNameError = null
  //       }
  //     },
  //     () => {
  //       this.onError();
        
  //     }
  //   );
  
  // }

  openNew() {
    this.componentType = new ComponentDto();
    this.submitted = false;
    this.componentTypeDialog = true;
    this.loadValue()
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    this.componentTypeService.filter().subscribe(
      (res: HttpResponse<ComponentType[]>) => {
        this.searchResult.total = res.body.length;
        this.spinner.hide();

        this.searchResult.items = res.body;
        this.searchResult = { ...this.searchResult };
      },
      () => {
        this.onError();
      }
    );
  }

  protected onError(): void { }

  hideDialog() {
    this.departmentDto = null;
    this.schemeDto = null;
    this.levelType=null;
    this.componentType = new ComponentDto();
    this.ngSelectInvalid = false;
    this.componentTypeDialog = false;
    this.submitted = false;
    this.viewOnly=false;
  }

  loadValue() {
    this.departmentListService.filter().subscribe(
      (res: HttpResponse<DepartmentList[]>) => {
        this.departmentList = res.body;
      },
      () => { }
    );
    this.schemeListService.filter().subscribe(
      (res: HttpResponse<SchemeList[]>) => {
        this.schemeList = res.body;
      },
      () => { }
    );
  }

  onchangeSchema() {
    this.levelTypeService.filter().subscribe(
      (res: HttpResponse<LevelType[]>) => {
        this.levelType = res.body;
      },
      () => { }
    );
  }

  saveData() {
    const isFormValid =
      this.departmentDto &&
      this.schemeDto &&
      this.componentType.levelTypeDto &&
      this.componentType.name

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }
    this.ngSelectInvalid = false;
    this.componentType.departmentId = this.departmentDto.id
    this.componentType.schemeId = this.schemeDto.id
    this.componentTypeService
      .save(this.componentType)
      .subscribe(
        (res: HttpResponse<DepartmentList>) => {
          this.componentTypeDialog = false;
          this.loadPage();
          this.notificationService.alertSuccess('Saved Successfully', '');
        },
        () => {
          this.onError();
          this.notificationService.alertError('Error!', '');
        }
      );
  }
  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'view') {
      if($event.type === 'view'){
        this.viewOnly=true;
      }
      this.componentTypeService.findbyId($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.componentType = res.body;
          this.departmentListService.filter().subscribe(
            (res: HttpResponse<DepartmentList[]>) => {
              this.departmentList = res.body;
            this.departmentList.forEach((element) => {
            if (element.id === this.componentType.departmentId) {
              this.departmentDto= element;
            }
          });
            },
            () => { }
          );
          this.schemeListService.filter().subscribe(
            (res: HttpResponse<SchemeList[]>) => {
              this.schemeList = res.body;
              this.schemeList.forEach((element) => {
                if (element.id === this.componentType.schemeId) {
                  this.schemeDto= element;
                }
              });
            },
            () => { }
          );
          this.componentTypeDialog = true;
        },
        () => {
          this.onError();
        }
      );
    } 
  }
}
