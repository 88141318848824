<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="nav.payments.eblist">EB Payment List</h5>
                </ng-template>
                <ng-template pTemplate="right">
                    <button
                        *ngIf="roleCode === userRoles.roleDMaker || roleCode === 'VMAKER' || roleCode === userRoles.roleBMaker"
                        pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button"
                        (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.generatevoucher">
                        </span>
                    </button>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult"
                (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [disablePaymentEditIcon]="false"
                (emitRow)="onSelectRow($event)" [showRedirectButton]="false" [lazyLoadOnInit]="false"
                (showTable)="getSubTableDetails($event)" [showTableButton]="true"></custom-filter-table>
        </div>
    </div>

</div>
<p-dialog [(visible)]="enableStatusVoucher" [style]="{width: '1400px',height:'600px'}" [modal]="true"
    [baseZIndex]="1000" styleClass="ib-dialog" (onHide)="enableStatusVoucher = false">
    <p-header [ngClass]="fontService.headingClass">
        {{ "dashBoard.transactionlist" | translate }}
    </p-header>
    <custom-filter-table [columnsValues]="vouchercols" [filterSearchResult]="searchVoucherStatusResult"
        (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [paginator]="false"
        [disableEditIcon]="true"></custom-filter-table>
</p-dialog>