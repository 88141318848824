import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createRequestOption } from '../auth/request/request-util';

export type EntityListResponseType = HttpResponse<any>;

@Injectable({ providedIn: 'root' })
export class ReportService {
 
 

  constructor(protected http: HttpClient) {}
  
  getData(url,parms,payload): Observable<any> {
    if(payload === null){
        payload = []
      }
      const options = createRequestOption(parms);
        return this.http
        .get<any[]>(environment.SERVER_API_URL+url, { params:options, observe: 'response' })
  }
  
 
}