import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { lbcdto } from './lbc.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LbcService {

  protected saveLBCPaymentDtoUrl = environment.SERVER_API_URL+('api/saveLBCTransaction')
  protected getLBCPaymentListUrl = environment.SERVER_API_URL+('api/getLBCTransactionByFilter')

  constructor(protected http: HttpClient) { }
  filter(req?: any,filter?:any): Observable<any> {
    if(filter === null){
      filter = []
    }
    const options = createRequestOption(req);
      return this.http
      .post<lbcdto[]>(this.getLBCPaymentListUrl,filter, { params:options, observe: 'response' })
  }

  save(lbcdto:lbcdto){
      return this.http
      .post<lbcdto>(this.saveLBCPaymentDtoUrl,lbcdto, {observe: 'response' })
  }
}
