<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        <p>Statutory Payment Voucher</p>
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col">
                    <div class="card p-3">
                        <div class="row">
                            <h6 [ngClass]="fontService.headingClass">
                                Generate Voucher
                            </h6>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "payments.voucher.number.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.voucherNumber }}
                                </p>
                            </div>
                            <div class="col-lg-1 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                </p>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "implementing.agency.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.implementingAgency?.name }}
                                </p>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "payments.gst.number.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.implementingAgency?.gstNo }}
                                </p>
                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    {{ "payments.level.type.name" | translate }}:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.levelMaster?.name }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="card p-3">
                        <div class="row mt-2">
                            <div class="col-md-3 col-sm-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" for="month">Month</label><br/>
                                <p-calendar [(ngModel)]="month" view="month" styleClass="datepicker" dateFormat="mm/yy" [readonlyInput]="true" [disabled]="!isEditable || statutoryVoucherGeneration.statutoryTransactionDto.id" (onSelect)="loadStatutoryBillDescription()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !month}"></p-calendar>
                            </div>
                            <div *ngIf="month" class="col-md-3 col-sm-4">
                                <label class="form-control-label req" [ngClass]="fontService.labelClass" for=" statutoryType">Statutory Type</label>
                                <ng-select id="statutoryType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [items]="statutoryDeductionList" [(ngModel)]="statutoryVoucherGeneration.statutoryTransactionDto.deductionType" [disabled]="!isEditable || statutoryVoucherGeneration.statutoryTransactionDto.id"
                                    (change)="loadAccount()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !statutoryVoucherGeneration.statutoryTransactionDto.deductionType}">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankAccountNumber && month">
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Account Number:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankAccountNumber }}
                                </p>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Account Name:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankAccountName }}
                                </p>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Bank Name:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankName }}
                                </p>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Bank Branch:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankBranchName }}
                                </p>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Bank IFSC:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankIfscCode }}
                                </p>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankAccountNumber && month">
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Gst Number:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.gstNumber }}
                                </p>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Pan Number:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.panNumber }}
                                </p>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Tin Number:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.tinNumber }}
                                </p>
                            </div>
                            <div class="col-md-2 col-sm-4">
                                <p [ngClass]="fontService.regularBoldClass">
                                    Tan Number:<br />{{ this.statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.tanNumber }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="statutoryVoucherGeneration?.statutoryBillDescription && month">
                <div class="col">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-md-12 mt-2">
                                <p-table [value]="statutoryVoucherGeneration.statutoryBillDescription" [paginator]="true" [rows]="10" styleClass="p-datatable-gridlines" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Voucher Number</th>
                                            <th>Work Id</th>
                                            <th>Vendor Name</th>
                                            <th>GST Number</th>
                                            <th>PAN Number</th>
                                            <th>Deduction Percentage</th>
                                            <th>Deduction Amount</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-index="rowIndex" let-list>
                                        <tr>
                                            <td>{{index+1}}</td>
                                            <td>{{ list.voucherNumber }}</td>
                                            <td>{{ list.workId }}</td>
                                            <td>{{ list.vendorName }}</td>
                                            <td>{{ list.vendorGstNumber }}</td>
                                            <td>{{ list.vendorPanNumber }}</td>
                                            <td>{{ list.deductionPercentage}}</td>
                                            <td>{{ list.deductionAmount }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-6">
                                <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label
                                ><br />
                                <textarea
                                id="w3review"
                                name="w3review"
                                rows="3" [disabled]="!isEditable"
                                [(ngModel)]="statutoryVoucherGeneration.statutoryTransactionDto.remarks"
                                cols="100" maxlength="150"
                                [ngClass]="{'is-invalid':ngSelectInvalid &&
                                    !statutoryVoucherGeneration.statutoryTransactionDto.remarks}"
                                oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                    onblur="this.value =this.value.trim();">
                                </textarea>
                            </div>
                            <div class="col-6 text-end" *ngIf="this.statutoryVoucherGeneration?.statutoryTransactionDto?.transactionAmount">
                                <div class="col"><b>Total Transaction Amount : {{this.statutoryVoucherGeneration?.statutoryTransactionDto?.transactionAmount}} </b></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p-toolbar *ngIf="statutoryVoucherGeneration?.statutoryBillDescription && !enableRevertButton && isEditable && month">
            <div class="p-toolbar-group-start"></div>
            <div class="p-toolbar-group-end">
              <button *ngIf="!enableSignButton && !readonly && isEditable" pButton pRipple class="p-button-success custom-button margin"
                (click)="saveVoucher()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                  jhiTranslate="common.generatevoucher"></span>
              </button>
              <button *ngIf="enableSignButton" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    >{{signButtonName}}</span>
            </button>
             
            </div>
          </p-toolbar>
          
          <p-toolbar *ngIf="statutoryVoucherGeneration?.statutoryBillDescription && enableRevertButton && isEditable && month">
            <div class="p-toolbar-group-start"></div>
            <button  *ngIf="enableSignButton && !statutoryVoucherGeneration.statutoryTransactionDto.levelTwoSign" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                <span class="button-label" [ngClass]="fontService.buttonClass"
                    >{{signButtonName}}</span>
            </button>
              <div
                *ngIf="
                  (!enableSignButton &&(
                  !statutoryVoucherGeneration?.statutoryTransactionDto?.signStatus || !statutoryVoucherGeneration.statutoryTransactionDto.levelTwoSign) &&  !statutoryVoucherGeneration.statutoryTransactionDto.levelTwoSign)
                "
                class="p-toolbar-group-end"
              >
                <button
                  pButton
                  pRipple
                  (click)="revert()"
                  class="p-button-warning custom-button"
                >
                  <span
                    class="button-label"
                    [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.revertbutton"
                  ></span>
                </button>
                <button
                  *ngIf="!statutoryVoucherGeneration.statutoryTransactionDto.signStatus && !statutoryVoucherGeneration?.statutoryTransactionDto?.revertStatus && enableApproved && isEditable"
                  pButton
                  pRipple
                  (click)="saveVoucher()"
                  class="p-button-success custom-button"
                >
                  <span
                    class="button-label"
                    [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.approvebutton"
                  ></span>
                </button>
                <button *ngIf="isEditable"
                  pButton
                  pRipple
                  (click)="reasonDialog=true"
                  class="p-button-danger custom-button"
                >
                  <span
                    class="button-label"
                    [ngClass]="fontService.buttonClass"
                    jhiTranslate="common.rejectbutton"
                  ></span>
                </button>
              </div>
          </p-toolbar>
          <p-toolbar>
            <div class="p-toolbar-group-start"></div>
            <div class="p-toolbar-group-end">
              <button
              *ngIf="statutoryVoucherGeneration.statutoryTransactionDto.levelTwoSign"
              pButton
              pRipple
              label="Download Pdf"
              (click)="getPdf()"
              class="p-button-success"
            ></button>
            </div>
          </p-toolbar>
    </div>
    <p-dialog
    [(visible)]="reasonDialog"
    [style]="{ width: '500px', height: '350px' }"
    [modal]="true"
    [baseZIndex]="1000"
    styleClass="ib-dialog"
  >
    <p-header [ngClass]="fontService.headingClass">
     Reject Dialog:
    </p-header>
    <div class="form-group mb-4">
      <label class="form-control-label req"
      for="reason" [ngClass]="fontService.labelClass"
      >Reject Reason</label><br />
                                <textarea id="reason" name="reason" rows="3" [(ngModel)]="rejectionReason" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
                                <div *ngIf="requiredError" class="mt-2">
                                    <small class="text-danger">please mention the reason for reject</small>
                                </div>
                            </div>
                            <ng-template pTemplate="footer">
                                <button pButton pRipple class="p-button-danger">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Reject"
          (click)="rejectDialog()"
          >Reject</span
        >
      </button>
                            </ng-template>

                            </p-dialog>
                            <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
                                <p-header [ngClass]="fontService.headingClass">
                                    Payment Alert Box
                                </p-header>
                                <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
                                <ng-template pTemplate="footer">
                                    <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
        <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          label="Ok"
          (click)="redirect()"
          >Ok</span
        >
      </button>
                                </ng-template>
                            </p-dialog>
                            <p-dialog [(visible)]="voucherConfirmDialog" [style]="{ width: '500px', height: '450px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
                                <p-header [ngClass]="fontService.headingClass">
                                    Please confirm the voucher details before proceeding:
                                </p-header>
                                <p><b>Deduction Type: {{statutoryVoucherGeneration?.statutoryTransactionDto?.deductionType?.name}}</b></p>
                                <p><b>Bank Account Name: {{statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankAccountName}}</b></p>
                                <p><b>Bank Account Number: {{statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankAccountNumber}}</b></p>
                                <p><b>Bank Name : {{statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankName}}</b></p>
                                <p><b>Bank Branch Name: {{statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankBranchName}}</b></p>
                                <p><b>Bank Branch IFSC: {{statutoryVoucherGeneration?.statutoryTransactionDto?.statutoryAccountDetail?.bankIfscCode}}</b></p>
                                <p><b>Transaction Amount:{{statutoryVoucherGeneration?.statutoryTransactionDto?.transactionAmount}}</b></p>
                                <ng-template pTemplate="footer">
                                    <button pButton pRipple class="p-button-success">
      <span
        class="button-label"
        [ngClass]="fontService.buttonClass"
        label="Confirm and Save"
        (click)="generateVoucher()"
        >Confirm and Save</span
      >
    </button>
                                </ng-template>

                            </p-dialog>