import { Component, OnInit } from '@angular/core';
import { Employee, EmployeeDTO, EmployeeTypeDTO, ValidationResponse } from './employee.model';
import { EmployeeService } from './employee.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/alert/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  employee: EmployeeDTO;
  employeeDialog: boolean;
  employeeTypeList: EmployeeTypeDTO[];
  subEmployeeList: EmployeeTypeDTO[];
  page: number;
  itemsPerPage: number = 10;
  filterProps: any[];
  searchResult: SearchResult<Employee> = new SearchResult<Employee>();
  public ifscError = false;
  public verifyClicked: boolean;
  validationRes:ValidationResponse;
  readonly:boolean;
  //required fields property
  accountInvalidError: any;
  ngSelectInvalid: boolean = false;
  cols = [
    {
      field: 'name',
      header: 'Name',
      isSortable: true,
      isFilterable: true,
      width: 10,
      isSelectcolumn: true,
      jhiTranslate: 'tableHead.name',
    },
    {
      field: 'employeeType',
      header: 'Employee Type',
      isSortable: true,
      isFilterable: false,
      width: 25,
      isSelectcolumn: true,
      type: 'dropDown',
      jhiTranslate: 'tableHead.employeeType',
    },
    {
      field: 'subEmployeeType',
      header: 'Sub Employee Type',
      isSortable: true,
      isFilterable: false,
      width: 25,
      isSelectcolumn: true,
      type: 'dropDown',
      jhiTranslate: 'tableHead.subEmployeeType',
    },
    {
      field: 'createdOn',
      header: 'Created On',
      jhiTranslate: 'tableHead.createdon',
      isSortable: true,
      isFilterable: true,
      width: 13,
      isSelectcolumn: true,
      type: 'date',
    },
  ];
  bankList: any;
  bankBranch: any;
  bankBranchList: any;

  constructor(
    private employeeService: EmployeeService,
    protected notificationService: NotificationService,
    public validationService: ValidationService,
    public fontService: FontService,
    private spinner: NgxSpinnerService,
    private commercialVendorservice: CommercialVendorService
  ) {}

  ngOnInit(): void {
    this.loadPage(1);
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();

    const pageToLoad: number = page ?? this.page ?? 1;

    let filterQuery = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
      sort: ['createdOn,asc'],
    };
    this.filterProps = [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.employeeService.filter({ ...filterQuery }, this.filterProps).subscribe(
      (res: HttpResponse<Employee[]>) => {
        this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
      },
      () => {
        this.onError();
      }
    );
  }

  protected onSuccess(
    data: Employee[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  protected onError(): void {}

  openNew() {
    this.employee = new EmployeeDTO();
    this.employeeDialog = true;
    this.loadValues();
    this.readonly=false;
  }

  hideDialog() {
    this.employeeDialog = false;
    this.ngSelectInvalid = false;
    this.ifscError = false;
    this.employeeTypeList = null;
    this.subEmployeeList = null;
    this.accountInvalidError = false;
    this.verifyClicked = true;
    this.bankBranch = null;
  }
  accountDetailsChanged() {
    this.verifyClicked = false;
  }
  loadValues() {
    this.employeeService.getEmployeeList().subscribe(
      (res: HttpResponse<EmployeeTypeDTO[]>) => {
        this.employeeTypeList = res.body;
      },
      () => {}
    );
    this.commercialVendorservice.getBankList().subscribe(
      (res: HttpResponse<[]>) => {
        this.bankList = res.body;
      },
      () => {}
    );
  }

  getBankBranchList(event?) {
    this.bankBranch = null;
    let filter = [];
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.employee.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };
    if (event) {
      filter.push({
        key: 'bankBranch',
        operation: 'contains',
        value: event.term.toUpperCase(),
      });
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorservice
        .getBankBranchList(filter, filterQuery)
        .subscribe(
          (res: HttpResponse<[]>) => {
            this.bankBranchList = res.body;
          },
          () => {}
        );
    }
  }

  getBankBranchdetais() {
    let filter = [];
    filter.push({
      key: 'bankIfsc',
      operation: 'equals',
      value: this.employee.ifscCode.toUpperCase(),
    });
    filter.push({
      key: 'bankName',
      operation: 'contains',
      value: this.employee.bankName,
    });

    let filterQuery = {
      page: 0,
      size: this.itemsPerPage,
    };

    this.commercialVendorservice
      .getBankBranchList(filter, filterQuery)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.bankBranch = res.body[0];
        },
        () => {}
      );
  }

  getBankIfsc() {
    this.employee.bankBranch = this.bankBranch.bankBranch;
    this.commercialVendorservice
      .getBankIfscCode(this.employee.bankName, this.employee.bankBranch)
      .subscribe(
        (res: HttpResponse<any>) => {
          this.employee.ifscCode = res.body[0];
        },
        () => {}
      );
  }

  accountValidation() {
    this.spinner.show();
    this.verifyClicked = true;
    this.commercialVendorservice
      .getaccountValidation(this.employee.accountNumber, this.employee.ifscCode)
      .subscribe(
        (res:any) => {
          this.validationRes=JSON.parse(res.body);
         if (this.validationRes.bankTxnStatus==="true") {
            this.accountInvalidError = null;
            this.notificationService.alertSuccess(
              'Account Verfied Sucessfully',
              ''
            );
            this.readonly=true;
          }else{
            this.accountInvalidError="Please input valid account number and branch details";
            this.notificationService.alertError(
              'Account Verification Failed',
              ''
            );
            this.readonly=false;
            this.verifyClicked=false;
          }
          this.spinner.hide();
        },
        () => {
          this.accountInvalidError =
            'Please input valid account number and branch details';
          this.notificationService.alertError('Account Failed', '');
          this.spinner.hide();
        }
      );
  }

  onchangeEmployeeType() {
    this.employee.subEmployeeTypeDto = null;
    this.employeeService
      .getSubEmployeeList(this.employee.employeeTypeDto.id)
      .subscribe(
        (res: HttpResponse<EmployeeTypeDTO[]>) => {
          this.subEmployeeList = res.body;
        },
        () => {}
      );
  }
  validateIfsc() {
    this.ifscError = !this.validationService.isIfscValid(
      this.employee.ifscCode
    );
    !this.employee.ifscCode ? (this.ifscError = false) : true;
    return !this.ifscError && !!this.employee.ifscCode;
  }

  saveData() {
    // if(this.employee.subEmployeeTypeDto===)
    const isFormValid =
      this.validateIfsc() &&
      this.employee.employeeTypeDto &&
      this.employee.subEmployeeTypeDto &&
      this.employee.name &&
      this.employee.grade &&
      this.employee.gpfCpsNo &&
      this.employee.dutyPay &&
      this.employee.dearnessAllowance &&
      this.employee.houseRentAllowance &&
      this.employee.medicalAllowance &&
      this.employee.generalProvidentFund &&
      this.employee.newHealthInsurence &&
      this.employee.familyBenefitFund &&
      this.employee.nhisCorpusFund &&
      this.employee.specialProvidentFund &&
      this.employee.grossAmount &&
      this.employee.totalDeduction &&
      this.employee.netAmount &&
      this.employee.escNet &&
      this.employee.bankName &&
      this.employee.bankBranch &&
      this.employee.accountNumber &&
      this.employee.bankAccName &&
      this.employee.ifscCode &&
      !this.accountInvalidError &&
      this.verifyClicked;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.ngSelectInvalid = false;
    this.employeeService.save(this.employee).subscribe(
      (res: HttpResponse<any>) => {
        this.employeeDialog = false;
        this.notificationService.alertSuccess('Saved Successfully', '');
        this.loadPage(1);
      },
      () => {
        this.notificationService.alertError('Error!', '');
      }
    );
  }

  onSelectRow($event) {
    if ($event.type === 'select') {
      this.employeeService.getemployee($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.readonly=true;
          this.employee = res.body;
          this.employeeDialog = true;
          this.loadValues();
          this.getBankBranchdetais();
          if (this.employee.bankName) {
            this.getBankBranchList();
          }
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.employeeService.deletemployee($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }
}
