export interface gender{
    id?: Number,
    name?: String
}
export interface educationalQualification {
    id?: Number,
    name?: String
}
export interface categoryReservation {
    id?: Number,
    name?: String
}
export interface community {
    id?: Number,
    name?: String
}
export interface  maritalStatus{
    id?: Number,
    name?: String
}
export class filterQuery {
    key?:string
    operation?:string
    value?:any
}

export class FinYearDto{
    id: Number
    name:string
}

