import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { LevelMaster } from 'src/app/module/level-master/level-master';
import { environment } from 'src/environments/environment';
import { filterQuery } from 'src/app/shared/common.model';
export type EntityArrayResponseType = HttpResponse<LevelMaster[]>;

@Injectable({ providedIn: 'root' })
export class DashboardService {
 
  protected fundsDashboardUrl=environment.SERVER_API_URL + 'api/dashboard/fundsDashboard';
  protected fundsTransactionByYearUrl=environment.SERVER_API_URL + 'api/dashboard/fundsTransactionByYear';
  protected fundsExpenditureByYearUrl=environment.SERVER_API_URL + 'api/dashboard/fundsExpenditureByYear';
  protected fundsBalanceByYearUrl=environment.SERVER_API_URL + 'api/dashboard/fundsBalanceByYear';
  protected iaLimitAllocateAmountByYearUrl=environment.SERVER_API_URL + 'api/dashboard/iaLimitAllocateAmountByYear';
  protected iaLimitExpenditureAmountByYearUrl=environment.SERVER_API_URL + 'api/dashboard/iaLimitExpenditureAmountByYear';
  protected iaLimitBalanceAmountByYearUrl=environment.SERVER_API_URL + 'api/dashboard/iaLimitBalanceAmountByYear';
  protected getProjectSummaryByYearUrl=environment.SERVER_API_URL + 'api/dashboard/getProjectSummaryByYear';
  protected getProjectSummaryBylevelMasterUrl=environment.SERVER_API_URL + 'api/dashboard/getProjectSummaryBylevelMaster';


  protected VoucherBillDescURL =
    environment.SERVER_API_URL + 'api/getVoucherBillDescByFilter';
  protected VoucherPersonalDescURL =
    environment.SERVER_API_URL + 'api/getPersonalDescByFilter';
  protected VoucherBillDescEmployeeURL =
    environment.SERVER_API_URL + 'api/getVoucherBillDescEmployeeByFilter';
  protected VoucherBillDescEmployeeRepURL =
    environment.SERVER_API_URL + 'api/getVoucherBillDescElectedRepByFilter';
  
  filterProps: any;

  constructor(protected http: HttpClient) {}

  getDashboardCardDetails(levelMasterId): Observable<any> {
    return this.http.get<any>(`${this.fundsDashboardUrl}?levelMasterId=${levelMasterId}`, {
      observe: 'response',
    });
  }

  fundsTransactionByYear(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.fundsTransactionByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  fundsExpenditureByYear(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.fundsExpenditureByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  fundsBalanceByYear(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.fundsBalanceByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  getProjectSummary(levelMasterId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    return this.http.get<any>(this.getProjectSummaryByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  limitFundByYear(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.iaLimitAllocateAmountByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  expenditureByYear(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.iaLimitExpenditureAmountByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }
  balanceByYear(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.iaLimitBalanceAmountByYearUrl, {
      observe: 'response',
      params: params, 
    });
  }

  getProjectSummaryByLevel(finYearId:number,levelMasterId?:number,parentId?:number): Observable<any> {
    const params: { [key: string]: number } = {};
    params['finYearId'] = finYearId;
    if (levelMasterId) {
      params['levelMasterId'] = levelMasterId;
    }
    if (parentId) {
      params['parentId'] = parentId;
    }
    return this.http.get<any>(this.getProjectSummaryBylevelMasterUrl, {
      observe: 'response',
      params: params, 
    });
  }

  getVoucherSubtableById(personalFlag: string, id: number): Observable<any> {
    let query = new filterQuery();
    switch (personalFlag) {
      case 'NO':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = id;
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(this.VoucherBillDescURL, this.filterProps, {
          observe: 'response',
        });
        break;

      case 'YES':
        query.key = 'vendortransactionId';
        query.operation = 'equals';
        query.value = id;
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(
          this.VoucherPersonalDescURL,
          this.filterProps,
          {
            observe: 'response',
          }
        );
        break;
      case 'E':
        query.key = 'vendorTransactionDto';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(
          this.VoucherBillDescEmployeeURL,
          this.filterProps,
          {
            observe: 'response',
          }
        );

      case 'ER':
        query.key = 'vendorTransactionDto';
        query.operation = 'equals';
        query.value = Number(id);
        this.filterProps = [];
        this.filterProps.push(query);
        return this.http.post<any>(
          this.VoucherBillDescEmployeeRepURL,
          this.filterProps,
          {
            observe: 'response',
          }
        );
    }
  }
}
