import { CommonService } from './../../shared/common.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ProfileService } from './profie.service';
import { AddProfile, Profile } from './profile';
import { SearchResult } from 'src/app/shared/models/search-result';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import {
  educationalQualification,
  gender,
  maritalStatus,
  categoryReservation,
  community,
  filterQuery,
} from 'src/app/shared/common.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { Dialog } from 'primeng/dialog';
import { FontService } from 'src/app/shared/font-service/font.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
// import { saveAs } from 'file-saver';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { userRoles } from 'src/app/shared/models/user-role-code';
import { UserRoleService } from 'src/app/shared/service/user-role.service';
import { Subscription } from 'rxjs';
import { NavbarService } from 'src/app/layout/navbar/navbar.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('dialog') dialog: Dialog;

  profileDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<Profile> = new SearchResult<Profile>();
  profiles?: Profile[];
  profile?: AddProfile;
  selectedProfiles: Profile[] = [];
  submitted?: boolean;
  filterProps: any;
  dateOfAssumingOffice: Date;
  profileDetails: any = {};
  page: number;
  itemsPerPage: number = 10;
  genderList: gender[];
  educationList: educationalQualification[];
  maritalList: maritalStatus[];
  categoryReservationList: categoryReservation[];
  community: community[];
  name: any;
  otp: number;
  Profiledetails: any;
  enableFeild: boolean;

  // validation fields properties
  resendotp = false;
  sendotp: boolean = false;
  otpOpen = false;
  public aadhaarError: boolean = false;
  public otpError: boolean = false;
  public showMobileError: boolean = false;
  public emailError = false;
  public emailBranchError = false;
  public panError = false;
  public pinError = false;
  public showBMobile = false;
  today: Date;
  blockProfilecols: any;

  //required fields property
  ngSelectInvalid: boolean = false;
  aadharNoNameError: string;
  panNoError: string;
  mobileNoError: string;
  emailIdError: string;
  txnNo: any;
  rolecode: any;
  otpButtonName: string = 'Send OTP';
  enableAddNewButton: boolean = false;
  isStateRole: boolean = true;
  userRoles = userRoles;
  userContextSubscription: Subscription;
  enableApprove: boolean;
  viewOnly: boolean;
  approveView: boolean;
  currentPath: string;
  routerEventsSubscription: Subscription;
  enableDistrictApprove: boolean;
  enableBlockApprove: boolean;
  levelMasterId: number;
  constructor(
    private ProfileService: ProfileService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    protected router: Router,
    private CommonService: CommonService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    private navbarService: NavbarService,
    protected notificationService: NotificationService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private loginService: SigninService,
    private route: ActivatedRoute,
    private userRoleService: UserRoleService
  ) {
    this.translateService.onLangChange.subscribe(() => {
      this.updateDialogFont();
    });
  }

  ngOnInit() {
    this.routerEventsSubscription = this.route.url.subscribe((urlSegments) => {
      this.currentPath = urlSegments.map((segment) => segment.path).join('/');
      console.log(this.currentPath);
    });
    this.userRoleService.getuserContext().subscribe(
      (res) => {
        this.rolecode = res.role.code;
        this.levelMasterId = res.levelMaster.id;
        if (res.profileStatus == 'No') {
          this.enableAddNewButton = true;
        }
        if (this.rolecode == userRoles.roleState) {
          this.isStateRole = false;
          this.enableApprove = true;
          this.enableDistrictApprove = true;
        }
        if (this.rolecode == userRoles.roleDChecker1) {
          this.enableBlockApprove = true;
        }
        this.loadPage(1);
      },
      (onError) => {}
    );

    this.cols = [
      {
        field: 'name',
        header: 'Name',
        isSortable: true,
        isFilterable: true,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.name',
      },
      {
        field: 'adminUser',
        header: 'Designation',
        isSortable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_inner_field',
      },
      {
        field: 'dateOfBirth',
        header: 'Date Of Birth',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
        jhiTranslate: 'tableHead.dateOfBirth',
      },
      {
        field: 'panNo',
        header: 'Pan No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'aadhaarNo',
        header: 'Aadhaar',
        jhiTranslate: 'tableHead.aadhaarno',
        isSortable: true,
        isFilterable: true,
        isSelectcolumn: true,
      },
      {
        field: 'termValidUpTo',
        header: 'Term Valid Upto',
        jhiTranslate: 'tableHead.termValidUpTo',
        isSortable: false,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
    this.blockProfilecols = [
      {
        field: 'name',
        header: 'Name',
        isSortable: true,

        isSelectcolumn: true,
        jhiTranslate: 'tableHead.name',
      },
      {
        field: 'adminUser',
        header: 'Designation',
        isSortable: false,
        isSelectcolumn: true,
        type: 'dropDown_with_inner_field',
      },
      {
        field: 'dateOfBirth',
        header: 'Date Of Birth',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
        jhiTranslate: 'tableHead.dateOfBirth',
      },
      {
        field: 'panNo',
        header: 'Pan No',
        jhiTranslate: 'tableHead.panno',
        isSortable: true,

        isSelectcolumn: true,
      },
      {
        field: 'aadhaarNo',
        header: 'Aadhaar',
        jhiTranslate: 'tableHead.aadhaarno',
        isSortable: true,

        isSelectcolumn: true,
      },
      {
        field: 'termValidUpTo',
        header: 'Term Valid Upto',
        jhiTranslate: 'tableHead.termValidUpTo',
        isSortable: false,
        isSelectcolumn: true,
        type: 'date',
      },
    ];
    this.today = new Date();
  }

  ngAfterViewInit() {
    this.updateDialogFont();
  }

  private updateDialogFont() {
    const lang = this.translateService.currentLang;
    const fontClass = lang === 'en' ? 'english-heading' : 'tamil-heading';
    const existingStyleClass = this.dialog.styleClass;
    const updatedStyleClass = `${existingStyleClass} ${fontClass}`;
    this.dialog.styleClass = updatedStyleClass;

    // Update label fonts
    const labels = document.querySelectorAll('.form-control-label');
    if (labels) {
      labels.forEach((label) => {
        if (lang === 'en') {
          label.classList.add('english-label');
          label.classList.remove('tamil-label');
        } else {
          label.classList.add('tamil-label');
          label.classList.remove('english-label');
        }
      });
    }
  }

  loadValue() {
    this.CommonService.getGenderList().subscribe(
      (res: HttpResponse<gender[]>) => {
        this.genderList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.geteducationList().subscribe(
      (res: HttpResponse<educationalQualification[]>) => {
        this.educationList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getMartialStatusList().subscribe(
      (res: HttpResponse<maritalStatus[]>) => {
        this.maritalList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getCategoryReservationList().subscribe(
      (res: HttpResponse<categoryReservation[]>) => {
        this.categoryReservationList = res.body;
      },
      () => {
        this.onError();
      }
    );
    this.CommonService.getcommunityList().subscribe(
      (res: HttpResponse<community[]>) => {
        this.community = res.body;
      },
      () => {
        this.onError();
      }
    );
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
    this.filterProps = this.filterProps ? this.filterProps : [];

    let filquery = new filterQuery();
    filquery.key = 'activeFlag';
    filquery.operation = 'equals';
    filquery.value = 'YES';
    this.filterProps.push(filquery);

    let query = {
      page: pageToLoad - 1,
      size: this.itemsPerPage,
    };
    if (
      this.currentPath == 'profile' &&
      this.rolecode !== userRoles.roleState
    ) {
      this.ProfileService.filter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    } else if (
      this.currentPath == 'profile' &&
      this.rolecode === userRoles.roleState
    ) {
      this.ProfileService.vaildFilter({ ...query }, this.filterProps).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.ProfileService.getProfileListByParentId(this.levelMasterId, {
        ...query,
      }).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  protected onSuccess(
    data: Profile[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;
    // if (navigate) {
    //   this.router.navigate(['/profile'], {
    //     queryParams: {
    //       page: this.page - 1,
    //       size: this.itemsPerPage,
    //     },
    //   });
    // }
    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.notificationService.alertError(
      'An unexpected error occured,please try again',
      ''
    );
    // this.enableFeild = false
  }

  async aadhaarNocheck() {
    this.validateAadhaar();
    const filterData = await this.getfilterdata(
      'aadhaarNo',
      this.profile.aadhaarNo
    );
    if (filterData.length !== 0) {
      this.aadharNoNameError = 'Aadhaar No Already Exists';
    } else {
      this.aadharNoNameError = null;
    }
  }

  async panNoCheck() {
    const filterData = await this.getfilterdata('panNo', this.profile.panNo);
    if (filterData.length !== 0) {
      this.panNoError = 'PanNo Already Exists';
    } else {
      this.panNoError = null;
    }
  }

  async mobileNo() {
    const filterData = await this.getfilterdata(
      'mobileNo',
      this.profile.mobileNo
    );
    if (filterData.length !== 0) {
      this.mobileNoError = 'MobileNo Already Exists';
    } else {
      this.mobileNoError = null;
    }
  }

  async emailCheck() {
    const filterData = await this.getfilterdata(
      'emailId',
      this.profile.emailId
    );
    if (filterData.length !== 0) {
      this.emailIdError = 'Email Already Exists';
    } else {
      this.emailIdError = null;
    }
  }

  getfilterdata(fields, value): Promise<any> {
    return new Promise<any>((resolve) => {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      const filter = [
        {
          key: fields,
          operation: 'equals',
          value: value.toUpperCase(),
        },
        {
          key: 'activeFlag',
          operation: 'equals',
          value: 'YES',
        },
      ];
      if (this.profile.id) {
        filter.push({
          key: 'id',
          operation: 'notEquals',
          value: this.profile.id,
        });
      }
      this.ProfileService.vaildFilter({ ...filterQuery }, filter).subscribe(
        (res: HttpResponse<Profile[]>) => {
          resolve(res.body);
        },
        () => {
          this.onError();
          resolve(null);
        }
      );
    });
  }

  openNew() {
    this.loadValue();
    this.submitted = false;
    this.profileDialog = true;
    this.enableFeild = false;
    this.profile = new AddProfile();
    this.profile.inCharge = false;
  }

  sendOtp() {
    this.sendotp = true;
    if (!this.aadharNoNameError) {
      setTimeout(() => {
        this.otpButtonName = 'Resend OTP';
        this.sendotp = false;
      }, 30000);
      this.ProfileService.sendOtp(this.profile.aadhaarNo).subscribe(
        (res: HttpResponse<any>) => {
          this.txnNo = res.body;
        },
        () => {
          this.onError();
          this.hideDialog();
        }
      );
    }
  }

  async validateOtp(otp) {
    this.spinner.show();
    this.ProfileService.validateOtp(
      this.txnNo,
      this.profile.aadhaarNo,
      this.profile.otp
    ).subscribe(
      async (res: HttpResponse<any>) => {
        this.profile = res.body;
        this.profile.inCharge = false;
        this.enableFeild = true;
        this.otpOpen = true;
        // const dateParts = details.inforamtion['@attributes']['dob'].split('-');
        // const formattedDate = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
        // this.profile.dateOfBirthUi = new Date(formattedDate);
        this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
        this.profile.landmark = this.profile.area;
        // this.profile.name = details.inforamtion['@attributes']['name'];
        // this.genderList.forEach((element) => {
        //   if (
        //     element.name[0] === details.inforamtion['@attributes']['gender']
        //   ) {
        //     this.profile.genderDto = element;
        //   }
        // });
        // this.profile.doorNo = details.address['@attributes']['house'];
        // this.profile.street = details.address['@attributes']['street'];
        // this.profile.landmark = details.address['@attributes']['lm'];
        // this.profile.district = details.address['@attributes']['dist'];
        // this.profile.city = details.address['@attributes']['vtc'];
        // this.profile.pinCode = details.address['@attributes']['pc'];
        // this.profile.state = details.address['@attributes']['state'];
        this.profile.fileNameOne = await this.getImage(
          this.profile.fileNameOne
        );
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
        this.onError();
        this.hideDialog();
      }
    );
  }

  getImage(image: string): Promise<any> {
    return new Promise<any>((resolve) => {
      const binaryString = atob(image);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('file', blob, 'profile');
      this.paymentService
        .uploadImage(formData)
        .subscribe((res: HttpResponse<any>) => {
          resolve(res.body.fileName);
        });
    });
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  hideDialog() {
    this.otpOpen = false;
    this.enableFeild = false;
    this.showMobileError = false;
    this.emailError = false;
    this.emailBranchError = false;
    this.panError = false;
    this.pinError = false;
    this.showBMobile = false;
    this.aadhaarError = false;
    this.profile = new AddProfile();
    this.ngSelectInvalid = false;
    this.profileDialog = false;
    this.resendotp = false;
    this.sendotp = false;
    this.otpButtonName = 'Send OTP';
    this.aadharNoNameError = null;
    this.viewOnly = false;
    this.approveView = false;
  }

  validateMobile() {
    this.showMobileError = !this.validationService.isMobileValid(
      this.profile.mobileNo
    );
    !this.profile.mobileNo ? (this.showMobileError = false) : true;
    return !this.showMobileError && !!this.profile.mobileNo;
  }

  validateEmail() {
    if (this.profile.emailId) {
      this.emailError = !this.validationService.isEmailValid(
        this.profile.emailId
      );
      !this.profile.emailId ? (this.emailError = false) : true;
      return !this.emailError;
    } else {
      return true;
    }
  }

  validatePan() {
    if (this.profile.panNo) {
      this.panError = !this.validationService.isPanValid(this.profile.panNo);
      !this.profile.panNo ? (this.panError = false) : true;
      return !this.panError;
    } else {
      return true;
    }
  }

  validatePin() {
    this.pinError = !this.validationService.isPincodeValid(
      this.profile.pinCode
    );
    !this.profile.pinCode ? (this.pinError = false) : true;
    return !this.pinError && !!this.profile.pinCode;
  }

  validateAotp() {
    this.otpError = !this.validationService.isOtpValid(this.profile.otp);
    !this.profile.otp ? (this.otpError = false) : true;
    // return !this.showMobileError && !!this.profile.otp;
  }

  validateAadhaar() {
    this.aadhaarError = !this.validationService.isAadhaarValid(
      this.profile.aadhaarNo
    );
    !this.profile.aadhaarNo ? (this.aadhaarError = false) : true;

    // return !this.showMobileError && !!this.profile.aadhaarNo;
  }

  saveProfile() {
    const isFormValid =
      this.validateMobile() &&
      this.validateEmail() &&
      this.validatePan() &&
      this.profile.mobileNo &&
      // this.profile.maritalStatusDto &&
      this.profile.dateOfAssumingOffice;

    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    } else {
      this.ngSelectInvalid = false;
      this.profile.categoryReservationDto = null;
      this.profile.communityDto = null;
      this.profile.educationalQualificationDto = null;
      this.profile.maritalStatusDto = null;
      this.profile.termValidUpTo = null;
      // if(this.rolecode != 'DA' && this.rolecode != userRoles.roleBChecker2
      // && this.rolecode != 'VC2'  && this.rolecode != userRoles.roleState){
      //   this.profile.categoryReservationDto=null;
      //   this.profile.communityDto=null;
      // }
      // else {
      //   if(this.profile.categoryReservationDto && this.profile.categoryReservationDto.id==null
      //     && this.profile.categoryReservationDto.name==null)
      //   {
      //     this.profile.categoryReservationDto = null;
      //   }
      //   if(this.profile.communityDto && this.profile.communityDto.id==null
      //     && this.profile.communityDto.name==null)
      //   {
      //     this.profile.communityDto=null;
      //   }
      // }
      this.ProfileService.save(this.profile).subscribe(
        (res: HttpResponse<Profile[]>) => {
          this.profileDialog = false;
          // this.loadPage(this.page);
          this.notificationService.alertSuccess('Saved Successfully', '');
          // this.router.navigate(['/home']);
          if (!this.profile.id) {
            this.navbarService.logout().subscribe(
              (res: HttpResponse<any>) => {
                this.userRoleService.clearuserContext();
                this.localStorageService.clear();
                this.sessionStorageService.clear();
                this.router.navigate(['/login']);
              },
              (err) => {}
            );
          } else {
            this.loadPage(this.page);
          }
        },
        (onError) => {
          if (onError.status == 400) {
            this.notificationService.alertError(onError.error.errorKey, '');
          } else {
            this.notificationService.alertError(
              'An unexpected error occured',
              ''
            );
          }
        }
      );
    }
  }
  onSelectRow($event) {
    if ($event.type === 'select' || $event.type === 'approve') {
      this.ProfileService.getProfile($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          if ($event.type === 'approve') {
            this.viewOnly = true;
            this.approveView = true;
          }
          this.profile = res.body;
          this.profileDialog = true;
          this.enableFeild = true;
          if (this.profile.dateOfBirth) {
            this.profile.dateOfBirthUi = new Date(this.profile.dateOfBirth);
          }
          if (this.profile.termValidUpTo) {
            this.profile.termValidUpTo = new Date(this.profile.termValidUpTo);
          }
          if (this.profile.dateOfAssumingOffice) {
            this.profile.dateOfAssumingOffice = new Date(
              this.profile.dateOfAssumingOffice
            );
          }
          this.loadValue();
        },
        () => {
          this.onError();
        }
      );
    } else {
      this.ProfileService.delete($event.value.id).subscribe(
        (res: HttpResponse<any>) => {
          this.loadPage(this.page);
        },
        () => {
          this.onError();
        }
      );
    }
  }

  approveProfile(id) {
    this.ProfileService.approveProfile(id).subscribe(
      (res: HttpResponse<any>) => {
        this.loadPage(this.page);
        this.profileDialog = false;
      },
      (error) => {
        this.onError();
      }
    );
  }

  rejectProfile(id) {
    this.ProfileService.rejectProfile(id).subscribe(
      (res: HttpResponse<any>) => {
        this.loadPage(this.page);
        this.profileDialog = false;
      },
      () => {
        this.onError();
      }
    );
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.userContextSubscription) {
      this.userContextSubscription.unsubscribe();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
