import { Router ,ActivatedRoute, NavigationStart, NavigationEnd} from '@angular/router';
import { Component, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { NavbarService } from './layout/navbar/navbar.service';
import { HttpResponse } from '@angular/common/http';
import { UserRoleService } from './shared/service/user-role.service';
import { SigninService } from './auth/signin/signin.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ib-sna-developement';
  inactivityTimeout:any;
  currentRoute: string | undefined;
  showHead: boolean | undefined;
  userContextSubscription: Subscription;
  constructor(private router: Router,private location: Location, 
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private navbarService: NavbarService,
    private userRoleService:UserRoleService,
    private loginService: SigninService,) {
    // on route change to '/login', set the variable showHead to false
      router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          if (event['url'] == '/login' || event['url'] == '/' || event['url'].startsWith('/error')
          || event['urlAfterRedirects'].startsWith('/error')
          || event['url'].startsWith('/ForgotPassword') || event['url'].startsWith('/ResetPassword')) {
            this.showHead = false;
          } else {
            this.showHead = true;
          }
        }
      });
    }

    async ngOnInit() {
    const id = this.router.routerState.snapshot.root;
    const token: string | null =
      this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
      if(token){
        this.loginService.refreshToken().subscribe();
        await this.userRoleService.fetchAndSetuserContext();
      }
  }
  async logoutByPromise(): Promise<string> {

    return new Promise<string>(
      (resolve) => {
        this.navbarService.logout().subscribe(
          () => {
            resolve('SUCCESS')
          },
          () => {
            resolve(null);
          }
        );

      }
    );
  }

  // @HostListener("window:mousemove")
  // @HostListener("window:keydown")
  // resetInactivityTimer() {
  //   clearTimeout(this.inactivityTimeout);
  //   this.inactivityTimeout = setTimeout(() => {
  //     this.logout();
  //   }, 600000); // 10mins.
  // }
  // logout(): void {
  //   const token: string | null =
  //   this.localStorageService.retrieve('authenticationToken') ?? this.sessionStorageService.retrieve('authenticationToken');
  //   if(token){
  //     this.navbarService.logout().subscribe(
  //       (res: HttpResponse<any>) => {
  //         this.localStorageService.clear();
  //         this.sessionStorageService.clear();
  //         this.router.navigate([""]);
  //     },
  //     (err) => {}    
  //   );
  //   } else{
  //     this.localStorageService.clear();
  //     this.sessionStorageService.clear();
  //     this.router.navigate([""]);
  //   }
  // }
  //     // this.localStorageService.clear();
  //     // this.sessionStorageService.clear();
  //     // this.router.navigate([""]);
  

  // // @HostListener('window:beforeunload', ['$event'])
  // //  onBeforeUnload(event: BeforeUnloadEvent) {
  // //   if(this.localStorageService.retrieve('refresh')){
  // //     this.localStorageService.clear('refresh');
  // //     return;
  // //   }
  // //   this.logout();
   
  // // }

  // // @HostListener('window:beforeunload', ['$event'])
  // // async onBeforeUnload(event: BeforeUnloadEvent) {
  // //   event.preventDefault();
  // //   if(this.localStorageService.retrieve('refresh')){
  // //     this.localStorageService.clear('refresh');
  // //     return;
  // //   }
  // //   else{
      
  // //     // await this.logoutByPromise();
  // //     this.logout();
  // //     this.localStorageService.clear();
  // //     this.sessionStorageService.clear();
  // //     this.router.navigate([""]);
  // //   }
   
  // // }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event: any) {
  //   event.preventDefault();
  //   this.logout();
  // }

  // @HostListener('contextmenu', ['$event'])
  // onContextMenu(event: MouseEvent) {
  //   event.preventDefault();
  // }
  // @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
  //   e.preventDefault();
  // }

  // @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
  //   e.preventDefault();
  // }

  // @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
  //   e.preventDefault();
  // }

  // ngOnDestroy() {
  //   // Unsubscribe to prevent memory leaks
  //   if (this.userContextSubscription) {
  //     this.userContextSubscription.unsubscribe();
  //   }
  // }
}
