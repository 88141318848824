import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LimitTransListService } from 'src/app/module/limit-translist/limit-translist.service';
import { IALimitAllocationAndAuthorization, IALimitUtilizedFundDto, countReportModel, limitReport } from '../drillDown.model';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { DrillDownReportService } from '../drillDown.service';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DatePipe } from '@angular/common';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchResult } from 'src/app/shared/models/search-result';

@Component({
  selector: 'app-limit-report',
  templateUrl: './limit-report.component.html',
  styleUrls: ['./limit-report.component.scss'],
})
export class LimitReportComponent implements OnInit {
  finYear: any;
  finYearDto: any;
  activeIndex = 0;
  activeIndexBlock = 0;
  activeIndexVillage = 0;
  districtlimitList: limitReport[];
  toDate: Date;
  fromDate: Date;
  itemsPerPage: any = 10;
  currentLevelMasterId: any;
  VoucherDialog: boolean;
  currentpaymentStatus: any;
  districtLevelList: LevelMasterDto[];
  district: any;
  cols: any;
  sign2VoucherDialog: boolean;
  sign3VoucherDialog: boolean;
  currentStatus: string;
  searchLimitList: SearchResult<IALimitAllocationAndAuthorization> = new SearchResult<IALimitAllocationAndAuthorization>();
  searchUtilizedList: SearchResult<IALimitUtilizedFundDto> = new SearchResult<IALimitUtilizedFundDto>();
  UtilizedDialog: boolean;
  dataLevel: string;
  filterLevel: string;
  blocklimitList: limitReport[];
  villagelimitList: limitReport[];

  constructor(
    private limitTransListService: LimitTransListService,
    private drillDownReportService: DrillDownReportService,
    public fontService: FontService,
    private datePipe: DatePipe,
    private levelMasterService: LevelMasterService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    let finYear: string;
    if (new Date().getMonth() + 1 >= 4) {
      finYear = new Date().getFullYear() + '-' + Number(new Date().getFullYear() + 1);
    } else {
      finYear = new Date().getFullYear() - 1 + '-' + new Date().getFullYear();
    }
    console.log('finyear', finYear);
    this.limitTransListService.getFinYear().subscribe(
      (res: HttpResponse<any>) => {
        this.finYear = res.body;
        this.finYear.forEach(element => {
          if (element.name === finYear) {
            this.finYearDto = element;
            this.dataLevel = 'District';
            this.changeFinYear();
          }
        });
      },
      () => {}
    );
    this.onStateLevelChange();
  }

  changeFinYear(id?,filterLevel?) {
    let filterQuery = { finYearId: this.finYearDto.id };
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    if(this.dataLevel === 'Block' || this.dataLevel === 'Village'){
      filterQuery['filterLevel'] = 'District';
      filterQuery['parentId'] = 1; 
    }
    if(this.district){
      filterQuery['levelMasterId'] = this.district?.id; 
    }
    else if(id){
      filterQuery['parentId'] = id;
      filterQuery['filterLevel'] = filterLevel;
    }
    else{
      filterQuery['parentId'] = 1; 
    }
    filterQuery['dataLevel'] = this.dataLevel;
    this.drillDownReportService.getDistrictLimitAmount(filterQuery).subscribe(
      res => {
        if(id){
          if(filterLevel === 'Block' && this.dataLevel === 'Block'){
            this.activeIndexBlock = 1;
            this.blocklimitList = res.body;
          }
          else if(filterLevel === 'Block' && this.dataLevel === 'Village'){
            this.activeIndexVillage = 1;
            this.blocklimitList = res.body;
          }
          else if(filterLevel === 'Village' && this.dataLevel === 'Village'){
            this.activeIndexVillage = 2;
            this.villagelimitList = res.body;
          }
        }
        else{
          this.districtlimitList = res.body;
        }
      },
      error => {}
    );
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  loadDistrictlimit(id: number, pageToLoad?, status?: string) {
    this.VoucherDialog = true;
    this.spinner.show();
    this.currentStatus = status;
    this.currentLevelMasterId = id;
    if(this.currentStatus === 'Allocated') {
      this.cols = [
     
        {
          field: 'levelMasterName',
          header: 'Level Master',
          isSortable: true,
          isFilterable: false,
          width: 10,
          isSelectcolumn: true,
        },
  
        {
          field: 'lgdCode',
          header: 'LGD Code',
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'finYearName',
          header: 'Fin Year',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'implementingAgencyName',
          header: 'Implementing Agency',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'adminAmount',
          header: 'Admin Fund Allocated',
          isSortable: false,
          isFilterable: false,
          width: 13,
          isSelectcolumn: true,
        },
        {
          field: 'programmeAmount',
          header: 'Program Fund Allocated',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
       
        
      ];
    }
    else{
      this.cols = [
     
        {
          field: 'levelMasterName',
          header: 'Level Master',
          isSortable: true,
          isFilterable: false,
          width: 10,
          isSelectcolumn: true,
        },
  
        {
          field: 'lgdCode',
          header: 'LGD Code',
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'finYearName',
          header: 'Fin Year',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
        {
          field: 'implementingAgencyName',
          header: 'Implementing Agency',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
       
        
        {
          field: 'adminAmount',
          header: 'Admin Fund Authorized',
          isSortable: false,
          isFilterable: false,
          width: 13,
          isSelectcolumn: true,
        },
        {
          field: 'programmeAmount',
          header: 'Program Fund Authorized',
          isSortable: true,
          isFilterable: false,
          width: 15,
          isSelectcolumn: true,
        },
       
        
      ];
    }
   
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id };
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    filterQuery['fundType'] = status;
    filterQuery['finYearId'] = this.finYearDto.id;
    this.drillDownReportService.getIALimitAllocationAndAuthorizationDistrictWise(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.searchLimitList.total = Number(res.headers.get('X-Total-Count'));
        this.searchLimitList.items = res.body ?? [];
        this.searchLimitList = { ...this.searchLimitList};
      },
      error => {
        this.spinner.hide();
        this.VoucherDialog = false;
      }
    );
  }

  loadUlimit(id: number, pageToLoad?, status?: string) {
    this.UtilizedDialog = true;
    this.spinner.show();
    this.currentStatus = status;
    this.currentLevelMasterId = id;
    this.cols = [
     
      {
        field: 'levelMasterName',
        header: 'District',
        isSortable: true,
        isFilterable: false,
        width: 10,
        isSelectcolumn: true,
      },

      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'voucherCreatedOn',
        header: 'Voucher Date',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'workId',
        header: 'Work Id',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        isSortable: false,
        isFilterable: false,
        width: 13,
        isSelectcolumn: true,
        type: 'amount',
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'netAmount',
        header: 'Gross Amount',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
        type: 'amount',
      },
      
      {
        field: 'componentMasterName',
        header: 'Component Name',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'transactionStatus',
        header: 'Transaction Status',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      {
        field: 'signStatus',
        header: 'Sign Status',
        isSortable: true,
        isFilterable: false,
        width: 15,
        isSelectcolumn: true,
      },
      
      
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id };
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    filterQuery['finYearId'] = this.finYearDto.id;
    this.drillDownReportService.IaUtilizedFundReportDistrictWise(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.searchUtilizedList.total = Number(res.headers.get('X-Total-Count'));
        this.searchUtilizedList.items = res.body ?? [];
        this.searchUtilizedList = { ...this.searchUtilizedList};
      },
      error => {
        this.spinner.hide();
        this.UtilizedDialog = false;
      }
    );
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    if (this.currentStatus) {
      if(this.currentStatus === 'Utilized'){
        this.loadUlimit(this.currentLevelMasterId, event.page,this.currentStatus);
      }
      else{
        this.loadDistrictlimit(this.currentLevelMasterId, event.page,this.currentStatus);
      }
      
    }

  }

  onTabChange(event: any): void {
    switch (event.index) {
      case 0:
        this.dataLevel = 'District';
        
        break;
      case 1:
        this.dataLevel = 'Block';
        this.filterLevel = 'District';
        break;
      case 2:
        this.dataLevel = 'Village';
        this.filterLevel = 'District';
    }
    this.changeFinYear();
  }
}
