<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">Vendor Status Report</h5>
                </ng-template>
                <ng-template pTemplate="right">

                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <p-toolbar>
        <div class="p-toolbar-group-start">
            <div class="row px-4 g-2">
                <div class="col-sm">
                    <label class="form-control-label" for="scheme">District</label>
                    <ng-select id="scheme" bindLabel="name" [(ngModel)]="district" [items]="districtLevelList" appearance="outline" [closeOnSelect]="true" [clearable]="false" class="form-control">
                    </ng-select>
                </div>
                <div class="col-sm">
                    <label class="form-control-label" for="dateOfBirth">From Date</label><br />
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="fromDate" styleClass="datepicker"></p-calendar>
                </div>

                <div class="col-sm">
                    <label class="form-control-label" for="dateOfBirth">To Date</label><br />
                    <p-calendar dateFormat="dd.mm.yy" [(ngModel)]="toDate" styleClass="datepicker"></p-calendar>
                </div>
                <div class="col-sm">
                    <p></p>
                    <button pButton pRipple (click)="getVoucherStatusByDistrict()" class="p-button-success align-items-end">
                        <span class="button-label">Search</span>
                    </button>
                </div>
                <div class="col-sm">
                    <p></p>
                    <button pButton pRipple (click)="fromDate=null;toDate=null;district=null;getVoucherStatusByDistrict()" class="p-button-danger align-items-end">
                        <span class="button-label">Clear</span>
                    </button>
                </div>
            </div>
            <div class="row">

            </div>
        </div>
    </p-toolbar>

    <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event)">
        <p-tabPanel header="District">
            <div class="row">
                <p-table [value]="districtVendorList" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [ngClass]="fontService.regularClass" id="fy">District Name</th>
                            <th [ngClass]="fontService.regularClass" id="amount">Approved Count</th>
                            <th [ngClass]="fontService.regularClass" id="amount">Pending Count</th>
                            <th [ngClass]="fontService.regularClass" id="Samount">Reject Count</th>
                            <th [ngClass]="fontService.regularClass" id="Samount">Total Count</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-list>
                        <tr>
                            <td>{{list?.levelMasterName}}</td>
                            <td>
                                <p-button (click)="loadDistrictVendor(list?.levelMasterId,1,'Approved')" styleClass="p-button-link">{{list.count1}}</p-button>
                            </td>
                            <td>
                                <p-button (click)="loadDistrictVendor(list?.levelMasterId,1,'Pending')" styleClass="p-button-link">{{list.count2}}</p-button>
                            </td>
                            <td>
                                <p-button (click)="loadDistrictVendor(list?.levelMasterId,1,'Rejected')" styleClass="p-button-link">{{list.count3}}</p-button>
                            </td>
                            <td>{{list?.totalCount}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-tabPanel>
        <p-tabPanel header="Block">
            <p-tabView [(activeIndex)]="activeIndexBlock">
                <p-tabPanel header="District">
                    <div class="row">
                        <p-table [value]="districtVendorList" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">District Name</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Approved Count</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Pending Count</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Reject Count</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Total Count</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td>
                                        <p-button (click)="getVoucherStatusByDistrict(list?.levelMasterId)" styleClass="p-button-link">{{list?.levelMasterName}}</p-button>
                                    </td>

                                    <td>
                                        {{list.count1}}
                                    </td>
                                    <td>
                                        {{list.count2}}
                                    </td>
                                    <td>
                                        {{list.count3}}
                                    </td>
                                    <td>{{list?.totalCount}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Block">
                    <div class="row">
                        <p-table [value]="blockVendorList" [tableStyle]="{ 'min-width': '100%' }" [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">Block Name</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Approved Count</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Pending Count</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Reject Count</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Total Count</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td>{{list?.levelMasterName}}</td>
                                    <td>
                                        <p-button (click)="loadDistrictVendor(list?.levelMasterId,1,'Approved')" styleClass="p-button-link">{{list.count1}}</p-button>
                                    </td>
                                    <td>
                                        <p-button (click)="loadDistrictVendor(list?.levelMasterId,1,'Pending')" styleClass="p-button-link">{{list.count2}}</p-button>
                                    </td>
                                    <td>
                                        <p-button (click)="loadDistrictVendor(list?.levelMasterId,1,'Rejected')" styleClass="p-button-link">{{list.count3}}</p-button>
                                    </td>
                                    <td>{{list?.totalCount}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </p-tabPanel>
    </p-tabView>

    <p-dialog [(visible)]="VoucherDialog" [style]="{ width: '1000px', height: '3000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <custom-filter-table [columnsValues]="cols" [filterSearchResult]="VendorList" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" [paginator]="true"></custom-filter-table>
    </p-dialog>



</div>