<div class="px-4 mt-4 pt-4">
    <div class="row g-3 mb-3">
        <div class="col-lg-2 col-md-4 col-sm-3 first-container">
            <div class="row mb-2">
                <p-card [style]="{backgroundColor: '#e8c7f2'}" (click)="activeIndex = 0;onCardTabChange()" styleClass="card-hover">
                    <p class="size" [ngClass]="fontService.regularClass">Budget Allotment
                        <br><span style="font-size: 16px;"> {{formatCash(this.dashboardCardDetails?.budgetAmount)}} Crore</span></p>
                </p-card>
            </div>
            <div class="row mb-2">
                <p-card (click)="activeIndex = 1;onCardTabChange()" [style]="{backgroundColor: '#a8e3ff'}" styleClass="card-hover">
                    <p class="size" [ngClass]="fontService.regularClass">Funds Recieved
                        <br><span style="font-size: 16px;"> {{formatCash(this.dashboardCardDetails?.fundReceivedAmount)}} Crore</span></p>
                </p-card>
            </div>
            <div class="row mb-2">
                <p-card (click)="activeIndex = 2;onCardTabChange()" [style]="{ backgroundColor: '#7DD8D0'}" styleClass="card-hover">
                    <p class="size" [ngClass]="fontService.regularClass">Work Details
                        <br><span style="font-size: 16px;"> {{this.dashboardCardDetails?.workCount}} No's</span></p>
                </p-card>
            </div> <div class="row mb-2">
                <p-card (click)="activeIndex = 3;onCardTabChange()" [style]="{ backgroundColor: '#bcbfeb'}" styleClass="card-hover">
                    <p class="size" [ngClass]="fontService.regularClass">Expenditure
                        <br><span style="font-size: 16px;"> {{formatCash(this.dashboardCardDetails?.expenditureAmount)}} Crore</span></p>
                </p-card>
            </div> <div class="row mb-2">
                <p-card (click)="activeIndex = 4;onCardTabChange()" [style]="{ backgroundColor: '#bcbfeb'}" styleClass="card-hover">
                    <p class="size" [ngClass]="fontService.regularClass">Balance
                        <br><span style="font-size: 16px;"> {{formatCash(this.dashboardCardDetails?.balanceAmount)}} Crore</span></p>
                </p-card>
            </div>
           
        </div>
        <div class="col-lg-10 col-md-8 col-sm-9 first-container">
            <p-tabView class="dashboardCard" [(activeIndex)]="activeIndex">
                <p-tabPanel header="Index0">
                    <h5>Allotment</h5>
                    <p-divider type="dashed"></p-divider>
                    <p-table [value]="funds" [tableStyle]="{ 'min-width': '100%' }" 
                    [resizableColumns]="true" responsiveLayout="scroll">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [ngClass]="fontService.regularClass" id="fy">Financial Year</th>
                                <th [ngClass]="fontService.regularClass" id="allotment">Allotment(Rs. in CR)</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-fund>
                            <tr>
                                <td>{{ fund?.finYear?.name}}</td>
                                <td>
                                    <span>{{ formatCash(fund?.budgetAmount)}} Crore</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="Index1">
                    <h5>Funds Recieved</h5>
                    <p-divider type="dashed"></p-divider>
                    <p-tabView class="showHeader" [(activeIndex)]="fundLimitActiveIndex" (onChange)="onfundLimitTabChange($event)">
                        <p-tabPanel header="Fin Year">
                        <p-table [value]="fundTransactions" [tableStyle]="{ 'min-width': '100%' }" 
                        [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">Financial Year</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Amount(Rs. in CR)</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td><p-button [label]="list?.finYearName" (click)="loadfundLimitDistrict(list?.finYearId)" styleClass="p-button-link"></p-button></td>
                                    <td>{{ formatCash(list?.totalAmount)}} Crore</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="District">
                            <div *ngIf="!limitFundByYear">Choose FinYear to load district data</div>
                            <div *ngIf="limitFundByYear" class="col-12 px-4">
                            <p-table [value]="limitFundByYear" [tableStyle]="{ 'min-width': '100%' }" 
                            [resizableColumns]="true" responsiveLayout="scroll" 
                            [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="total">Total Fund Recieved</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Programme Amount(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Admin Amount(Rs. in lakh)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td><p-button [label]="list?.levelMasterName" (click)="loadfundLimitDistrict(list?.levelMasterId,'Block')" styleClass="p-button-link"></p-button></td>
                                        <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            </div>
                            </p-tabPanel>
                            <p-tabPanel header="Block">
                                <div *ngIf="!limitFundBlock">Choose FinYear to load block data</div>
                                <div *ngIf="limitFundBlock" class="col-12 px-4">
                                <p-table [value]="limitFundBlock" [tableStyle]="{ 'min-width': '100%' }" 
                                [resizableColumns]="true" responsiveLayout="scroll" 
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th [ngClass]="fontService.regularClass" id="fy">Block</th>
                                            <th [ngClass]="fontService.regularClass" id="total">Total Fund Recieved</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Programme Amount(Rs. in lakh)</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Admin Amount(Rs. in lakh)</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-list>
                                        <tr>
                                            <td>{{ list?.levelMasterName}} </td>
                                            <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                            <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                            <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                </div>
                                </p-tabPanel>
                    </p-tabView>
                </p-tabPanel>
                <p-tabPanel header="Index2">
                    <h5>Work Details</h5>
                    <p-divider type="dashed"></p-divider>
                    <p-tabView class="showHeader" [(activeIndex)]="workActiveIndex" (onChange)="onWorkTabChange($event)">
                        <p-tabPanel header="Fin Year">
                        <p-table [value]="projectSummary" [tableStyle]="{ 'min-width': '100%' }" 
                        [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">Financial Year</th>
                                    <th [ngClass]="fontService.regularClass" id="fy">Total Cost(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Utilised Cost(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Balance Cost(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="fy">Total Work</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Completed</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Not completed</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td><p-button [label]="list?.finYearName" (click)="loadWorkDistrict(list?.finYearId)" styleClass="p-button-link"></p-button></td>
                                    <td>{{ formatCash(list?.totalCost)}} Crore</td>
                                    <td>{{ formatCash(list?.utilizedCost)}} Crore</td>
                                    <td>{{ formatCash(list?.balanceCost)}} Crore</td>
                                    <td>{{ list?.totalWork}}</td>
                                    <td>{{ list?.completed}}</td>
                                    <td>{{ list?.notCompleted}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="District">
                            <div *ngIf="!projectSummaryByLevel">Choose FinYear to load district data</div>
                            <div *ngIf="projectSummaryByLevel" class="col-12 px-4">
                            <p-table [value]="projectSummaryByLevel" [tableStyle]="{ 'min-width': '100%' }" 
                            [resizableColumns]="true" responsiveLayout="scroll"
                            [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="fy">Total Cost(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Utilised Cost(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Balance Cost(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="fy">Total Work</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Completed</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Not completed</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                    <td><p-button [label]="list?.levelmasterName" (click)="loadWorkDistrict(list?.levelMasterId,'Block')" styleClass="p-button-link"></p-button></td>
                                        <td>{{ formatCash(list?.totalCost)}} Crore</td>
                                    <td>{{ formatCash(list?.utilizedCost)}} Crore</td>
                                    <td>{{ formatCash(list?.balanceCost)}} Crore</td>
                                    <td>{{ list?.totalWork}}</td>
                                    <td>{{ list?.completed}}</td>
                                    <td>{{ list?.notCompleted}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            </div>
                            </p-tabPanel>
                            <p-tabPanel header="Block">
                                <div *ngIf="!projectSummaryByBlock">Choose FinYear to load block data</div>
                                <div *ngIf="projectSummaryByBlock" class="col-12 px-4">
                                <p-table [value]="projectSummaryByBlock" [tableStyle]="{ 'min-width': '100%' }" 
                                [resizableColumns]="true" responsiveLayout="scroll"
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th [ngClass]="fontService.regularClass" id="fy">Block</th>
                                            <th [ngClass]="fontService.regularClass" id="fy">Total Cost(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="Samount">Utilised Cost(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Balance Cost(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="fy">Total Work</th>
                                        <th [ngClass]="fontService.regularClass" id="Samount">Completed</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Not completed</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-list>
                                        <tr>
                                            <td>{{ list?.levelmasterName}} </td>
                                            <td>{{ formatCash(list?.totalCost)}} Crore</td>
                                        <td>{{ formatCash(list?.utilizedCost)}} Crore</td>
                                        <td>{{ formatCash(list?.balanceCost)}} Crore</td>
                                        <td>{{ list?.totalWork}}</td>
                                        <td>{{ list?.completed}}</td>
                                        <td>{{ list?.notCompleted}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                </div>
                                </p-tabPanel>
                    </p-tabView> 
                </p-tabPanel>
                <p-tabPanel header="Index3">
                    <h5>Expenditure</h5>
                    <p-divider type="dashed"></p-divider>
                    <p-tabView class="showHeader" [(activeIndex)]="exprLimitActiveIndex" (onChange)="onExpenditureTabChange($event)">
                        <p-tabPanel header="Fin Year">
                        <p-table [value]="fundExpenditure" [tableStyle]="{ 'min-width': '100%' }" 
                        [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">Financial Year</th>
                                    <th [ngClass]="fontService.regularClass" id="fy">Fund Recieved(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Programme Fund Expenditure(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Admin Fund Expenditure(Rs. in CR)</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td><p-button [label]="list?.finYearName" (click)="loadExpenditureDistrict(list?.finYearId)" styleClass="p-button-link"></p-button></td>
                                    <td>{{ formatCash(list?.totalAmount)}} Crore</td>
                                    <td>{{ formatCash(list?.programAmount)}} Crore</td>
                                    <td>{{ formatCash(list?.adminAmount)}} Crore</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="District">
                            <div *ngIf="!expenditureByYear">Choose FinYear to load district data</div>
                            <div *ngIf="expenditureByYear" class="col-12 px-4">
                            <p-table [value]="expenditureByYear" [tableStyle]="{ 'min-width': '100%' }" 
                            [resizableColumns]="true" responsiveLayout="scroll"
                            [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Programme Fund Expenditure(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Admin Fund Expenditure(Rs. in lakh)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td><p-button [label]="list?.levelMasterName" (click)="loadExpenditureDistrict(list?.levelMasterId,'Block')" styleClass="p-button-link"></p-button></td>
                                        <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            </div>
                            </p-tabPanel>
                            <p-tabPanel header="Block">
                                <div *ngIf="!expenditureBlock">Choose FinYear to load block data</div>
                                <div *ngIf="expenditureBlock" class="col-12 px-4">
                                <p-table [value]="expenditureBlock" [tableStyle]="{ 'min-width': '100%' }" 
                                [resizableColumns]="true" responsiveLayout="scroll"
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th [ngClass]="fontService.regularClass" id="fy">Block</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in lakh)</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Programme Fund Expenditure(Rs. in lakh)</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Admin Fund Expenditure(Rs. in lakh)</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-list>
                                        <tr>
                                            <td>{{ list?.levelMasterName}}</td>
                                            <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                            <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                            <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                </div>
                                </p-tabPanel>
                    </p-tabView>    
                </p-tabPanel>
                <p-tabPanel header="Index4">
                    <h5>Balance</h5>
                    <p-divider type="dashed"></p-divider>
                    <p-tabView class="showHeader" [(activeIndex)]="balLimitActiveIndex" (onChange)="onBalanceTabChange($event)">
                        <p-tabPanel header="Fin Year">
                        <p-table [value]="fundBalance" [tableStyle]="{ 'min-width': '100%' }" 
                        [resizableColumns]="true" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th [ngClass]="fontService.regularClass" id="fy">Financial Year</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in CR)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Expenditure(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="Samount">Balance Programme Fund(Rs. in CR)</th>
                                    <th [ngClass]="fontService.regularClass" id="amount">Balance Admin Fund (Rs. in CR)</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-list>
                                <tr>
                                    <td><p-button [label]="list?.finYearName" (click)="loadBalanceDistrict(list?.finYearId)" styleClass="p-button-link"></p-button></td>
                                    <td>{{ formatCash(list?.totalAmount)}} Crore</td>
                                        <td>{{ formatCash(list?.amount)}} Crore</td>
                                        <td>{{ formatCash(list?.programAmount)}} Crore</td>
                                        <td>{{ formatCash(list?.adminAmount)}} Crore</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="District">
                            <div *ngIf="!balanceByYear">Choose FinYear to load district data</div>
                            <div *ngIf="balanceByYear" class="col-12 px-4">
                            <p-table [value]="balanceByYear" [tableStyle]="{ 'min-width': '100%' }" 
                            [resizableColumns]="true" responsiveLayout="scroll" 
                            [paginator]="true" [rows]="10" [showCurrentPageReport]="true" 
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.regularClass" id="fy">District</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Total Expenditure(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Balance Programme Fund(Rs. in lakh)</th>
                                        <th [ngClass]="fontService.regularClass" id="amount">Balance Admin Fund(Rs. in lakh)</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list>
                                    <tr>
                                        <td><p-button [label]="list?.levelMasterName" (click)="loadBalanceDistrict(list?.levelMasterId,'Block')" styleClass="p-button-link"></p-button></td>
                                        <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.amount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                        <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            </div>
                            </p-tabPanel>
                            <p-tabPanel header="Block">
                                <div *ngIf="!balanceBlock">Choose FinYear to load block data</div>
                                <div *ngIf="balanceBlock" class="col-12 px-4">
                                <p-table [value]="balanceBlock" [tableStyle]="{ 'min-width': '100%' }" 
                                [resizableColumns]="true" responsiveLayout="scroll" 
                                [paginator]="true" [rows]="10" [showCurrentPageReport]="true" 
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th [ngClass]="fontService.regularClass" id="fy">Block</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Total Fund Recieved(Rs. in lakh)</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Total Expenditure(Rs. in lakh)</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Balance Programme Fund(Rs. in lakh)</th>
                                            <th [ngClass]="fontService.regularClass" id="amount">Balance Admin Fund(Rs. in lakh)</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-list>
                                        <tr>
                                            <td>{{ list?.levelMasterName}} </td>
                                            <td>{{ formatCashLakh(list?.totalAmount)}} Lakh</td>
                                            <td>{{ formatCashLakh(list?.amount)}} Lakh</td>
                                            <td>{{ formatCashLakh(list?.programmeAmount)}} Lakh</td>
                                            <td>{{ formatCashLakh(list?.adminAmount)}} Lakh</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                </div>
                                </p-tabPanel>
                    </p-tabView>  
                </p-tabPanel>
            </p-tabView>
        </div>
        
    </div>


