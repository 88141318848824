import { ComponentDto } from './../component-type/component-type';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { MasterComponent } from './major-component';
import { ComponentMasterDto } from 'src/app/payments/payment-voucher/payment-voucher.model';
export type EntityResponseType = HttpResponse<MasterComponent>;
export type EntityArrayResponseType = HttpResponse<MasterComponent[]>;

@Injectable()
export class MajorComponentService {
    
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getViewComponentMasterByFilter');
    protected getMajorComponentMasterUrl = environment.SERVER_API_URL+('api/getViewMajorComponentMasterByFilter')
    protected getMinorComponentMasterUrl = environment.SERVER_API_URL+('api/getViewMinorComponentMasterByFilter')  
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveComponentMaster');
    // protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deleteProfile');
    constructor(protected http: HttpClient) {}
    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<MasterComponent[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }
    majorComponentfilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<MasterComponent[]>(this.getMajorComponentMasterUrl,filter, { params:options, observe: 'response' })
    }
    minorComponentfilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<MasterComponent[]>(this.getMinorComponentMasterUrl,filter, { params:options, observe: 'response' })
    }
    save(componentMaster?:ComponentMasterDto): Observable<any> {
        return this.http
        .post<any>(this.resourceSaveUrl,componentMaster, {observe: 'response' })
    }
    // delete(id: number): Observable<HttpResponse<{}>> {
    //   return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
    // }

}