<style>
    .footer-logo {
    text-align: center;
    margin-top: 100px;
}
        .header {
            background-image: url(https://preview.keenthemes.com/metronic8/demo54/assets/media/auth/bg9-dark.jpg);
            background-repeat: no-repeat;
            background-position: center;
            background-color: none;
        }
        
        text.highcharts-credits {
            display: none;
        }
        .content {
    padding: 0 0 0 0;
    margin-top: 0px;
}
        .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
            background-color: transparent;
           box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
        .tab-pane.active {
    animation: slide-down .5s ease-out;
}
.hover-eff .text-dark{
    font-size: 2rem!important;
}
        .hover-eff .text-dark,.hover-eff .text-dark-400 {
            color: #000 !important;
        }
        .table:not(.table-bordered) td:first-child, .table:not(.table-bordered) th:first-child, .table:not(.table-bordered) tr:first-child {
            padding-left: 10px;
        }
        .hover-eff:hover {
            background-color: #063356 !important;
        }
        .hover-eff:hover .text-dark{
            color: #fff !important;
        }
        .hover-eff:hover span{
            color: #fff !important;
        }

@keyframes slide-down {
    0% { opacity: 0; transform: translateY(10%); }
    100% { opacity: 1; transform: translateY(0); }
}
    </style>
    <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
        <!--begin::Wrapper-->
        <div class="d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <!--begin::Container-->
                <div class="container-fluid" id="kt_content_container">
                    <div class="row g-5 g-xl-8 mt-5">
                        <!--begin::Col-->
                        <div class="col-xl-12">
                            <!--begin::Misc Widget 1-->
                            <div class="row g-5 g-xl-8 justify-content-center nav nav-pills mb-5 row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-5 row-cols-xl-5"  id="pills-tab" role="tablist">
                                <!--begin::Col-->
                                <div class="col">
                                    <!--begin::Card-->
                                    <a href="#" class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                        <div class="card card-flush hover-eff" style="background-color: #e8c7f2">
                                            <div class="card-header">
                                                <div class="card-title d-flex flex-column">   
                                                    <div class="d-flex align-items-center">
                                                        <span class="fs-4 fw-semibold text-dark-400 me-1 align-self-start">₹</span>

                                                        <span class="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">10,00,000</span>
                                                    </div>

                                                    <span class="text-dark-400 pt-1 fw-semibold fs-6">Budget Allotment</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <!--end::Col-->
                                <!--begin::Col-->
                                
                                <div class="col">
                                    <a href="#" class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                        <div class="card card-flush hover-eff" style="background-color: #a8e3ff">
                                            <div class="card-header">
                                                <div class="card-title d-flex flex-column">   
                                                    <div class="d-flex align-items-center">
                                                        <span class="fs-4 fw-semibold text-dark-400 me-1 align-self-start">₹</span>
                                                        <span class="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">2,00,000</span>
                                                    </div>

                                                    <span class="text-dark-400 pt-1 fw-semibold fs-6">Sanctioned Issued</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                
                                <div class="col">
                                    <a href="#" class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
                                        <div class="card card-flush hover-eff" style="background-color: #7DD8D0">
                                            <div class="card-header">
                                                <div class="card-title d-flex flex-column">   
                                                    <div class="d-flex align-items-center">
                                                        <span class="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">255</span>
                                                    </div>

                                                    <span class="text-dark-400 pt-1 fw-semibold fs-6">No of Works Taken</span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                
                                
                                <div class="col">
                                    <a href="#" class="nav-link" id="pills-project-tab" data-bs-toggle="pill" data-bs-target="#pills-project" type="button" role="tab" aria-controls="pills-project" aria-selected="false">
                                    <div class="card card-flush hover-eff" style="background-color: #FFD68F">
                                    <div class="card-header">
                                        <div class="card-title d-flex flex-column">   
                                            <div class="d-flex align-items-center">
                                                <span class="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">147</span>
                                            </div>

                                            <span class="text-dark-400 pt-1 fw-semibold fs-6">No of Works Completed</span>
                                        </div>
                                    </div>
                                </div>
                                    </a>
                                </div>
                                <div class="col">
                                    <a href="#" class="nav-link" id="pills-balance-tab" data-bs-toggle="pill" data-bs-target="#pills-balance" type="button" role="tab" aria-controls="pills-balance" aria-selected="false">
                                    <div class="card card-flush hover-eff" style="background-color: #bcbfeb">
                                    <div class="card-header">
                                        <div class="card-title d-flex flex-column">   
                                            <div class="d-flex align-items-center">
                                                <span class="fs-4 fw-semibold text-dark-400 me-1 align-self-start">₹</span>
                                                <span class="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">5,00,000</span>
                                            </div>

                                            <span class="text-dark-400 pt-1 fw-semibold fs-6">Expenditure</span>
                                        </div>
                                    </div>
                                </div>
                                    </a>
                                </div>
                            </div>
                            
                                <div class="tab-content" id="pills-tabContent">
                                  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                      <div class="row justify-content-center">
                                      <div class="col-xl-8 mb-5 mb-xl-5 mt-0">
                                            <div class="card card-flush h-xl-100">
                                                <div class="card-header pt-7">
                                                    <h3 class="card-title align-items-start flex-column">
                                                        <span class="card-label fw-bold text-dark-800">Allotment</span>
                                                    </h3>
                                                </div>
                                                <div class="separator separator-dashed my-3"></div>
                                                <div class="card-body pt-2">
                                                    <div class="table-responsive">
                                                    <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table" style="margin: 0 auto">
                                                        <thead class="table-dark">
                                                            <tr class="text-start text-dark-400 fw-bold fs-7 text-uppercase gs-0">
                                                                <th class="min-w-100px">Financial Year</th>
                                                                <th class="min-w-100px">Allotment</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-bold text-dark-600">
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2024-25</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹70,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2023-24</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹64,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2022-23</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹52,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2021-22</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹40,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2020-21</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹30,00,000</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                      <div class="row justify-content-center">
                                      <div class="col-xl-6 mb-5 mb-xl-5 mt-0">
                                            <div class="card card-flush h-xl-100">
                                                <div class="card-header pt-7">
                                                    <h3 class="card-title align-items-start flex-column">
                                                        <span class="card-label fw-bold text-dark-800">Sanctioned Issued</span>
                                                    </h3>
                                                </div>
                                                <div class="separator separator-dashed my-3"></div>
                                                <div class="card-body pt-2">
                                                    <div class="table-responsive">
                                                    <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table" style="margin: 0 auto">
                                                        <thead class="table-dark">
                                                            <tr class="text-start text-dark-400 fw-bold fs-7 text-uppercase gs-0">
                                                                <th class="min-w-100px">Financial Year</th>
                                                                <th class="min-w-100px">Amount </th>
                                                                <th class="min-w-100px">Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-bold text-dark-600">
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2024-25</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹70,00,000</span>
                                                                </td>
                                                                <td>
                                                                    22-08-2024
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2023-24</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹64,00,000</span>
                                                                </td>
                                                                <td>
                                                                    05-05-2024
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2022-23</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹52,00,000</span>
                                                                </td>
                                                                <td>
                                                                    14-02-2023
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2021-22</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹40,00,000</span>
                                                                </td>
                                                                <td>
                                                                    05-04-2022
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2020-21</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹30,00,000</span>
                                                                </td>
                                                                <td>
                                                                    24-08-2021
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                      <div class="row justify-content-center">
                                      <div class="col-xl-6 mb-5 mb-xl-5 mt-0">
                                            <div class="card card-flush h-xl-100">
                                                <div class="card-header pt-7">
                                                    <h3 class="card-title align-items-start flex-column">
                                                        <span class="card-label fw-bold text-dark-800">No of Works Taken</span>
                                                    </h3>
                                                </div>
                                                <div class="separator separator-dashed my-3"></div>
                                                <div class="card-body pt-2">
                                                    <div class="table-responsive">
                                                    <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table" style="margin: 0 auto">
                                                        <thead class="table-dark">
                                                            <tr class="text-start text-dark-400 fw-bold fs-7 text-uppercase gs-0">
                                                                <th class="min-w-100px">District</th>
                                                                <th class="min-w-100px">No of Work </th>
                                                                <th class="min-w-100px">AS Amount</th>
                                                                <th class="min-w-100px">Expenditure</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-bold text-dark-600">
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal">Ariyalur</a>
                                                                </td>
                                                                <td>
                                                                    15
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹9,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹5,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Erode</a>
                                                                </td>
                                                                <td>
                                                                    10
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹14,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹4,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Cuddalore</a>
                                                                </td>
                                                                <td>
                                                                    22
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹18,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹12,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Karur</a>
                                                                </td>
                                                                <td>
                                                                    13
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹26,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹18,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Kallakurichi</a>
                                                                </td>
                                                                <td>
                                                                    25
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹16,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹6,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Kancheepuram</a>
                                                                </td>
                                                                <td>
                                                                    21
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹20,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹15,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Krishnagiri</a>
                                                                </td>
                                                                <td>
                                                                    16
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹34,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹20,00,000</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-project" role="tabpanel" aria-labelledby="pills-project-tab">
                                      <div class="row justify-content-center">
                                      <div class="col-xl-6 mb-5 mb-xl-5 mt-0">
                                            <div class="card card-flush h-xl-100">
                                                <div class="card-header pt-7">
                                                    <h3 class="card-title align-items-start flex-column">
                                                        <span class="card-label fw-bold text-dark-800">No of Work Completed</span>
                                                    </h3>
                                                </div>
                                                <div class="separator separator-dashed my-3"></div>
                                                <div class="card-body pt-2">
                                                    <div class="table-responsive">
                                                    <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table" style="margin: 0 auto">
                                                        <thead class="table-dark">
                                                            <tr class="text-start text-dark-400 fw-bold fs-7 text-uppercase gs-0">
                                                                <th class="min-w-100px">District</th>
                                                                <th class="min-w-100px">No of Work </th>
                                                                <th class="min-w-100px">AS Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-bold text-dark-600">
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary"  data-bs-toggle="modal" data-bs-target="#exampleModal">Ariyalur</a>
                                                                </td>
                                                                <td>
                                                                    15
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹9,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Erode</a>
                                                                </td>
                                                                <td>
                                                                    10
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹14,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Cuddalore</a>
                                                                </td>
                                                                <td>
                                                                    22
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹18,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Karur</a>
                                                                </td>
                                                                <td>
                                                                    13
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹26,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Kallakurichi</a>
                                                                </td>
                                                                <td>
                                                                    25
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹16,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Kancheepuram</a>
                                                                </td>
                                                                <td>
                                                                    21
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹20,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Krishnagiri</a>
                                                                </td>
                                                                <td>
                                                                    16
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹34,00,000</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-balance" role="tabpanel" aria-labelledby="pills-balance-tab">
                                      <div class="row justify-content-center">
                                      <div class="col-xl-6 mb-5 mb-xl-5 mt-0">
                                            <div class="card card-flush h-xl-100">
                                                <div class="card-header pt-7">
                                                    <h3 class="card-title align-items-start flex-column">
                                                        <span class="card-label fw-bold text-dark-800">Expenditure</span>
                                                    </h3>
                                                </div>
                                                <div class="separator separator-dashed my-3"></div>
                                                <div class="card-body pt-2">
                                                    <div class="table-responsive">
                                                    <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table" style="margin: 0 auto">
                                                        <thead class="table-dark">
                                                            <tr class="text-start text-dark-400 fw-bold fs-7 text-uppercase gs-0">
                                                                <th class="min-w-100px">Financial Year</th>
                                                                <th class="min-w-100px">Budget Allotment</th>
                                                                <th class="min-w-100px">Expenditure</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-bold text-dark-600">
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2024-25</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹70,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹50,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2023-24</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹64,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹34,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2022-23</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹52,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹25,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2021-22</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹40,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹20,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">2020-21</a>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹30,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹22,00,000</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            
                        </div>
                        
                    </div>

                    <div class=" card row g-3 mb-3 mt-4">
                        <div class="col-12">
                            <dx-chart id="chart" title="District Wise Funds Allocation Report" [dataSource]="fundDS" palette="Material">
                                <dxi-series valueField="allocatedAmount" name="Allocated"></dxi-series>
                                <dxi-series valueField="utilizedAmount" name="Utilized"></dxi-series>
                                <dxi-value-axis [showZero]="true">
                                    <dxo-title text="Amount"></dxo-title>
                                </dxi-value-axis>
                                <dxo-argument-axis>
                                    <dxo-label overlappingBehavior="rotate" rotationAngle=270></dxo-label>
                                </dxo-argument-axis>
                                <dxo-common-series-settings argumentField="levelMasterName" type="bar">
                                    <!-- <dxo-label
                    [visible]="true"
                    [showForZeroValues]="true"
                    >
                    </dxo-label> -->
                                </dxo-common-series-settings>
                                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right">
                                </dxo-legend>
                                <dxo-tooltip [enabled]="true" location="edge" [customizeTooltip]="customizeTooltipdemo2">
                                </dxo-tooltip>
                            </dx-chart>
                        </div>
                    </div>
                    <!--end::Row-->
                </div>
                <!--end::Container-->
            </div>
            <!--end::Content-->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ariyalur Block No of Works</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div class="card card-flush h-xl-100">
                                                <div class="card-body pt-2">
                                                    <div class="table-responsive">
                                                    <table class="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table" style="margin: 0 auto">
                                                        <thead class="table-dark">
                                                            <tr class="text-start text-dark-400 fw-bold fs-7 text-uppercase gs-0">
                                                                <th class="min-w-100px">District</th>
                                                                <th class="min-w-100px">No of Work </th>
                                                                <th class="min-w-100px">AS Amount</th>
                                                                <th class="min-w-100px">Expenditure</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="fw-bold text-dark-600">
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Thirumanur </a>
                                                                </td>
                                                                <td>
                                                                    15
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹2,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹1,00,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Sendurai </a>
                                                                </td>
                                                                <td>
                                                                    10
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹2,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹1,50,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Jayankondam </a>
                                                                </td>
                                                                <td>
                                                                    22
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹2,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹1,20,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">T.Palur</a>
                                                                </td>
                                                                <td>
                                                                    13
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹1,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹30,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Andimadam</a>
                                                                </td>
                                                                <td>
                                                                    25
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹1,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹50,000</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <a href="#" class="text-dark-800 text-hover-primary">Ariyalur</a>
                                                                </td>
                                                                <td>
                                                                    21
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-primary">₹1,00,000</span>
                                                                </td>
                                                                <td>
                                                                    <span class="badge py-3 px-4 fs-7 badge-light-danger">₹15,000</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                  </div>
				  
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            <!--begin::Footer-->
            <!--end::Footer-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
<script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js" integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.min.js" integrity="sha384-Rx+T1VzGupg4BHQYs2gCW9It+akI2MM/mndMCy36UVfodzcJcF0GGLxZIzObiEfa" crossorigin="anonymous"></script>
