export class ElectedRepresentativeDto {
    id: number;
    levelId: number;
    levelMasterId: number;
    name: string;
    post: string;
    wardNo: number;
    fixedTravellingAllowance: number;
    sittingChargesAllowance: number;
    honorariumAllowance: number;
    others: number;
    createdOn: Date;
    updatedOn: Date;
    activeFlag: string;
    bankAccName: string;
    bankName: string;
    bankBranch: string;
    ifscCode: string;
    accountNumber: string;
    micr: string;
}

export class ElectedRepresentative {
    id: number;
    levelId: number;
    levelMasterId: number;
    name: string;
    post: string;
    wardNo: number;
    fixedTravellingAllowance: number;
    sittingChargesAllowance: number;
    honorariumAllowance: number;
    others: number;
    createdOn: Date;
    updatedOn?: Date;
    activeFlag: string;
    bankAccName: string;
    bankName: string;
    bankBranch: string;
    ifscCode: string;
    accountNumber: string;
    micr?: string;
}

export class ValidationResponse{
    accountNo:string;
    accountName:string;
    bankTxnStatus:string;
    ifsc:string;
    bankResponse:string;
  }