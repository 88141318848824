import { filterQuery } from './../common.model';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterMatchMode, LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { ASC, DESC, ITEMS_PER_PAGE } from 'src/app/config/pagination.constants';
import { SearchResult } from '../models/search-result';
import { TableColumn } from '../models/table-column';
import * as dayjs from 'dayjs';
import enIN from '@angular/common/locales/en-IN';
import {registerLocaleData } from '@angular/common';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { userRoles } from '../models/user-role-code';
@Component({
  selector: 'custom-filter-table',
  templateUrl: './custom-filter-table.component.html',
  styleUrls: ['./custom-filter-table.component.scss'],
})
export class CustomFilterTableComponent implements OnInit {
  matchModeOptions = [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Equals', value: FilterMatchMode.EQUALS },
  ];
  @ViewChild('reportTable', { static: true }) reportTable!: any;

  @Output() emitRow = new EventEmitter();
  @Output() searchParam = new EventEmitter();
  @Output() showTable = new EventEmitter();
  @Output() redirectTo = new EventEmitter();
  @Input() disableDeleteIcon = true;
  @Input() disableEditIcon = true;
  @Input() disablePaymentEditIcon = true;
  @Input() disableVendorEdit = true;
  @Input() disableVendorDelete = true;
  @Input() lazyLoadOnInit = true;
  @Input() paginator = true;
  @Input() showProfileButton = false;
  @Input() showRedirectButton = false;
  @Input() showTableButton = false;
  @Input() enableViewIcon = false;
  @Input() enableApproveButton = false;
  @Input() enablePApproveButton = false;
  @Input() enableActivateButton = false;
  @Input() enableDeactivateButton = false;
  @Input() enableCheckTransaction = false;
  @Input() DownloadPdf = false;
  @Input() currentRole = "";
  @Input() enableuserUnlock = false;
  @Input() set columnsValues(frozenValue: any[]) {
    this.columnOptions = [];
    this.selectedColumns = [];
    frozenValue?.forEach((frozen) => {
      delete frozen.value;
      this.columnOptions.push(frozen);
      if (frozen.isSelectcolumn) {
        this.selectedColumns.push(frozen);
      }
    });
  }
  columnOptions: TableColumn[] = [];
  selectedColumns: TableColumn[] = [];
  listItems: Subject<any[]> = new Subject<any[]>();
  totalCount = 0;
  first = 0;
  sortOrder = 1;
  sortField = 'id';
  userRoles=userRoles;
  @Input() itemsPerPage = ITEMS_PER_PAGE;
  @Input() fileName ;
  @Input() levelMasterId;
  @Input() set filterSearchResult(filterSearchResult: SearchResult<any>) {
    if (!filterSearchResult) {
      return;
    }
    this.listItems.next(filterSearchResult.items);
    this.totalCount = filterSearchResult.total;
    this.totalCount = filterSearchResult.total;
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    private paymentService: PaymentService,
    protected notificationService: NotificationService,
  ) {
    registerLocaleData(enIN);
  }

  ngOnInit(): void {
    const currentPage = this.activatedRoute.snapshot.queryParamMap.get('page');
    const currentSize = this.activatedRoute.snapshot.queryParamMap.get('size');

    const currentSort = this.activatedRoute.snapshot.queryParamMap
      .get('sort')
      ?.split(',');

    if (currentSort) {
      this.sortField = currentSort[0];
      this.sortOrder = currentSort[1] === ASC ? 1 : -1;
    }

    if (currentPage) {
      this.first = Number(currentSize) * (Number(currentPage) - 1);
    }
  }

  ngAfterViewInit() {
    const table =
      this.reportTable.containerViewChild.nativeElement.querySelector('table');
    table.setAttribute('id', 'excelTable');
  }

  resetTablePagination(){
    this.first=0;
  }

  loadFilterValues(event: LazyLoadEvent) {
    let filterDefinitions = [];
    let filterValue = null;
    if (Object.keys(event.filters).length !== 0) {
      for (let column of this.selectedColumns) {
        filterValue = event.filters[column.field];
        if (
          filterValue?.length > 0 &&
          filterValue[0]?.value &&
          column.type != 'dropDown'
        ) {
          let query = new filterQuery();
          query.key = column.field;
          query.operation = filterValue[0].matchMode;
          query.value = filterValue[0].value.toUpperCase().trim();
          filterDefinitions.push(query);
        }
        if (
          filterValue?.length > 0 &&
          filterValue[0]?.value &&
          column.type === 'dropDown' &&
          column.isFilterable
        ) {
          let query = new filterQuery();
          query.key = column.field + '.name';
          query.operation = filterValue[0].matchMode;
          query.value = filterValue[0].value.toUpperCase().trim();
          filterDefinitions.push(query);
        }
      }
    }

    let sortDefinitions = {
      predicate: event.sortField,
      ascending: event.sortOrder === 1 ? true : false,
    };

    this.first = event.first;
    event.first = (event.first + event.rows) / event.rows;

    let filterData = {
      filterDefinitions,
      sortDefinitions,
      page: event.first,
      size: event.rows,
    };
    this.searchParam.emit(filterData);
  }

  // onRowSelected(value, type) {
  //   let data = {
  //     value,
  //     type
  //   };
  //   this.emitRow.emit(data);
  // }

  onSelectRow(record: any, type: any) {
    this.emitRow.emit({ value: record, type });
  }

  onSelectshowTable(record: any) {
    this.showTable.emit(record);
  }

  getDate(date: Date) {
    return dayjs(date);
  }

  navigateProfile() {
    this.router.navigate(['/profile']);
  }

  navigatetoVoucherPage(record: any) {
    this.redirectTo.emit(record);
  }

  exportExcel() {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.table_to_book(
        document.getElementById('excelTable')
      );
      var workbook = xlsx.utils.book_new();
      workbook.SheetNames = worksheet.SheetNames;
      workbook.Sheets = worksheet.Sheets;
      const fileName = this.fileName ? this.fileName + '.xlsx':'report.xlsx';
      xlsx.writeFileXLSX(workbook, fileName);
    });
  }
  downloadVoucherPdf(fileName:string){
    this.paymentService
      .getPdfFile(fileName,true)
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          window.open(url, '_blank');
          link.click();
          URL.revokeObjectURL(url);
          // this.spinner.hide();
          // this.router.navigate(['payment']);
          // this.getfile();
          // this.notificationService.alertSuccess('Saved Successfully', '');
        },
        (err) => {
          const uint8Array = new Uint8Array(err.error);
          const textDecoder = new TextDecoder('utf-8'); 
          const decodedString = textDecoder.decode(uint8Array);
          this.notificationService.alertError(JSON.parse(decodedString).errorKey, '');
          // this.spinner.hide();
        }
      );
  }
}
