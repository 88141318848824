<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="master.component.type.list.name">Component Type List</h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()" styleClass="button">
                        <span
                        class="button-label"
                        [ngClass]="fontService.buttonClass"
                        jhiTranslate="common.newbutton"
                      ></span>
                    </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [disableDeleteIcon]="true" [disableEditIcon]="true" [fileName]="'Component Type'" (emitRow)="onSelectRow($event)" [enableViewIcon]="true"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="componentTypeDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog" [style]="{ width: '850px' }">
        <p-header [ngClass]="fontService.headingClass">
            {{"master.add.component.type.name"|translate}}
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass" jhiTranslate="master.department.name">Department</label>
                            <ng-select [appendTo]="'.p-dialog'" id="department" [(ngModel)]="departmentDto" [items]="departmentList" bindLabel="name" appearance="outline" [closeOnSelect]="true" class="form-control" [disabled]="viewOnly" [clearable]="true" [ngClass]="{ 'is-invalid': ngSelectInvalid && !departmentDto }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.scheme.name">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="schemeDto" [items]="schemeList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !schemeDto }"
                                (change)="onchangeSchema()" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.level.type.name">Level Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="componentType.levelTypeDto" [items]="levelType" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !componentType.levelTypeDto }"
                                class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="departCode" [ngClass]="fontService.labelClass" jhiTranslate="master.component.name.name">Component Name</label>
                            <input type="text" class="form-control" [(ngModel)]="componentType.name" maxlength="100" name="componentName" autocomplete="off" [disabled]="viewOnly" oninput="this.value = this.value.replace(/[^A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !componentType.name }" id="openingBalance" />
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
                <span
                class="button-label"
                [ngClass]="fontService.buttonClass"
                jhiTranslate="common.cancelbutton"
              ></span></button>
            <button *ngIf="!viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
                <span
          class="button-label"
          [ngClass]="fontService.buttonClass"
          jhiTranslate="common.savebutton"
        ></span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>