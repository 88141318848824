import { SchemeList } from './../scheme-list/scheme-list';
import { LevelMaster } from '../level-master/level-master';

export class lbcdto{
    id: number
    schemeDto: SchemeList
    finYearDto: FinYear
    blockMasterId:number;
    villagLevelMasterDto:LevelMaster
    transactionDate: string
    lbcTransactionAmount: number
    activeFlag:boolean
    createdOn: Date
    updatedOn: Date
    createdBy: number
    updatedBy: number
}

export class FinYear {
    id: number
    name: string
  }