import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-name',
  templateUrl: './component-name.component.html',
  styleUrls: ['./component-name.component.scss']
})
export class ComponentNameComponent implements OnInit {

  constructor() { }

  customizeTooltipdemo2(arg: any) {
    return {
      text: `${arg.argumentText}<br>${arg.seriesName} : ${arg.valueText}`,
    };
  }
 
  fundDS:any=[
    {
      "allocatedAmount": 450389.42000000004,
      "utilizedAmount": 332045,
      "balanceAmount": 118344.42000000004,
      "compName": null,
      "levelMasterName": "Coimbatore",
      "levelMasterId": 2,
      "compTypeId": null
    },
    {
      "allocatedAmount": 450801.63,
      "utilizedAmount": 156230.36,
      "balanceAmount": 294571.27,
      "compName": null,
      "levelMasterName": "Cuddalore",
      "levelMasterId": 3,
      "compTypeId": null
    },
    {
      "allocatedAmount": 0,
      "utilizedAmount": 0,
      "balanceAmount": 0,
      "compName": null,
      "levelMasterName": "Dharmapuri",
      "levelMasterId": 4,
      "compTypeId": null
    },
    {
      "allocatedAmount": 2248330.54,
      "utilizedAmount": 843693.64,
      "balanceAmount": 1404636.9,
      "compName": null,
      "levelMasterName": "Dindigul",
      "levelMasterId": 5,
      "compTypeId": null
    },
    {
      "allocatedAmount": 263764.35,
      "utilizedAmount": 0,
      "balanceAmount": 263764.35,
      "compName": null,
      "levelMasterName": "Erode",
      "levelMasterId": 6,
      "compTypeId": null
    },
    {
      "allocatedAmount": 187857.12,
      "utilizedAmount": 0,
      "balanceAmount": 187857.12,
      "compName": null,
      "levelMasterName": "Kancheepuram",
      "levelMasterId": 7,
      "compTypeId": null
    },
    {
      "allocatedAmount": 118542.02,
      "utilizedAmount": 0,
      "balanceAmount": 118542.02,
      "compName": null,
      "levelMasterName": "Kanniyakumari",
      "levelMasterId": 8,
      "compTypeId": null
    },
    {
      "allocatedAmount": 175694.98,
      "utilizedAmount": 0,
      "balanceAmount": 175694.98,
      "compName": null,
      "levelMasterName": "Karur",
      "levelMasterId": 9,
      "compTypeId": null
    },
    {
      "allocatedAmount": 352453.72,
      "utilizedAmount": 0,
      "balanceAmount": 352453.72,
      "compName": null,
      "levelMasterName": "Krishnagiri",
      "levelMasterId": 10,
      "compTypeId": null
    },
    {
      "allocatedAmount": 306867.87,
      "utilizedAmount": 0,
      "balanceAmount": 306867.87,
      "compName": null,
      "levelMasterName": "Madurai",
      "levelMasterId": 11,
      "compTypeId": null
    },
    {
      "allocatedAmount": 135611.52,
      "utilizedAmount": 0,
      "balanceAmount": 135611.52,
      "compName": null,
      "levelMasterName": "Nagapattinam",
      "levelMasterId": 12,
      "compTypeId": null
    },
    {
      "allocatedAmount": 276729.4,
      "utilizedAmount": 0,
      "balanceAmount": 276729.4,
      "compName": null,
      "levelMasterName": "Namakkal",
      "levelMasterId": 13,
      "compTypeId": null
    },
    {
      "allocatedAmount": 127058.76,
      "utilizedAmount": 0,
      "balanceAmount": 127058.76,
      "compName": null,
      "levelMasterName": "Perambalur",
      "levelMasterId": 14,
      "compTypeId": null
    },
    {
      "allocatedAmount": 326737.4,
      "utilizedAmount": 0,
      "balanceAmount": 326737.4,
      "compName": null,
      "levelMasterName": "Pudukkottai",
      "levelMasterId": 15,
      "compTypeId": null
    },
    {
      "allocatedAmount": 261680.43,
      "utilizedAmount": 0,
      "balanceAmount": 261680.43,
      "compName": null,
      "levelMasterName": "Ramanathapuram",
      "levelMasterId": 16,
      "compTypeId": null
    },
    {
      "allocatedAmount": 467797.08,
      "utilizedAmount": 2312.74,
      "balanceAmount": 465484.34,
      "compName": null,
      "levelMasterName": "Salem",
      "levelMasterId": 17,
      "compTypeId": null
    },
    {
      "allocatedAmount": 232847.8,
      "utilizedAmount": 0,
      "balanceAmount": 232847.8,
      "compName": null,
      "levelMasterName": "Sivagangai",
      "levelMasterId": 18,
      "compTypeId": null
    },
    {
      "allocatedAmount": 393060.36,
      "utilizedAmount": 0,
      "balanceAmount": 393060.36,
      "compName": null,
      "levelMasterName": "Thanjavur",
      "levelMasterId": 19,
      "compTypeId": null
    },
    {
      "allocatedAmount": 88223.79,
      "utilizedAmount": 0,
      "balanceAmount": 88223.79,
      "compName": null,
      "levelMasterName": "The Nilgiris",
      "levelMasterId": 20,
      "compTypeId": null
    },
    {
      "allocatedAmount": 136367.13,
      "utilizedAmount": 0,
      "balanceAmount": 136367.13,
      "compName": null,
      "levelMasterName": "Theni",
      "levelMasterId": 21,
      "compTypeId": null
    },
    {
      "allocatedAmount": 397638.14,
      "utilizedAmount": 0,
      "balanceAmount": 397638.14,
      "compName": null,
      "levelMasterName": "Tiruvallur",
      "levelMasterId": 22,
      "compTypeId": null
    },
    {
      "allocatedAmount": 265585.8,
      "utilizedAmount": 0,
      "balanceAmount": 265585.8,
      "compName": null,
      "levelMasterName": "Tiruvarur",
      "levelMasterId": 23,
      "compTypeId": null
    },
    {
      "allocatedAmount": 354524.35,
      "utilizedAmount": 0,
      "balanceAmount": 354524.35,
      "compName": null,
      "levelMasterName": "Tiruchirappalli",
      "levelMasterId": 24,
      "compTypeId": null
    },
    {
      "allocatedAmount": 186215.11,
      "utilizedAmount": 0,
      "balanceAmount": 186215.11,
      "compName": null,
      "levelMasterName": "Tirunelveli",
      "levelMasterId": 25,
      "compTypeId": null
    },
    {
      "allocatedAmount": 507758,
      "utilizedAmount": 0,
      "balanceAmount": 507758,
      "compName": null,
      "levelMasterName": "Tiruvannamalai",
      "levelMasterId": 26,
      "compTypeId": null
    },
    {
      "allocatedAmount": 264540.59,
      "utilizedAmount": 0,
      "balanceAmount": 264540.59,
      "compName": null,
      "levelMasterName": "Thoothukkudi",
      "levelMasterId": 27,
      "compTypeId": null
    },
    {
      "allocatedAmount": 202414.95,
      "utilizedAmount": 0,
      "balanceAmount": 202414.95,
      "compName": null,
      "levelMasterName": "Vellore",
      "levelMasterId": 28,
      "compTypeId": null
    },
    {
      "allocatedAmount": 433226.82,
      "utilizedAmount": 0,
      "balanceAmount": 433226.82,
      "compName": null,
      "levelMasterName": "Vilupuram",
      "levelMasterId": 29,
      "compTypeId": null
    },
    {
      "allocatedAmount": 313502.35,
      "utilizedAmount": 0,
      "balanceAmount": 313502.35,
      "compName": null,
      "levelMasterName": "Virudhunagar",
      "levelMasterId": 30,
      "compTypeId": null
    },
    {
      "allocatedAmount": 165077.59,
      "utilizedAmount": 0,
      "balanceAmount": 165077.59,
      "compName": null,
      "levelMasterName": "Ariyalur",
      "levelMasterId": 31,
      "compTypeId": null
    },
    {
      "allocatedAmount": 298436.24,
      "utilizedAmount": 0,
      "balanceAmount": 298436.24,
      "compName": null,
      "levelMasterName": "Tiruppur",
      "levelMasterId": 32,
      "compTypeId": null
    },
    {
      "allocatedAmount": 207932.99,
      "utilizedAmount": 0,
      "balanceAmount": 207932.99,
      "compName": null,
      "levelMasterName": "Tenkasi",
      "levelMasterId": 33,
      "compTypeId": null
    },
    {
      "allocatedAmount": 194281.63,
      "utilizedAmount": 0,
      "balanceAmount": 194281.63,
      "compName": null,
      "levelMasterName": "Ranipet",
      "levelMasterId": 34,
      "compTypeId": null
    },
    {
      "allocatedAmount": 185957.34,
      "utilizedAmount": 0,
      "balanceAmount": 185957.34,
      "compName": null,
      "levelMasterName": "Tirupathur",
      "levelMasterId": 35,
      "compTypeId": null
    },
    {
      "allocatedAmount": 314594.89,
      "utilizedAmount": 0,
      "balanceAmount": 314594.89,
      "compName": null,
      "levelMasterName": "Kallakurichi",
      "levelMasterId": 36,
      "compTypeId": null
    },
    {
      "allocatedAmount": 289601.45,
      "utilizedAmount": 0,
      "balanceAmount": 289601.45,
      "compName": null,
      "levelMasterName": "Chengalpattu",
      "levelMasterId": 37,
      "compTypeId": null
    },
    {
      "allocatedAmount": 188578.4,
      "utilizedAmount": 0,
      "balanceAmount": 188578.4,
      "compName": null,
      "levelMasterName": "Mayiladuthurai",
      "levelMasterId": 38,
      "compTypeId": null
    }
  ];

  piechart =[{
    region: 'Allocation Amount',
    val: 4119626293,
  }, {
    region: 'Utilized  Amount',
    val: 1012956064,
  }, {
    region: 'Public Contribution Amount',
    val: 590946440,
  }, {
    region: 'Government Contribution Amount',
    val: 727082222,
  }];

  ngOnInit(): void {
  }

}
