import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { PersonalVendors, PersonalVendorsDto } from './personal-vendors';
export type EntityArrayResponseType = HttpResponse<PersonalVendors[]>;

@Injectable()
export class PersonalVendorService {
   

    constructor(private http: HttpClient) { }

    

    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getPersonalVendorByFilter');
    protected resourceValidFliterUrl = environment.SERVER_API_URL+('api/getPersonalVendorValidByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/savePersonalVendor');
    protected resourceEditUrl = environment.SERVER_API_URL+('api/getPersonalVendor')
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deletePersonalVendor');

    filter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<PersonalVendors[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
    }

    validFilter(req?: any,filter?:any): Observable<EntityArrayResponseType> {
      if(filter === null){
        filter = []
      }
      const options = createRequestOption(req);
        return this.http
        .post<PersonalVendors[]>(this.resourceValidFliterUrl,filter, { params:options, observe: 'response' })
    }

    save(profile?:PersonalVendorsDto): Observable<any> {
      return this.http
      .post<PersonalVendorsDto>(this.resourceSaveUrl,profile, {observe: 'response' })

    }

    delete(id:number): Observable<any> {
      
      return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
  }

    getpersonalVendor(id: number): Observable<HttpResponse<any>> {
      return this.http.get<PersonalVendorsDto>(`${this.resourceEditUrl}?id=${id}`, { observe: 'response' });
    }

}