<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass">
                        Work
                    </h5>

                </ng-template>
                <ng-template pTemplate="right">
                    <!-- <button *ngIf="allowEdit" pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()" styleClass="button">
                        <span class="button-label" [ngClass]="fontService.buttonClass"
                            jhiTranslate="common.newbutton"></span>
                    </button> -->

                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" (searchParam)="onLazyLoad($event)" [disableDeleteIcon]="true" (emitRow)="onSelectRow($event)" [disableEditIcon]="allowEdit?false:true" [enableViewIcon]="true"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="projectDialog" [style]="{ width: '850px' }" header="User Details" [modal]="true" styleClass="ib-dialog" (onHide)="hideDialog()">
        <p-header [ngClass]="fontService.headingClass">
            Work
        </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-12">
                            <h6 [ngClass]="fontService.headingClass">
                                Work Details
                            </h6>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Name of the work</label>
                            <textarea id="w3review" [(ngModel)]="project.projectName" name="name" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" cols="100"
                                maxlength="150" uppercase style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.projectName}" id="name" [disabled]="viewOnly"></textarea>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Work ID
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="project.workId" name="name" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workId}" id="name" [disabled]="viewOnly" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="gender" [ngClass]="fontService.labelClass" jhiTranslate="iACompo.limitTrans.fin.year.name">Fin Year</label>
                            <ng-select [appendTo]="'.p-dialog'" id="gender" bindLabel="name" [items]="finYear" appearance="outline" [(ngModel)]="project.finYear" [closeOnSelect]="true" [clearable]="true" class="form-control" [disabled]="viewOnly  && !edit" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.finYear }"
                                (change)="onchangeFinYear()">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="workType" [ngClass]="fontService.labelClass">Work Type</label>
                            <ng-select [appendTo]="'.p-dialog'" id="workType" [(ngModel)]="project.workType" [items]="workTypeList" (change)="onWorkTypeChange()" bindLabel="workCategoryName" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workType }"
                                [disabled]="viewOnly  && !isEditWorkType">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="workCategory" [ngClass]="fontService.labelClass">Work Category</label>
                            <ng-select [appendTo]="'.p-dialog'" id="workCategory" [(ngModel)]="project.workCategory" [items]="workCategoryList" bindLabel="workCategoryName" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.workCategory }"
                                [disabled]="viewOnly && !isEditWorkType">
                            </ng-select>
                        </div>

                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="master.scheme.name">Scheme</label>
                            <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="project.scheme" (change)="onBlockChange()" [items]="schemeList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.scheme }"
                                [disabled]="viewOnly">
                            </ng-select>
                        </div>


                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="block" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.block">Block</label>
                            <ng-select id="block" [(ngModel)]="project.blockMaster" [items]="this.blockLevelList" bindLabel="name" appearance="outline" (search)="onchangeFinYear($event)" (change)="onBlockChange()" [disabled]="viewOnly" [closeOnSelect]="true" [clearable]="false"
                                class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.blockMaster }">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="village" [ngClass]="fontService.labelClass" jhiTranslate="dashBoard.village">Village</label>
                            <ng-select id="village" [(ngModel)]="project.villageMaster" [items]="this.villageLevelList" bindLabel="name" appearance="outline" (search)="onBlockChange($event)" [closeOnSelect]="true" [clearable]="false" class="form-control">
                            </ng-select>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="name">Administrative Sanction No</label>
                            <input type="text" class="form-control" [(ngModel)]="project.administrativeSanctionNo" name="name" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase style="text-transform:uppercase" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.administrativeSanctionNo}" id="name" [disabled]="viewOnly" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass">Administrative Sanction Date</label>
                            <p-calendar styleClass="datepicker" class="same_line_fix" [(ngModel)]="project.administrativeSanctionDate" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.administrativeSanctionDate}" appendTo="body" [disabled]="viewOnly"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="Amount" [ngClass]="fontService.labelClass">Administrative Sanction Amount</label>
                            <input type="text" class="form-control" name="Amount" autocomplete="off" id="Amount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="project.totalCost" (change)="calcAmount()" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.totalCost}"
                                maxlength="15" [disabled]="viewOnly" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="tSanctionNo">Technical Sanction No</label>
                            <input type="text" class="form-control" [(ngModel)]="project.technicalSanctionNo" name="tSanctionNo" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');"
                                onblur="this.value =this.value.trim();" uppercase style="text-transform:uppercase" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.technicalSanctionNo}" id="tSanctionNo" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass">Technical Sanction Date</label>
                            <p-calendar styleClass="datepicker" class="same_line_fix" [disabled]="viewOnly" [(ngModel)]="project.technicalSanctionDate" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.technicalSanctionDate}" appendTo="body"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="Amount" [ngClass]="fontService.labelClass">Technical Sanction Amount</label>
                            <input type="text" class="form-control" name="Amount" autocomplete="off" id="Amount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="project.technicalSanctionAmount" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.technicalSanctionAmount}"
                                maxlength="15" [disabled]="viewOnly" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" [ngClass]="fontService.labelClass" for="agreementNo">Agreement No</label>
                            <input type="text" class="form-control" [(ngModel)]="project.agreementNo" name="agreementNo" autocomplete="off" maxlength="60" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                                uppercase style="text-transform:uppercase" [disabled]="viewOnly && !edit" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.agreementNo}" id="agreementNo" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="date" [ngClass]="fontService.labelClass">Agreement Date</label>
                            <p-calendar styleClass="datepicker" [disabled]="viewOnly && !edit" class="same_line_fix" [(ngModel)]="project.agreementDate" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.agreementDate}" appendTo="body"></p-calendar>
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="agreementAmount" [ngClass]="fontService.labelClass">Agreement Amount</label>
                            <input type="text" class="form-control" name="agreementAmount" autocomplete="off" id="agreementAmount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" [(ngModel)]="project.agreementAmount" [ngClass]="{ 'is-invalid': ngSelectInvalid && !project.agreementAmount}"
                                maxlength="15" [disabled]="viewOnly && !edit" />
                        </div>
                        <div class="col-md-12">
                            <label class="form-control-label req" for="upload" [ngClass]="fontService.labelClass">Upload AS Copy</label><br>
                            <small [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file
                                types are jpg, jpeg, png, pdf and
                                Maximum file size should be 1MB</small>
                        </div>
                        <div class="col-md-12" *ngIf="!isFile">
                            <p-fileUpload (onUpload)="onUploadfile1($event)" [customUpload]="true" auto="true" (uploadHandler)="onUploadfile1($event)" multiple="false" [showUploadButton]="false" [disabled]="isFile || (viewOnly && !edit)" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                            </p-fileUpload>
                            <div class="row mt-2" *ngIf="!isFile && ngSelectInvalid">
                                <small class="text-danger">Please Upload the File</small>
                            </div>
                        </div>
                        <div class="col" *ngIf="isFile">
                            <a class="cursor-pointer" (click)="viewDocument()">{{project.fileUpload}}</a>
                            <i *ngIf="!viewOnly || edit" class="pi pi-times add-margin" (click)="removeDocument()"></i>
                        </div>
                        <!-- <div class="col-sm-6 col-md-4">
                                <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">public Contribution
                                </label><br />
                                <p-radioButton name="option" [value]="true" [(ngModel)]="enablepublicContributionAmount" (onClick)="calcAmount()" label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                <p-radioButton name="option" [value]="false" [(ngModel)]="enablepublicContributionAmount" (onClick)="calcAmount()" label="{{ 'payments.amount.no' | translate }}"></p-radioButton>

                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="enablepublicContributionAmount">
                                <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">public Contribution Percentage</label>
                                <ng-select [appendTo]="'.p-dialog'" id="scheme" [(ngModel)]="selectedpublicContribution" (change)="calcAmount()" [items]="publicContributionAmount" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" class="form-control" [ngClass]="{ 'is-invalid': ngSelectInvalid && !selectedpublicContribution}">
                                </ng-select>
                            </div>
                            <div class="col-sm-6 col-md-4" *ngIf="enablepublicContributionAmount">
                                <label class="form-control-label req" for="pinCode" [ngClass]="fontService.labelClass">Public Contribution 
                                    Amount</label>
                                <p>{{project?.publicContributionAmount?.toFixed(2)}}</p>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <label class="form-control-label req" for="pinCode" [ngClass]="fontService.labelClass">Government Contribution 
                                    Amount</label>
                                <p>{{project?.administrativeSanctionAmount}}</p>
                            </div> -->
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()">
                <span class="button-label" [ngClass]="fontService.buttonClass" custom-button
                    jhiTranslate="common.cancelbutton"></span>
            </button>
            <button *ngIf="!project.id && !viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.addbutton"></span>
            </button>
            <button *ngIf="project.id && (!viewOnly || edit)" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()">
                <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span>
            </button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
    <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
        <ng-template pTemplate="body">
            <div style='position: relative; height: 100%;'>
                <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
            </div>
        </ng-template>
    </p-dialog>
</div>