import { Component, OnInit } from '@angular/core';
import { DrillDownReportService } from '../drillDown.service';
import { VoucherTranacstionDto, countReportModel } from '../drillDown.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DatePipe } from '@angular/common';
import { SearchResult } from 'src/app/shared/models/search-result';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { HttpResponse } from '@angular/common/http';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit {
  activeIndex = 0;
  activeIndexBlock = 0;
  districtPaymentList: countReportModel[];
  toDate: Date;
  fromDate: Date;
  itemsPerPage: any = 10;
  districtPaymentSuccessList: countReportModel[];
  searchVendorTranaction: SearchResult<VoucherTranacstionDto> = new SearchResult<VoucherTranacstionDto>();
  failedVendorTranaction: SearchResult<VoucherTranacstionDto> = new SearchResult<VoucherTranacstionDto>();
  RejectVendorTranaction: SearchResult<VoucherTranacstionDto> = new SearchResult<VoucherTranacstionDto>();
  currentLevelMasterId: any;
  VoucherDialog: boolean;
  currentpaymentStatus: any;
  districtLevelList: LevelMasterDto[];
  district:any;
  cols: any;
  failVoucherDialog: boolean;
  rejectVoucherDialog: boolean;
  dataLevel: string;
  filterLevel: any;
  blockPaymentList: countReportModel[];
  constructor(private drillDownReportService: DrillDownReportService, public fontService: FontService, private datePipe: DatePipe, private levelMasterService: LevelMasterService, private spinner: NgxSpinnerService,) {}
  

  ngOnInit(): void {
    this.dataLevel = 'District';
    this.getPaymentStatusByDistrict();
    this.onStateLevelChange();
  }

  getPaymentStatusByDistrict(id?) {
    let filterQuery = {};
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    if(this.dataLevel === 'Block'){
      filterQuery['filterLevel'] = 'District';
      filterQuery['parentId'] = 1; 
    }
    if(this.district){
      filterQuery['levelMasterId'] = this.district?.id; 
    }
    else if(id){
      filterQuery['parentId'] = id;
      filterQuery['filterLevel'] = 'Block';
    }
    else{
      filterQuery['parentId'] = 1; 
    }
    filterQuery['dataLevel'] = this.dataLevel;
   
    this.drillDownReportService.getDistrictPaymentCount(filterQuery).subscribe(
      res => {
        
        if(id){
          this.activeIndexBlock = 1;
          this.blockPaymentList = res.body;
        }
        else{
          this.districtPaymentList = res.body;
        }
      },
      error => {}
    );
  }

  loadDistrictWiseSuccessVoucher(id: number, pageToLoad?) {
    this.cols = null;
    this.VoucherDialog = true;
    this.spinner.show();
    this.currentpaymentStatus = 'Success';
    this.currentLevelMasterId = id;
    this.cols =  [
      {
        field: 'name',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'voucherDate',
        header: ' Voucher Date',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorBankName',
        header: 'Bank Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transcationNumber',
        header: 'Transaction No',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'deductions',
        header: 'Deductions',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'status',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id};
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    this.drillDownReportService.getDistrictPaymentSuccessList(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.searchVendorTranaction.total = Number(res.headers.get('X-Total-Count'));
        this.searchVendorTranaction.items = res.body ?? [];
        this.searchVendorTranaction = { ...this.searchVendorTranaction };
       
      },
      error => {
        this.spinner.hide();
        this.VoucherDialog = false;
      }
    );
  }

  loadDistrictWiseFailedVoucher(id: number, pageToLoad?) {
    this.cols = null;
    this.failVoucherDialog = true;
    this.spinner.show();
    this.currentpaymentStatus = 'Failed';
    this.currentLevelMasterId = id;
    this.cols =  [
      {
        field: 'name',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'voucherDate',
        header: ' Voucher Date',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorBankName',
        header: 'Bank Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transcationNumber',
        header: 'Transaction No',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'deductions',
        header: 'Deductions',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'status',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'reason',
        header: 'Reason',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id };
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    this.drillDownReportService.getDistrictPaymentFailedList(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.failedVendorTranaction.total = Number(res.headers.get('X-Total-Count'));
        this.failedVendorTranaction.items = res.body ?? [];
        this.failedVendorTranaction = { ...this.failedVendorTranaction };
        
      },
      error => {
        this.spinner.hide();
        this.failVoucherDialog = false;
      }
    );
  }

  loadDistrictWiseRejectVoucher(id: number,pageToLoad?) {
    this.cols = null;
    this.rejectVoucherDialog = true; 
    this.spinner.show();
    this.currentpaymentStatus = 'Reject';
    this.currentLevelMasterId = id;
    this.cols =  [
      {
        field: 'name',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'voucherDate',
        header: ' Voucher Date',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorBankName',
        header: 'Bank Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transcationNumber',
        header: 'Transaction No',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'deductions',
        header: 'Deductions',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'status',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'reason',
        header: 'Reason',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id };
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    this.drillDownReportService.getDistrictPaymentRejectList(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.RejectVendorTranaction.total = Number(res.headers.get('X-Total-Count'));
        this.RejectVendorTranaction.items = res.body ?? [];
        this.RejectVendorTranaction = { ...this.RejectVendorTranaction};
        
      },
      error => {
        this.spinner.hide();
        this.rejectVoucherDialog = false;
      }
    );
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    switch (this.currentpaymentStatus) {
      case "Success":
        this.loadDistrictWiseSuccessVoucher(this.currentLevelMasterId, event.page);
        break;
      case "Failed":
        this.loadDistrictWiseFailedVoucher(this.currentLevelMasterId, event.page);
        break;
        case "Reject":
          this.loadDistrictWiseRejectVoucher(this.currentLevelMasterId, event.page);
          break;
    }
    
    
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onTabChange(event: any): void {
    switch (event.index) {
      case 0:
        this.dataLevel = 'District';
        
        break;
      case 1:
        this.dataLevel = 'Block';
        this.filterLevel = 'District';
        break;
    }
    this.getPaymentStatusByDistrict();
  }
}
