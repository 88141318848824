import { Component, OnInit } from '@angular/core';
import { DrillDownReportService } from '../drillDown.service';
import { VoucherTranacstionDto, countReportModel } from '../drillDown.model';
import { FontService } from 'src/app/shared/font-service/font.service';
import { DatePipe } from '@angular/common';
import { SearchResult } from 'src/app/shared/models/search-result';
import { LevelMasterDto } from 'src/app/module/level-master/level-master';
import { HttpResponse } from '@angular/common/http';
import { LevelMasterService } from 'src/app/module/level-master/level-master.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-voucher-status',
  templateUrl: './voucher-status.component.html',
  styleUrls: ['./voucher-status.component.scss']
})
export class VoucherStatusComponent implements OnInit {

  activeIndex = 0;
  activeIndexBlock = 0;
  districtVoucherList: countReportModel[];
  toDate: Date;
  fromDate: Date;
  itemsPerPage: any = 10;
  districtPaymentSuccessList: countReportModel[];
  sign1VendorTranaction: SearchResult<VoucherTranacstionDto> = new SearchResult<VoucherTranacstionDto>();
  sign2VendorTranaction: SearchResult<VoucherTranacstionDto> = new SearchResult<VoucherTranacstionDto>();
  sign3VendorTranaction: SearchResult<VoucherTranacstionDto> = new SearchResult<VoucherTranacstionDto>();
  currentLevelMasterId: any;
  VoucherDialog: boolean;
  currentpaymentStatus: any;
  districtLevelList: LevelMasterDto[];
  district:any;
  cols: any;
  sign2VoucherDialog: boolean;
  sign3VoucherDialog: boolean;
  dataLevel: string;
  filterLevel: string;
  blockVoucherList: countReportModel[];
  constructor(private drillDownReportService: DrillDownReportService, public fontService: FontService, private datePipe: DatePipe, private levelMasterService: LevelMasterService, private spinner: NgxSpinnerService,) {}
  

  ngOnInit(): void {
    this.dataLevel = 'District';
    this.getVoucherStatusByDistrict();
    this.onStateLevelChange();
  }

  getVoucherStatusByDistrict(id?) {
    let filterQuery = {};
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    if(this.dataLevel === 'Block'){
      filterQuery['filterLevel'] = 'District';
      filterQuery['parentId'] = 1; 
    }
    if(this.district){
      filterQuery['levelMasterId'] = this.district?.id; 
    }
    else if(id){
      filterQuery['parentId'] = id;
      filterQuery['filterLevel'] = 'Block';
    }
    else{
      filterQuery['parentId'] = 1; 
    }
    filterQuery['dataLevel'] = this.dataLevel;
    this.drillDownReportService.getDistrictVoucherCount(filterQuery).subscribe(
      res => {
        if(id){
          this.activeIndexBlock = 1;
          this.blockVoucherList = res.body;
        }
        else{
          this.districtVoucherList = res.body;
        }
        
      },
      error => {}
    );
  }

  loadDistrictWiseSign1Voucher(id: number, pageToLoad?) {
    this.cols = null;
    this.VoucherDialog = true;
    this.spinner.show();
    this.currentpaymentStatus = 'sign1';
    this.currentLevelMasterId = id;
    this.cols =  [
      {
        field: 'name',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'voucherDate',
        header: ' Voucher Date',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorBankName',
        header: 'Bank Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transcationNumber',
        header: 'Transaction No',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'deductions',
        header: 'Deductions',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'status',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id};
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    this.drillDownReportService.getDistrictVouchersign1PendingList(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.sign1VendorTranaction.total = Number(res.headers.get('X-Total-Count'));
        this.sign1VendorTranaction.items = res.body ?? [];
        this.sign1VendorTranaction = { ...this.sign1VendorTranaction };
      },
      error => {
        this.spinner.hide();
        this.VoucherDialog = false;
      }
    );
  }

  loadDistrictWisesign2Voucher(id: number, pageToLoad?) {
    this.cols = null;
    this.sign2VoucherDialog = true;
    this.spinner.show();
    this.currentpaymentStatus = 'sign2';
    this.currentLevelMasterId = id;
    this.cols =  [
      {
        field: 'name',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'voucherDate',
        header: ' Voucher Date',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorBankName',
        header: 'Bank Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transcationNumber',
        header: 'Transaction No',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'deductions',
        header: 'Deductions',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'status',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
     
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id };
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    this.drillDownReportService.getDistrictVouchersign2PendingList(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.sign2VendorTranaction.total = Number(res.headers.get('X-Total-Count'));
        this.sign2VendorTranaction.items = res.body ?? [];
        this.sign2VendorTranaction = { ...this.sign2VendorTranaction };
        
      },
      error => {
        this.spinner.hide();
        this.sign2VoucherDialog = false;
      }
    );
  }

  loadDistrictWisesign3Voucher(id: number,pageToLoad?) {
    this.cols = null;
    this.sign3VoucherDialog = true; 
    this.spinner.show();
    this.currentpaymentStatus = 'sign3';
    this.currentLevelMasterId = id;
    this.cols =  [
      {
        field: 'name',
        header: 'District',
        isSelectcolumn: true,
        isFilterable: false,
      },
      {
        field: 'voucherNo',
        header: 'Voucher No',
        isFilterable: false,
        isSelectcolumn: true,
        jhiTranslate: 'tableHead.voucher.no',
      },
      {
        field: 'voucherDate',
        header: ' Voucher Date',
        isFilterable: false,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'vendorName',
        header: 'Vendor Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'vendorBankName',
        header: 'Bank Name',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transcationNumber',
        header: 'Transaction No',
        isFilterable: false,
        isSelectcolumn: true,
      },
      {
        field: 'transDate',
        header: 'Transaction Date',
        jhiTranslate: 'tableHead.transaction.date',
        isSortable: true,
        isSelectcolumn: true,
        type: 'date',
      },
      {
        field: 'transactionAmount',
        header: 'Transaction Amount',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'deductions',
        header: 'Deductions',
        isSelectcolumn: true,
        isFilterable: false,
        type: 'amount',
      },
      {
        field: 'status',
        header: 'Status',
        isSelectcolumn: true,
        isFilterable: false,
      },
     
    ];
    let filterQuery = { page: pageToLoad - 1, size: this.itemsPerPage, levelMasterId: id };
    if (this.fromDate && this.toDate) {
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd') + ' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd') + ' 23:59:59.999';
    }
    this.drillDownReportService.getDistrictVouchersign3PendingList(filterQuery).subscribe(
      res => {
        this.spinner.hide();
        this.sign3VendorTranaction.total = Number(res.headers.get('X-Total-Count'));
        this.sign3VendorTranaction.items = res.body ?? [];
        this.sign3VendorTranaction = { ...this.sign3VendorTranaction};
        
      },
      error => {
        this.spinner.hide();
        this.sign3VoucherDialog = false;
      }
    );
  }

  onLazyLoad(event) {
    this.itemsPerPage = event.size;
    switch (this.currentpaymentStatus) {
      case "sign1":
        this.loadDistrictWiseSign1Voucher(this.currentLevelMasterId, event.page);
        break;
      case "sign2":
        this.loadDistrictWisesign2Voucher(this.currentLevelMasterId, event.page);
        break;
        case "sign3":
          this.loadDistrictWisesign3Voucher(this.currentLevelMasterId, event.page);
          break;
    }
    
    
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onTabChange(event: any): void {
    switch (event.index) {
      case 0:
        this.dataLevel = 'District';
        
        break;
      case 1:
        this.dataLevel = 'Block';
        this.filterLevel = 'District';
        break;
    }
    this.getVoucherStatusByDistrict();
  }

}
