import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  isMobileValid(mobile: string): boolean {
    const regex = /^[6-9]\d{9}$/;
    return regex.test(mobile);
  }

  isEmailValid(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }


  isGstValid(gstNo) {
    const gstNoPattern = /^[0-9]{2}[A-Z]{4}[0-9A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[A-Z]{1}[0-9A-Z]{1}$/;
    return gstNoPattern.test(gstNo);
  }

  isIfscValid(ifscCode: string): boolean {
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return regex.test(ifscCode);
  }

  isPanValid(pan: string): boolean {
    const panRegex =/^([A-Z]{5}\d{4}[A-Z])$/;
    return panRegex.test(pan);
  }

  isPincodeValid(pincode: string): boolean {
    const pincodeRegex = /^[1-9][0-9]{5}$/; 
    return pincodeRegex.test(pincode);
  }

  isOtpValid(otp: string): boolean {
    const pincodeRegex = /^[0-9]{6}$/; 
    return pincodeRegex.test(otp);
  }

  isPasswordValid(password: string): boolean {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    return passwordRegex.test(password);
  }

  isTanValid(tan: string): boolean {
    const tanRegex = /([A-Z]){4}([0-9]){5}([A-Z]){1}$/;
    return tanRegex.test(tan);
  }

  isTinValid(tin: string): boolean {
    const tinRegex = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/;
    return tinRegex.test(tin);
  }

  isAadhaarValid(Aadhaar: string): boolean {
    const aadhaarRegex = /^[2-9]{1}[0-9]{11}$/;
    return aadhaarRegex.test(Aadhaar);
  }

  isHsnValid(hsn:string):boolean{
    const hsnRegex=/^[0-9]{2}[A-Z]{4}[0-9]{2}$/;
    return hsnRegex.test(hsn);
  }

}
