import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { AccountSna } from '../account-sna/account-sna';
import { OpeningBalance, OpeningBalanceDto, SnaFundsDto } from './opening-balance';


@Injectable()
export class OpeningBalanceService {
    protected resourceFliterUrl = environment.SERVER_API_URL+('api/getOpeningBalanceByFilter');
    protected resourceSaveUrl = environment.SERVER_API_URL+('api/saveOpeningFund');
    protected resourceDeleteUrl = environment.SERVER_API_URL+('api/deletOpeningFund');
    protected getFundsUrl = environment.SERVER_API_URL+('api/getOpeningFund');
    constructor(protected http: HttpClient) {}
    filter(req?: any,filter?:any): Observable<any> {
        if(filter === null){
          filter = []
        }
        const options = createRequestOption(req);
          return this.http
          .post< OpeningBalance[]>(this.resourceFliterUrl,filter, { params:options, observe: 'response' })
      }
      save(snaFundsDto?:SnaFundsDto): Observable<any> {
        return this.http
        .post<any>(this.resourceSaveUrl,snaFundsDto, {observe: 'response' })
    }

    delete(id: number): Observable<HttpResponse<{}>> {
        return this.http.get(`${this.resourceDeleteUrl}?id=${id}`, { observe: 'response' });
      }
  
      getOpeningBalance(id: number): Observable<HttpResponse<any>> {
        return this.http.get<OpeningBalanceDto>(`${this.getFundsUrl}?id=${id}`, { observe: 'response' });
      }


    
}