import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild  } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router  } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { SearchResult } from '../shared/models/search-result';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportService } from './dynamic-report-service';
import { LevelMasterDto } from '../module/level-master/level-master';
import { LevelMasterService } from '../module/level-master/level-master.service';
import { FinYear } from '../module/limit-translist/limit-translist.module';
import { LimitTransListService } from '../module/limit-translist/limit-translist.service';
import { DatePipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { CustomFilterTableComponent } from '../shared/custom-filter-table/custom-filter-table.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-report-table',
  templateUrl: './dynamic-report-table.component.html',
  styleUrls: ['./dynamic-report-table.component.scss'],
})
export class DynamicReportTableComponent implements OnInit {
  private routerEventsSubscription: Subscription;
  @ViewChild(CustomFilterTableComponent) customFilterTableComponent: CustomFilterTableComponent;
  reportData:any;
  isReload: boolean;
  searchResult: SearchResult<any> = new SearchResult<any>();
  itemsPerPage: any = 10;
  page: number;
  filterProps: any;
  currentElement: any;
  cols: any;
  districtLevelList: LevelMasterDto[];
  district: any;
  BlockLevelDto: null;
  blockLevelList: LevelMasterDto[];
  finYear: FinYear[];
  finYearDto:any;
  toDate:Date;
  fromDate:Date;
  statusList = [
    { 
      name: 'Approved',
      value:'Approved'
   },
    {
      name: 'Reject',
      value:'Reject'
    },
    {
      name: 'Pending',
      value:'Pending'
    }
  ];
  selectedStatus: any;

  constructor(
    protected activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService,
    private reportService: ReportService,
    private levelMasterService: LevelMasterService,
    private limitTransListService:LimitTransListService,
    private datePipe:DatePipe,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.startsWith('/dynamicReport')) {
        this.reloadComp();
        this.isReload=true;
      }
      });
  }

  ngOnInit(): void {
  }

  reloadComp(){
    if(this.isReload){
      this.customFilterTableComponent.resetTablePagination();
    }
    this.httpClient
      .get('/assets/json/report.json')
      .subscribe((reportData: any) => {
        reportData.forEach((element) => {
          if (element.menu_id === Number(this.route.snapshot.queryParamMap.get('id'))) {
            this.currentElement = element;
            this.cols = element.tableHead;
            if (element.enable_District) {
              if(Object.keys(this.currentElement?.queryparms).length > 0){
                this.levelMasterService.getLevelMaster(2).subscribe(
                  (res: HttpResponse<any>) => {
                    this.district = res.body;
                  },
                  () => {}
                );
              }
              this.onStateLevelChange();
            }
          
            if(element.enable_finyear){
              this.limitTransListService.getFinYear().subscribe(
                (res: HttpResponse<FinYear[]>) => {
                  this.finYear = res.body;
                },
                () => {}
              );
            }
            this.loadPage(1);
          }
        });
      });
  }

  onStateLevelChange($event?) {
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: 1,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.districtLevelList = res.body;
          },
          () => {}
        );
    }
  }

  onDistrictLevelChange($event?) {
    this.BlockLevelDto = null;
    if ($event) {
      let filterQuery = {
        page: 0,
        size: 50,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.district.id,
          },
          {
            key: 'name',
            operation: 'contains',
            value: $event.term.toUpperCase(),
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    } else {
      let filterQuery = {
        page: 0,
        size: 10,
      };
      this.levelMasterService
        .LevelMasterDtoFilter({ ...filterQuery }, [
          {
            key: 'parentId',
            operation: 'equals',
            value: this.district.id,
          },
        ])
        .subscribe(
          (res: HttpResponse<LevelMasterDto[]>) => {
            this.blockLevelList = res.body;
          },
          () => {}
        );
    }
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
    this.spinner.show();
    const pageToLoad: number = page ?? this.page ?? 1;
     let filterQuery = this.currentElement?.queryparms;
     filterQuery['page']=pageToLoad - 1;
     filterQuery['size']=this.itemsPerPage;
    if(this.district){
      filterQuery[this.currentElement?.district_field]=this.district.id
    }
    if(this.finYearDto){
      filterQuery[this.currentElement?.finyear_fields]=this.finYearDto.id
    }
    if(this.currentElement.enable_date && !this.fromDate){
    filterQuery['fromDate'] = this.datePipe.transform(new Date("04-01-2023"), 'yyyy-MM-dd')+' 00:00:00.000';
    filterQuery['toDate'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd')+' 23:59:59.999';
    }
    if(this.currentElement.enable_date && this.fromDate && this.toDate){
      filterQuery = this.currentElement?.queryparms;
      filterQuery['fromDate'] = this.datePipe.transform(new Date(this.fromDate), 'yyyy-MM-dd')+' 00:00:00.000';
      filterQuery['toDate'] = this.datePipe.transform(new Date(this.toDate), 'yyyy-MM-dd')+' 23:59:59.999';
      }
    if(this.selectedStatus){
      filterQuery[this.currentElement?.status_field]=this.selectedStatus.value;
    }
    this.filterProps = this.currentElement?.payload;
   
   

    this.reportService
      .getData(
        this.currentElement.api_name,
        { ...filterQuery },
        this.filterProps
      )
      .subscribe(
        (res: HttpResponse<any[]>) => {
          this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
        },
        () => {
          this.onError();
        }
      );
  }

  protected onSuccess(
    data: any[] | null,
    headers: HttpHeaders,
    page: number,
    navigate: boolean
  ): void {
    this.searchResult.total = Number(headers.get('X-Total-Count'));
    this.spinner.hide();
    this.page = page;

    this.searchResult.items = data ?? [];
    this.searchResult = { ...this.searchResult };
  }

  protected onError(): void {
    this.spinner.hide();
  }

  onLazyLoad(event) {
    this.filterProps = event.filterDefinitions;
    this.itemsPerPage = event.size;
    this.loadPage(event.page);
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
