import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeListService } from '../scheme-list/scheme-list.service';
import { LevelTypeService } from '../level-type/level-type.service';
import { ComponentTypeService } from '../component-type/component-type.service';
import { CommercialVendorService } from '../commercial-vendors/commercial-vendors.service';
import { PaymentService } from 'src/app/payments/payment-voucher/payment-voucher.service';
import { DepartmentListService } from '../department-list/department-list.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { FontService } from 'src/app/shared/font-service/font.service';
import { ValidationService } from 'src/app/shared/validation/validation.service';
import { NotificationService } from 'src/app/alert/notification.service';
import { SigninService } from 'src/app/auth/signin/signin.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProjectService } from 'src/app/project/project.service';
import { TenderMapping, VendorMapping, tender } from './vendor-mapping-model';
import { Dialog } from 'primeng/dialog';
import cloneDeep from 'lodash/cloneDeep';
import {
  CommercialVendors,
  CommercialVendorsDto,
} from '../commercial-vendors/commercial-vendors';
import { NavbarComponent } from 'src/app/layout/navbar/navbar.component';
import { filterQuery } from 'src/app/shared/common.model';
import { TenderPageService } from './tendor-page.service';
import { LevelMaster } from '../level-master/level-master';

@Component({
  selector: 'app-vendor-mapping-page',
  templateUrl: './vendor-mapping-page.component.html',
  styleUrls: ['./vendor-mapping-page.component.scss'],
})
export class VendorMappingPageComponent implements OnInit {
  @ViewChild('dialog') dialog: Dialog;
  @ViewChild('formData') formData;
  navbarComponent!: NavbarComponent;
  selectedVendor: any;
  roleCode: any;
  vendorList: any;
  filterProps: any[];
  project: any;
  tenderMapping: tender;
  ngSelectInvalid: boolean;
  readonly: boolean;
  isFileUploaded: boolean;
  uFilename: any;
  vFileExt: any;
  vFileUrl: string;
  viewDoc: boolean;
  isFile: boolean;
  isCreate: any;
  minDate = new Date('01/03/2015');
  maxDate = new Date();
  approveView: boolean;
  loadingMore: boolean=false;
  currentPage: number = 0;
  constructor(
    protected activatedRoute: ActivatedRoute,
    private schemeListService: SchemeListService,
    private levelTypeService: LevelTypeService,
    private componentTypeService: ComponentTypeService,
    private commercialVendorService: CommercialVendorService,
    private paymentService: PaymentService,
    private router: Router,
    private departmentListService: DepartmentListService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    public fontService: FontService,
    public validationService: ValidationService,
    protected notificationService: NotificationService,
    private loginService: SigninService,
    private http: HttpClient,
    private projectService: ProjectService,
    private tenderPageService: TenderPageService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getRole();

    if (this.activatedRoute.snapshot.queryParamMap.get('id')) {
      if (this.activatedRoute.snapshot?.queryParamMap?.get('view')) {
        this.readonly = true;
      }
      if (this.activatedRoute.snapshot?.queryParamMap?.get('approve')) {
        this.readonly = true;
        this.approveView = true;
      }
      this.spinner.show();
      this.tenderPageService
        .getVendorMapping(
          Number(this.activatedRoute.snapshot.queryParamMap.get('id'))
        )
        .subscribe((res: HttpResponse<tender>) => {
          this.tenderMapping = res.body;
          this.selectedVendor = [];
          this.tenderMapping.vendorMappings.forEach((element) => {
            this.selectedVendor.push(element.commercialVendor);
          });
          if (this.tenderMapping?.tenderMapping?.fileUpload) {
            this.isFileUploaded = true;
            this.isFile = true;
          }
          this.tenderMapping.tenderMapping.workProcurementDate = new Date(
            this.tenderMapping.tenderMapping.workProcurementDate
          );
          this.tenderMapping.tenderMapping.tenderDate = new Date(
            this.tenderMapping.tenderMapping.tenderDate
          );
          this.vendorFilter();
          this.spinner.hide();
        });
    } else {
      this.tenderMapping = new tender();
      this.tenderMapping.tenderMapping = new TenderMapping();
      this.tenderMapping.tenderMapping.levelMaster = this.roleCode.levelMaster;
      this.spinner.hide();
    }
  }

  ngAfterViewInit() {
    if (this.formData) {
      this.formData.valueChanges.subscribe((event) => {});
    }
  }

  getRole(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.loginService.getuserDto().subscribe(
        (res) => {
          this.roleCode = res.body;
          resolve(this.roleCode);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  getVendor(id: number): Promise<any> {
    return new Promise<any>((resolve) => {
      this.commercialVendorService.getcommercialVendor(id).subscribe(
        (res) => {
          resolve(res.body);
        },
        (onError) => {
          resolve(null);
        }
      );
    });
  }

  cancel() {
    this.router.navigate(['/tender-table']);
  }

  save() {
    this.tenderMapping.tenderMapping.finYear =
      this.tenderMapping.tenderMapping.work.finYear;
    const isFormValid =
      this.tenderMapping.tenderMapping.workId &&
      this.selectedVendor &&
      this.tenderMapping.tenderMapping.tenderNo &&
      this.tenderMapping.tenderMapping.tenderDate &&
      this.tenderMapping.tenderMapping.workProcurementDate &&
      this.tenderMapping.tenderMapping.workProcurementNo;
    if (
      !this.tenderMapping.tenderMapping.fileUpload ||
      !this.tenderMapping.tenderMapping
    ) {
      this.notificationService.alertError('Please upload the file', '');
      return;
    }
    if (!isFormValid) {
      this.ngSelectInvalid = true;
      return;
    }

    this.tenderPageService.save(this.tenderMapping).subscribe(
      (res) => {
        console.log('ressss', res);
        this.router.navigate(['/tender-table']);
      },
      (onError) => {
        console.log('onError.error', onError);
        this.notificationService.alertError(onError.error.errorKey, '');
      }
    );
  }

  viewDocument(fileType) {
    let file = '';
    if (fileType === 'tendor') {
      file = this.tenderMapping.tenderMapping.fileUploadTendor;
    } else if (fileType === 'workOrder') {
      file = this.tenderMapping.tenderMapping.fileUpload;
    }
    this.paymentService.viewimage(file).subscribe((res) => {
      const filename = res.body.fileName;
      this.vFileExt = filename.split('.').pop();
      this.vFileUrl =
        'data:image/' + this.vFileExt + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
      this.viewDoc = true;
    });
  }
  downloadImage(base64Data: string, filename: string) {
    const link = document.createElement('a');
    link.href = base64Data;
    link.download = filename;
    link.click();
  }
  downloadDocument(fileType) {
    let file = '';
    if (fileType === 'tendor') {
      file = this.tenderMapping.tenderMapping.fileUploadTendor;
    } else if (fileType === 'workOrder') {
      file = this.tenderMapping.tenderMapping.fileUpload;
    }
    this.paymentService.getimage(file).subscribe(
      (res) => {
        const filename = res.body.fileName;
        const fileExtension = filename.split('.').pop();

        if (fileExtension == 'pdf') {
          const base64Data = 'data:application/pdf;base64,' + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        } else {
          const base64Data =
            'data:image/' + fileExtension + ';base64,' + res.body.fileType; // Replace with your Base64-encoded image string
          this.downloadImage(base64Data, filename);
        }
      },
      (onError) => {}
    );
  }
  removeDocument(fileType) {
    let file = '';
    if (fileType === 'tendor') {
      file = this.tenderMapping.tenderMapping.fileUploadTendor;
    } else if (fileType === 'workOrder') {
      file = this.tenderMapping.tenderMapping.fileUpload;
    }
    this.paymentService.deleteimage(file).subscribe(
      (res) => {
        this.notificationService.alertSuccess('File Deleted', '');
        if (fileType === 'tendor') {
          this.isFile = false;
          this.tenderMapping.tenderMapping.fileUploadTendor = null;
        } else if (fileType === 'workOrder') {
          this.isFileUploaded = false;
          this.tenderMapping.tenderMapping.fileUpload = null;
        }
      },
      (error) => {
        this.notificationService.alertError('Error occured, try again', '');
      }
    );
  }

  onUpload($event, fileType) {
    console.log('dldldldldldldldldldldldldldd', $event);
    const formData = new FormData();
    const i = $event.files.length - 1;
    const fileData = new Blob([$event.files[i]], {
      type: $event.files[i].type,
    });
    if ($event.files[i].type === 'application/pdf') {
      formData.append('file', fileData, `.pdf`);
    } else if ($event.files[i].type === 'image/png') {
      formData.append('file', fileData, `.png`);
    } else if ($event.files[i].type === 'image/jpeg') {
      formData.append('file', fileData, `.jpeg`);
    } else {
      formData.append('file', fileData, `.jpg`);
    }

    this.paymentService.uploadImage(formData).subscribe(
      (res: HttpResponse<any>) => {
        if (fileType === 'workOrder') {
          this.tenderMapping.tenderMapping.fileUpload = res.body.fileName;
          this.uFilename = this.tenderMapping.tenderMapping.fileUpload;
          this.isFileUploaded = true;
        } else if (fileType === 'tendor') {
          this.isFile = true;
          this.tenderMapping.tenderMapping.fileUploadTendor = res.body.fileName;
        }

        this.notificationService.alertSuccess('Uploaded Successfully', '');
      },
      () => {
        this.notificationService.alertError('Error!', '');
      }
    );

    // Call the backend endpoint to upload the file
    // Use HttpClient to make an HTTP POST request
  }

  vendorFilter($event?) {
    let filterquery = [];
    filterquery = [
      {
        key: 'activeFlag',
        operation: 'equals',
        value: 'YES',
      },
      {
        key: 'approve',
        operation: 'equals',
        value: 'YES',
      },
    ];
    if ($event) {
      let filter = new filterQuery();
      filter.key = 'name';
      filter.operation = 'contains';
      filter.value = $event.term.toUpperCase();
      filterquery.push(filter);
      this.commercialVendorService
        .filter({ size: 50, page: 0 }, filterquery)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.vendorList = res.body;
          },
          () => {}
        );
    } else {
      this.commercialVendorService
        .filter({ size: 50, page: 0 }, filterquery)
        .subscribe(
          (res: HttpResponse<any>) => {
            this.vendorList = res.body;
          },
          () => {}
        );
    }
  }

  onprojectid() {
    this.vendorFilter();
    this.filterProps = [];
    this.filterProps.push({
      key: 'activeFlag',
      operation: 'equals',
      value: 'YES',
    });
    this.filterProps.push({
      key: 'workId',
      operation: 'equals',
      value: this.tenderMapping.tenderMapping.workId,
    });
    this.projectService
      .filter({ size: 10, page: 0 }, this.filterProps)
      .subscribe(
        (res: HttpResponse<any>) => {
          if (res.body.length !== 0) {
            this.tenderMapping.tenderMapping.work = res.body[0];

            this.spinner.hide();
          } else {
            this.notificationService.alertError('WorkId Not Exists', '');
          }
        },
        () => {}
      );
  }

  popupValue() {
    if (!this.tenderMapping.tenderMapping.id) {
      this.tenderMapping.vendorMappings = [];
      this.selectedVendor.forEach((element) => {
        let subTender = new VendorMapping();
        subTender.commercialVendor = element;
        this.tenderMapping.vendorMappings.push(subTender);
      });
    }
    else{
      let temp = cloneDeep(this.tenderMapping.vendorMappings);
      this.tenderMapping.vendorMappings = [];
      this.selectedVendor.forEach((element) => {
        const vendorMapping = temp.find(item => item.commercialVendor.id === element.id);
        console.log('tender',vendorMapping);
        if(vendorMapping) {
          this.tenderMapping.vendorMappings.push(vendorMapping);
        }
        else{
          let subTender = new VendorMapping();
        subTender.commercialVendor = element;
        this.tenderMapping.vendorMappings.push(subTender);
        }
        
      });
    }
  }

  approveVendorMapping(id) {
    this.tenderPageService.approveVendorMapping(id).subscribe(
      (res: HttpResponse<any>) => {
        this.cancel();
      },
      (error) => {}
    );
  }

  rejectVendorMapping(id) {
    this.tenderPageService.rejectVendorMapping(id).subscribe(
      (res: HttpResponse<any>) => {
        this.cancel();
      },
      () => {}
    );
  }

  loadMoreItems(page: number) {
    const filter = [
      {
        key: 'activeFlag',
        operation: 'equals',
        value: 'YES',
      },
      {
        key: 'approve',
        operation: 'equals',
        value: 'YES',
      },
    ];
  
    const paginationOptions = { size: 10, page };
  
    return this.commercialVendorService.filter(paginationOptions, filter);
  }
  
    onScrollToEnd(){
      if (!this.loadingMore) {
        this.loadingMore = true;
        this.currentPage++;
  
        this.loadMoreItems(this.currentPage).subscribe(
          (res: HttpResponse<any>) => {
            const additionalItems = res.body;
  
            this.vendorList = this.vendorList.concat(additionalItems);
  
            this.loadingMore = false;
          },
          () => {
            this.loadingMore = false;
          }
        );
      }
    }
}
