<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="department.list.name">Department List</h5>
                    <!-- <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger"
                        (click)="deleteSelectedAgencys()"
                        [disabled]="!selectedAgencys || !selectedAgencys.length"></button> -->
                </ng-template>
                <ng-template pTemplate="right">
                    <button *ngIf="this.searchResult.total === 0" pButton pRipple icon="pi pi-plus" class="p-button-secondary custom-button  new-button" (click)="openNew()" styleClass="button">
            <span class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.newbutton"></span>
          </button>
                    <!-- <p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Import"
                        chooseLabel="Import" class="p-mr-2 p-d-inline-block"></p-fileUpload>
                    <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help"></button> -->
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="row">
        <div #customerTable id="entities" class="col-12 px-4">
            <custom-filter-table [columnsValues]="cols" [filterSearchResult]="searchResult" [disableDeleteIcon]="true" [disableEditIcon]="false" [fileName]="'Department'" [enableViewIcon]="true" (emitRow)="onSelectRow($event)"></custom-filter-table>
        </div>
    </div>
    <p-dialog #dialog [(visible)]="departmentListDialog" [modal]="true" (onHide)="hideDialog()" styleClass="ib-dialog" [style]="{ width: '850px' }">
        <p-header [ngClass]="fontService.headingClass">
            {{"department.add.department.name" | translate }} </p-header>
        <ng-template pTemplate="content">
            <div class="col-12">
                <div class="form-inner-panel">
                    <div class="row gy-2">
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="department" [ngClass]="fontService.labelClass" jhiTranslate="department.department.name">Department</label>
                            <input type="text" class="form-control" [(ngModel)]="departmentList.name" maxlength="100" oninput="this.value = this.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                name="department" autocomplete="off" id="department" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !departmentList.name }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="shortName" [ngClass]="fontService.labelClass" jhiTranslate="department.short.name.name">Short Name</label>
                            <input type="text" class="form-control" [(ngModel)]="departmentList.shortName" name="shortName" autocomplete="off" id="shortName" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !departmentList.shortName }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="departCode" [ngClass]="fontService.labelClass" jhiTranslate="department.department.code.name">Department Code</label>
                            <input type="text" class="form-control" [(ngModel)]="departmentList.code" maxlength="20" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                name="departCode" autocomplete="off" id="departCode" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !departmentList.code }" />
                        </div>
                        <div class="col-sm-6 col-md-4">
                            <label class="form-control-label req" for="branchCode" [ngClass]="fontService.labelClass" jhiTranslate="department.branch.code.name">Branch Code</label>
                            <input type="text" class="form-control" [(ngModel)]="departmentList.branch" maxlength="100" oninput="this.value = this.value.replace(/[^A-Za-z0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform:uppercase"
                                name="branchCode" autocomplete="off" id="branchCode" [disabled]="viewOnly" [ngClass]="{ 'is-invalid': ngSelectInvalid && !departmentList.branch }" />
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" class="p-button-text custom-button" (click)="hideDialog()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.cancelbutton"></span></button>
            <button *ngIf="!viewOnly" pButton pRipple icon="pi pi-check" class="p-button-text custom-button" (click)="saveData()"><span
          class="button-label" [ngClass]="fontService.buttonClass" jhiTranslate="common.savebutton"></span></button>
        </ng-template>
    </p-dialog>
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
</div>