<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <p-toolbar styleClass="toolbar">
                <ng-template pTemplate="left">
                    <h5 [ngClass]="fontService.headingClass" jhiTranslate="payments.work.payment.voucher.name">
                        Work Payment Voucher
                    </h5>
                </ng-template>
            </p-toolbar>
        </div>
    </div>
    <div class="container-fluid pb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col">
                        <div class="card p-3">
                            <div class="row voucherfont">
                                <h6 [ngClass]="fontService.headingClass" jhiTranslate="payments.add.voucher.generation.name">
                                    Add Voucher Generation
                                </h6>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.voucher.number.name" | translate }}:<br />{{ voucherGenerationDto?.vendorTransactionDto?.voucherNo }}
                                    </p>
                                </div>
                                <div class="col-lg-1 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "date.name" | translate }}:<br />{{ date | date:'dd/MM/yyyy'}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "implementing.agency.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.name }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.gst.number.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.gstNo }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.department.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.departmentDto.name }}
                                    </p>
                                </div>

                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.scheme.name" | translate }}:<br />{{ this.voucherGenerationDto.vendorTransactionDto .implementingAgencyDto.schemeDto.name }}
                                    </p>
                                </div>

                            </div>
                            <div class="row voucherfont">
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.level.type.name" | translate }}: {{ levelMasterDto.name }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4">
                                    <p [ngClass]="fontService.regularBoldClass" *ngIf="voucherGenerationDto && voucherGenerationDto.vendorTransactionDto.project">
                                        Fin Year: {{this.voucherGenerationDto.vendorTransactionDto.project.finYear.name}}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4" *ngIf="this.voucherGenerationDto.vendorTransactionDto.project">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.districtAllocation.amount" | translate }}: {{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.programmeAllocationAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4" *ngIf="this.voucherGenerationDto.vendorTransactionDto.project">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.utilized.amount.name" | translate }}: {{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.programmeUtilizedAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4" *ngIf="this.voucherGenerationDto.vendorTransactionDto.project">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        {{ "payments.fundAuthorization.amount" | translate }}: {{ this.voucherGenerationDto?.vendorTransactionDto .iaComponentLimitDto?.programmeFundAuthorizationAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>
                                <div class="col-lg-2 col-md-6 col-sm-4" *ngIf="this.voucherGenerationDto.vendorTransactionDto.project">
                                    <p [ngClass]="fontService.regularBoldClass">
                                        Work Amount: {{ this.voucherGenerationDto.vendorTransactionDto.project.totalCost| currency:'INR':'':'1.2-2':'en-IN' }}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row gy-2">
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label" for="workId" [ngClass]="fontService.labelClass">Work
                                Id</label>
                            <input type="text" class="form-control" autocomplete="off" maxlength="15" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.workId"
                                [disabled]="!isEditable" [ngClass]="{'is-invalid':ngSelectInvalid && !voucherGenerationDto.vendorTransactionDto.workId}" name="workId" [readonly]="this.voucherGenerationDto.vendorTransactionDto.id" id="workId" (change)="loadProjectData()"
                            />
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Name of
                                the work</label>
                            <p [ngClass]="fontService.regularBoldClass" *ngIf="voucherGenerationDto && voucherGenerationDto.vendorTransactionDto.project">
                                {{this.voucherGenerationDto.vendorTransactionDto.project.projectName}}</p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Work
                                Type</label>
                            <p [ngClass]="fontService.regularBoldClass" *ngIf="voucherGenerationDto && voucherGenerationDto.vendorTransactionDto.project">
                                {{this.voucherGenerationDto.vendorTransactionDto?.project?.workType.workCategoryName}}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Work
                                Category</label>
                            <p [ngClass]="fontService.regularBoldClass" *ngIf="voucherGenerationDto && voucherGenerationDto.vendorTransactionDto.project">
                                {{this.voucherGenerationDto.vendorTransactionDto?.project?.workCategory.workCategoryName}}
                            </p>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <label class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Zonal
                                User</label>
                            <p *ngIf="voucherGenerationDto && voucherGenerationDto.vendorTransactionDto.project">
                                {{this.voucherGenerationDto.vendorTransactionDto?.project?.zonalUser}}</p>
                        </div>

                        <div class="col-lg-3 col-md-6" *ngIf="voucherGenerationDto.vendorTransactionDto.workId && this.finalPayment=='No'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Component
                                Type</label>
                            <ng-select id="scheme" [hideSelected]="true" [(ngModel)]="grantlevel" [items]="grantLevel" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" [disabled]="readonly || !isEditable" class="form-control dropdown-width" (change)="ongrantlevel()"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !grantlevel }">
                            </ng-select>
                        </div>
                        <div class="col-lg-3 col-md-6" *ngIf="voucherGenerationDto.vendorTransactionDto.workId && grantlevel && this.finalPayment=='No'">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass">Component</label>
                            <ng-select id="scheme" [hideSelected]="true" [(ngModel)]="componentMaster" [items]="componentMasterList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [clearable]="true" (change)="vendorFilter()" [disabled]="readonly || !isEditable" class="form-control dropdown-width"
                                [ngClass]="{ 'is-invalid': ngSelectInvalid && !componentMaster }">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="componentMaster" class="row g-0">
            <div class="col-lg-5">
                <div class="card p-3 invoice-card">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.vendor.name">Vendor Name</label>
                            <ng-select id="scheme" [disabled]="readonly || !isEditable" [(ngModel)]="selectedVendor" [items]="vendorList" bindLabel="name" appearance="outline" [searchable]="true" [closeOnSelect]="true" [clearable]="false" (search)="vendorFilter($event)" (change)="popupValue()"
                                class="form-control dropdown-width" [ngClass]="{'is-invalid': ngSelectInvalid && !selectedVendor.name}">
                            </ng-select>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">Proceedings Number
                            </label>
                            <input type="text" [disabled]="!isEditable" class="form-control" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" maxlength="15" [(ngModel)]="
                voucherGenerationDto.vendorTransactionDto
                  .paymentApprovalNumber
              " [ngClass]="{
                'is-invalid':
                  ngSelectInvalid &&
                  !voucherGenerationDto.vendorTransactionDto
                    .paymentApprovalNumber
              }" name="name" [readonly]="readonly" id="levelType" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "payments.vendor.id" | translate }}:<br />{{ selectedVendor.id }}
                            </p>
                        </div>

                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "payments.vendor.gst" | translate }}:<br />{{ selectedVendor?.gstNo }}
                            </p>
                        </div>
                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                {{ "payments.vendor.pan" | translate }}:<br />{{ selectedVendor?.panNo }}
                            </p>
                        </div>
                        <div class="col-md-3">
                            <p [ngClass]="fontService.regularBoldClass">
                                Vendor AccountNo:<br />{{ selectedVendor?.accountNumber}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-"></div> -->
                        <div *ngIf="selectedVendor.name" class="col-md-5">
                            <div class="" [ngClass]="fontService.labelClass">
                                Work Transactions
                            </div>
                            <button pButton pRipple (click)="viewClick()" class="p-button-success custom-button" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    jhiTranslate="common.view"></span>
                            </button>
                        </div>
                        <!-- <div *ngIf="enableZonalButton" class="col-md-6">
            <label
              class="form-control-label req"
              for="scheme"
              [ngClass]="fontService.labelClass"
              >Zonal User</label
            >
            <ng-select
              id="scheme"
              [disabled]="readonly || !isEditable"
              [(ngModel)]="selectedZonalUser"
              [items]="zonalList"
              bindLabel="userName"
              appearance="outline"
              [searchable]="true"
              [closeOnSelect]="true"
              [clearable]="false"
              class="form-control dropdown-width"
             
            >
            </ng-select>
          </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="card p-3 invoice-card">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-7">
                                    <label *ngIf="workPayment" class="form-control-label" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.invoice.number">Mbook
                                        Number</label>
                                    <label *ngIf="!workPayment" class="form-control-label" for="scheme" [ngClass]="fontService.labelClass">Invoice Number</label>
                                    <input type="text" [disabled]="!isEditable" class="form-control" autocomplete="off" oninput="this.value = this.value.replace(/[^A-Za-z0-9-_]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();" style="text-transform: uppercase"
                                        maxlength="30" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.invoiceNo
                  " name="name" [readonly]="readonly" id="levelType" />
                                </div>
                                <div class="col-lg-5">
                                    <label *ngIf="workPayment" class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" for="dateOfBirth" [ngClass]="fontService.labelClass" jhiTranslate="payments.invoice.date">Bill Date</label>
                                    <label *ngIf="!workPayment" class="form-control-label pt-lg-0 pt-sm-2 pt-xs-2" for="dateOfBirth" [ngClass]="fontService.labelClass">Invoice Date</label><br />
                                    <p-calendar [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.invoiceDate
                  " [maxDate]="maxDate" [disabled]="readonly || !isEditable" styleClass="datepicker"></p-calendar>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <label *ngIf="workPayment" class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.name.of.work.name">Nature Of Work</label><br />
                                    <label *ngIf="!workPayment" class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="schemeDescription" [ngClass]="fontService.labelClass">Description</label><br />
                                    <textarea id="w3review" [disabled]="!isEditable" [readonly]="readonly" name="w3review" rows="3" [(ngModel)]="
                    voucherGenerationDto.vendorTransactionDto.nameOfWork
                  " [ngClass]="{
                    'is-invalid':
                      ngSelectInvalid &&
                      !voucherGenerationDto.vendorTransactionDto.nameOfWork
                  }" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <label *ngIf="workPayment" class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.invoice.upload.invoice.name">Contract
                                Certificate</label>
                            <label *ngIf="!workPayment" class="form-control-label pt-md-0 pt-sm-2 pt-xs-2 req" for="scheme" [ngClass]="fontService.labelClass">File Upload</label>
                            <div>
                                <small class="mb-2" *ngIf="!readonly && isEditable" [ngClass]="fontService.regularClass" jhiTranslate="funds.note">Note : Allowed file types are jpg, jpeg, png, pdf and
                                    Maximum file size should be 1MB</small>
                            </div>
                            <p-fileUpload *ngIf="!readonly" (onUpload)="onUpload($event)" [customUpload]="true" [disabled]="isFileUploaded || !isEditable" (uploadHandler)="onUpload($event)" auto="true" multiple="false" [showUploadButton]="false" [showCancelButton]="false" chooseLabel="{{'common.upload'|translate}}">
                            </p-fileUpload>
                            <div class="row mt-2" *ngIf="!isFileUploaded && ngSelectInvalid">
                                <small class="text-danger">Upload the Certificate</small>
                            </div>
                            <div class="row mt-2" *ngIf="isFileUploaded">
                                <div class="col">
                                    <a [ngClass]="fontService.regularClass" class="cursor-pointer" (click)="viewDocument()">{{uFilename}}</a>
                                    <i class="pi pi-times add-margin" *ngIf="!readonly && isEditable" (click)="removeDocument()"></i>
                                    <i class="pi pi-download add-margin" (click)="downloadDocument()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="selectedVendor.name" class="row">
            <div class="col-md-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-md-12 mt-2" *ngIf="selectedVendor.name">
                            <p-table [value]="voucherGenerationDto.voucherBillDescDtoList" [resizableColumns]="true" responsiveLayout="scroll">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th [ngClass]="fontService.labelClass">
                                            {{ "payments.table.sno" | translate }}
                                        </th>
                                        <th *ngIf="workPayment" class="req" [ngClass]="fontService.labelClass">
                                            {{ "payments.table.billType" | translate }}
                                        </th>
                                        <th *ngIf="isPartial && workPayment">Part No</th>
                                        <th [ngClass]="fontService.labelClass">
                                            Expenditure
                                        </th>
                                        <th [ngClass]="fontService.labelClass">
                                            Balance
                                        </th>
                                        <th class="req" [ngClass]="fontService.labelClass">
                                            Current Bill Value<br />
                                            <span class="italic">({{ "payments.table.exclGst" | translate }})</span>
                                        </th>
                                        <th *ngIf="enableStateGst" [ngClass]="fontService.labelClass">
                                            {{ "payments.table.gst" | translate }}
                                        </th>
                                        <th *ngIf="workPayment" [ngClass]="fontService.labelClass">LWF%
                                        </th>
                                        <th [ngClass]="fontService.labelClass">
                                            {{ "payments.table.grossTotal" | translate }}
                                        </th>
                                        <!-- <th [ngClass]="fontService.labelClass">
                  {{ "payments.table.delete" | translate }}
                </th> -->
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-list let-index="rowIndex">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <td *ngIf="workPayment">
                                            <ng-select [appendTo]="'body'" id="scheme" [items]="paymentTypeList" bindLabel="name" appearance="outline" [closeOnSelect]="true" [disabled]="readonly || !isEditable" class="form-control" [(ngModel)]="list.paymentType" (change)="onPaymentTypeChange(list)"
                                                [clearable]="false" appendTo="body" [ngClass]="{ 'is-invalid': ngSelectInvalid && !list.paymentType }">
                                            </ng-select>
                                        </td>
                                        <td *ngIf="isPartial && workPayment">
                                            <input type="text" class="form-control" id="partCount" [disabled]="true" [(ngModel)]="partialCount" [readonly]="true" />
                                        </td>
                                        <td>
                                            <p-inputNumber id="amountPaid" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]=this.voucherGenerationDto.vendorTransactionDto.project.utilizedTotalCost [disabled]="true" maxlength="15"></p-inputNumber>
                                        </td>
                                        <td>
                                            <p-inputNumber id="balancePay" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="balancePay" [disabled]="true" maxlength="15"></p-inputNumber>
                                        </td>
                                        <td>
                                            <p-inputNumber id="field_price" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="list.amount" (ngModelChange)="calculatetotal(list)" [readonly]="readonly" [disabled]="!isEditable" maxlength="15" [ngClass]="{
                      'is-invalid': ngSelectInvalid && !list.amount
                    }"></p-inputNumber>
                                        </td>

                                        <td *ngIf="enableStateGst">
                                            <p-inputNumber id="field_price" [max]="28" [readonly]="readonly" [disabled]="!isEditable" [(ngModel)]="list.gstPercentage" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" (ngModelChange)="calculatetotal(list)"></p-inputNumber>
                                        </td>
                                        <td *ngIf="workPayment">
                                            <p-inputNumber id="lwf" [disabled]="true" [(ngModel)]="list.lwfPercentage" mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2"></p-inputNumber>
                                        </td>
                                        <td>
                                            <p-inputNumber type="text" [disabled]="!isEditable" inputId="locale-indian" mode="decimal" locale="en-IN" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" id="field_price" [(ngModel)]="list.grossAmount" [readonly]="true"></p-inputNumber>
                                        </td>
                                        <!-- <td>
                  <button 
                    pButton
                    pRipple
                    type="button"
                    [disabled]="readonly || !isEditable"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-text p-button-danger"
                    (click)="delete(index)"
                  ></button>
                </td> -->
                                        <td></td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div *ngIf="selectedVendor.name" class="row mt-3 tds">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-sm-4">
                                    <label class="form-control-label" for="schemeDescription" [ngClass]="fontService.labelClass">{{
                                        "payments.amount.select.IT.name" | translate }}</label><br />
                                    <p-radioButton [disabled]="readonly || !isEditable" name="option" [value]="true" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.tdsApplicable
                " label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton [disabled]="readonly || !isEditable" name="option" [value]="false" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.tdsApplicable
                " label="{{ 'payments.amount.no' | translate }}" (onClick)="
                  voucherGenerationDto.vendorTransactionDto.tdsPercentage = 0;
                  voucherGenerationDto.vendorTransactionDto.tdsAmount = 0;
                  calculateTotalAmount()
                "></p-radioButton>
                                </div>

                                <div class="col-sm-3" *ngIf="
                voucherGenerationDto?.vendorTransactionDto?.tdsApplicable
              ">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.IT%">IT%</label><br />
                                    <p-inputNumber class="full-width" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" id="field_price" [readonly]="readonly" [min]="0" [max]="28" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.tdsPercentage
                " mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" (ngModelChange)="calculateTds()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5" *ngIf="
                voucherGenerationDto?.vendorTransactionDto?.tdsApplicable
              ">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.IT.amount.name">IT Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.tdsAmount
                " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">{{ "payments.amount.select.gst.name" |
                                        translate }}</label><br />
                                    <p-radioButton name="option" [disabled]="readonly || !isEditable" [value]="true" [(ngModel)]="enableGstOnTds" (onClick)="
                  calculateTotalAmount()
                " label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton name="option" [disabled]="readonly || !isEditable" [value]="false" [(ngModel)]="enableGstOnTds" label="{{ 'payments.amount.no' | translate }}" (onClick)="calculateTotalAmount()"></p-radioButton>
                                </div>
                                <div class="col-sm-3" *ngIf="enableGstOnTds">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.gstTds%">TDS on
                                        GST %</label><br />
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [min]="0" [max]="29" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.gstOnTds
                " mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" [readonly]="readonly" (ngModelChange)="calculateTotalAmount()" name="name" id="levelType"></p-inputNumber>
                                </div>
                                <div class="col-sm-5" *ngIf="enableGstOnTds">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass" jhiTranslate="payments.amount.gst.amount.name">TDS on GST Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount
                " name="name" id="levelType" [readonly]="true"></p-inputNumber>
                                </div>
                            </div>
                            <!-- <div class="row mt-2">
            <div class="col-sm-4">
              <label
                class="form-control-label pt-xs-2"
                for="schemeDescription"
                [ngClass]="fontService.labelClass"
                >{{
                  "payments.amount.select.labour.name" | translate
                }}</label
              ><br />
              <p-radioButton
                name="option"
                [disabled]="readonly || !isEditable"
                [value]="true"
                [(ngModel)]="enableLabourChess"
                (onClick)="
                  calculateTotalAmount()
                "
                label="{{ 'payments.amount.yes' | translate }}"
              ></p-radioButton>
              <p-radioButton
                name="option"
                [disabled]="readonly || !isEditable"
                [value]="false"
                [(ngModel)]="enableLabourChess"
                label="{{ 'payments.amount.no' | translate }}"
                (onClick)="voucherGenerationDto.vendorTransactionDto.labourCessPercentage = 0;
                calculateTotalAmount()"
              ></p-radioButton>
            </div>

            <div class="col-sm-3" *ngIf="enableLabourChess">
              <label
                class="form-control-label pt-xs-2"
                for="scheme"
                [ngClass]="fontService.labelClass"
                jhiTranslate="payments.amount.labour.percentage.name"
              ></label
              ><br />
              <p-inputNumber
                class="full-width"
                [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto
                    .labourCessPercentage
                "
                mode="decimal"
                [minFractionDigits]="1" 
                [maxFractionDigits]="2"
                name="name"
                id="levelType"
                [max]="28"
                [disabled]="workPayment"
                [readonly]="readonly"
                (ngModelChange)="calculateTotalAmount()"
              ></p-inputNumber>
            </div>
            <div class="col-sm-5" *ngIf="enableLabourChess">
              <label
                class="form-control-label pt-xs-2"
                for="scheme"
                [ngClass]="fontService.labelClass"
                jhiTranslate="payments.amount.labour.amount.name"
              ></label><br>
              <p-inputNumber
              class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.labourCess" [readonly]="true"  name="name" 
                [disabled]="true" id="levelType"></p-inputNumber>
                    </div>
                </div> -->
                            <div class="row mt-2">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">Select Security Deposit Amount</label><br />
                                    <p-radioButton name="option" [value]="true" [disabled]="readonly || !isEditable" [(ngModel)]="enableSecurityDeposit" (onClick)="
                  calculateTotalAmount()
                " label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton name="option" [value]="false" [disabled]="readonly || !isEditable" [(ngModel)]="enableSecurityDeposit" label="{{ 'payments.amount.no' | translate }}" (onClick)="
                  voucherGenerationDto.vendorTransactionDto.securityDeposit = 0;
                  calculateTotalAmount()
                "></p-radioButton>
                                </div>

                                <div class="col-sm-3" *ngIf="enableSecurityDeposit">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass"> Security
                                        Deposit Percentage</label><br />
                                    <p-inputNumber class="full-width" [max]="28" [readonly]="readonly" [disabled]="workPayment" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto.securityDeposit
                " mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" name="name" id="levelType" (ngModelChange)="calculateTotalAmount()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5" *ngIf="enableSecurityDeposit">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass">Security
                                        Deposit Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" locale="en-IN" [(ngModel)]="
                  voucherGenerationDto.vendorTransactionDto
                    .securityDepositAmount" name="name" id="levelType" [disabled]="true" [readonly]="readonly"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="workPayment">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">Select Labour Cess</label><br />
                                    <p-radioButton name="option" [value]="true" [disabled]="readonly || !isEditable || emdCharge" [(ngModel)]="enableMaterialSupply" (onClick)="
                calculateTotalAmount()
              " label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton name="option" [value]="false" [disabled]="readonly || !isEditable || emdCharge" [(ngModel)]="enableMaterialSupply" label="{{ 'payments.amount.no' | translate }}" (onClick)="
                voucherGenerationDto.vendorTransactionDto.materialSupplyAmount = 0;
                calculateTotalAmount()
              "></p-radioButton>
                                </div>
                                <div class="col-sm-3" *ngIf="enableMaterialSupply">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass">Labour Cess
                                        Percentage</label><br />
                                    <p-inputNumber class="full-width" [(ngModel)]="
                voucherGenerationDto.vendorTransactionDto
                  .materialSupplyPercentage
              " mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" name="name" id="levelType" [max]="28" [disabled]="workPayment" [readonly]="readonly" (ngModelChange)="calculateTotalAmount()"></p-inputNumber>
                                </div>
                                <div class="col-sm-5" *ngIf="enableMaterialSupply">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass">Labour Cess
                                        Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" locale="en-IN" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.materialSupplyAmount" [readonly]="readonly" (ngModelChange)="calculateTotalAmount()"
                                        name="name" id="levelType" [disabled]="workPayment"></p-inputNumber>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="workPayment">
                                <div class="col-sm-4">
                                    <label class="form-control-label pt-xs-2" for="schemeDescription" [ngClass]="fontService.labelClass">Miscellaneous</label><br />
                                    <p-radioButton name="option" [value]="true" [disabled]="readonly || !isEditable" [(ngModel)]="enableMiscellaneous" (onClick)="
              calculateTotalAmount()
            " label="{{ 'payments.amount.yes' | translate }}"></p-radioButton>
                                    <p-radioButton name="option" [value]="false" [disabled]="readonly || !isEditable" [(ngModel)]="enableMiscellaneous" label="{{ 'payments.amount.no' | translate }}" (onClick)="
              voucherGenerationDto.vendorTransactionDto.miscellaneousAmount = 0;
              calculateTotalAmount()
            "></p-radioButton>
                                </div>

                                <div class="col-sm-5" *ngIf="enableMiscellaneous">
                                    <label class="form-control-label pt-xs-2" for="scheme" [ngClass]="fontService.labelClass">Miscellaneous Amount</label><br>
                                    <p-inputNumber class="full-width" inputId="locale-indian" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" locale="en-IN" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.miscellaneousAmount" (ngModelChange)="calculateTotalAmount()"
                                        [readonly]="readonly" name="name" id="levelType"></p-inputNumber>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 pt-lg-0 pt-xs-2 pt-sm-2">
                            <label class="form-control-label req" for="schemeDescription" [ngClass]="fontService.labelClass" jhiTranslate="payments.remarks.name">Remarks</label><br />
                            <textarea id="w3review" name="w3review" rows="3" [disabled]="!isEditable" [(ngModel)]="voucherGenerationDto.vendorTransactionDto.remark" [readonly]="
              enableSignButton ||
              voucherGenerationDto.vendorTransactionDto.signStatus
            " cols="100" maxlength="150" [ngClass]="{
              'is-invalid':
                ngSelectInvalid &&
                !voucherGenerationDto.vendorTransactionDto.remark
            }" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();">
          </textarea>
                        </div>

                        <div class="col-lg-3 pt-lg-0 pt-xs-2 pt-sm-2">
                            <table class="snippet">
                                <tr *ngIf="
                voucherGenerationDto.vendorTransactionDto.grossAmount !== 0
              ">
                                    <td [ngClass]="fontService.labelClass">
                                        Work Cost
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.grossAmount | currency:'INR':'':'1.2-2':'en-IN' || "Nill" }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                voucherGenerationDto.vendorTransactionDto.netAmount !== 0 
              ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.total.amount.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.netAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                voucherGenerationDto.vendorTransactionDto.tdsAmount !== 0
              ">
                                    <td [ngClass]="fontService.labelClass">IT</td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.tdsAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="
                voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount !==
                0
              ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.gst.amount.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto.gstOnTdsAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <!-- <tr
              *ngIf="
                voucherGenerationDto.vendorTransactionDto.labourCess !== 0
              "
            >
              <td [ngClass]="fontService.labelClass">
                {{ "payments.amount.labour.cess.name" | translate }}
              </td>
              <td style="width: 110px; text-align: right">
                {{
                  voucherGenerationDto.vendorTransactionDto.labourCess
                  | currency:'INR':'':'1.2-2':'en-IN'
                }}
              </td>
            </tr> -->
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto
                  .securityDepositAmount !== 0 && isPartial">
                                    <td [ngClass]="fontService.labelClass">
                                        Security Deposit Amount
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto .securityDepositAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf=" voucherGenerationDto.vendorTransactionDto
            .materialSupplyAmount !== 0">
                                    <td [ngClass]="fontService.labelClass">
                                        Labour Cess Amount
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto .materialSupplyAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf=" voucherGenerationDto.vendorTransactionDto
            .miscellaneousAmount !== 0">
                                    <td [ngClass]="fontService.labelClass">
                                        Miscellaneous Amount
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto .miscellaneousAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <!-- <tr
            *ngIf="totWithheldAmount!== 0 && !isPartial && workPayment" style="color:gray">
            <td [ngClass]="fontService.labelClass">
              Total withheld Amount
            </td>
            <td style="width: 110px; text-align: right">
              {{this.totWithheldAmount | currency:'INR':'':'1.2-2':'en-IN' }}
            </td>
          </tr> -->
                                <tr *ngIf="voucherGenerationDto.vendorTransactionDto
            .cumulativeAmount!== 0 && !isPartial && workPayment">
                                    <td [ngClass]="fontService.labelClass">
                                        Cumulative Security Deposit Amount
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{voucherGenerationDto.vendorTransactionDto .cumulativeAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="totalDeduction !== 0">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.total.deduction.amount.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ totalDeduction | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <tr *ngIf="totWithheldAmount!== 0 && !isPartial && workPayment">
                                    <td [ngClass]="fontService.labelClass">
                                        Recovered Security Deposit Amount
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{this.totWithheldAmount| currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>

                                <tr *ngIf="
                voucherGenerationDto.vendorTransactionDto
                  .transactionAmount !== 0
              ">
                                    <td [ngClass]="fontService.labelClass">
                                        {{ "payments.amount.transaction.name" | translate }}
                                    </td>
                                    <td style="width: 110px; text-align: right">
                                        {{ voucherGenerationDto.vendorTransactionDto .transactionAmount | currency:'INR':'':'1.2-2':'en-IN' }}
                                    </td>
                                </tr>
                                <!--   <div class="row " *ngIf="!readonly">
                                <div class="col-sm-3 generate mt-3">
                                
                                </div>
                                    <button pButton pRipple label="Cancel" class="p-button-danger"></button>
                            </div> -->
                            </table>
                        </div>
                    </div>
                    <p-toolbar *ngIf="selectedVendor.name && !enableRevertButton && isEditable">
                        <div class="p-toolbar-group-start"></div>
                        <div class="p-toolbar-group-end">
                            <button *ngIf="!enableSignButton && !readonly && isEditable" pButton pRipple class="p-button-success custom-button margin" (click)="genVocher()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    jhiTranslate="common.generatevoucher"></span>
                            </button>
                            <button *ngIf="enableSignButton" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    >{{signButtonName}}</span>
                            </button>
                        </div>
                    </p-toolbar>

                    <p-toolbar *ngIf="selectedVendor.name && enableRevertButton && isEditable">
                        <div class="p-toolbar-group-start"></div>
                        <button *ngIf="enableSignButton && !voucherGenerationDto.vendorTransactionDto.signaturePresident" pButton pRipple class="p-button-success custom-button margin" (click)="submitForm()" styleClass="button">
                            <span class="button-label" [ngClass]="fontService.buttonClass"
                                >{{signButtonName}}</span>
                        </button>
                        <div *ngIf="
              (!enableSignButton &&(
              !voucherGenerationDto?.vendorTransactionDto?.signStatus || !voucherGenerationDto.vendorTransactionDto.signaturePresident) &&  !voucherGenerationDto.vendorTransactionDto.signaturePresident)
            " class="p-toolbar-group-end">
                            <button pButton pRipple (click)="revert()" class="p-button-warning custom-button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    jhiTranslate="common.revertbutton"></span>
                            </button>
                            <button *ngIf="!voucherGenerationDto.vendorTransactionDto.signStatus && !voucherGenerationDto?.vendorTransactionDto?.revertStatus && enableApproved && isEditable" pButton pRipple (click)="genVocher()" class="p-button-success custom-button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    jhiTranslate="common.approvebutton"></span>
                            </button>
                            <button *ngIf="isEditable" pButton pRipple (click)="reasonDialog=true" class="p-button-danger custom-button">
                                <span class="button-label" [ngClass]="fontService.buttonClass"
                                    jhiTranslate="common.rejectbutton"></span>
                            </button>
                        </div>
                        <button *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident" pButton pRipple label="Download Pdf" (click)="getPdf()" class="p-button-success"></button>
                        <!-- <button
          *ngIf="voucherGenerationDto.vendorTransactionDto.signaturePresident && !enableRePaymentButton && !disablepaymentButton && !checkstatusButton && isEditable"
          pButton
          pRipple
          [label]="buttonText"
          (click)="PaymentSucess()"
          class="p-button-success"
        ></button> -->
                    </p-toolbar>
                </div>
            </div>
        </div>
        <p-dialog [(visible)]="profileDialog" [style]="{ width: '1000px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <p-header [ngClass]="fontService.headingClass">
                Work Transactions
            </p-header>

            <p-table *ngIf="!noRecord" [value]="searchResult.items" [resizableColumns]="true" styleClass="dynamic-table p-datatable-gridlines" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th [ngClass]="fontService.labelClass">
                            {{ "payments.viewTable.voucherNo" | translate }}
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            Work Name
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            {{ "payments.viewTable.vendorName" | translate }}
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            {{ "payments.viewTable.vendorAccount" | translate }}
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            Component Name
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            Payment Type
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            Payment Part Number
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            Gross Amount
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            {{ "payments.viewTable.totTransAmount" | translate }}
                        </th>
                        <th [ngClass]="fontService.labelClass">
                            {{ "payments.viewTable.transactionDate" | translate }}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-index="rowIndex">
                    <tr>
                        <td>{{ data.voucherNo }}</td>
                        <td>{{ data.projectName }}</td>
                        <td>{{ data.vendorName }}</td>
                        <td>{{ data.accountNumber }}</td>
                        <td>{{ data.componentName }}</td>
                        <td>{{ data.paymentType }}</td>
                        <td>{{ data.partCount }}</td>
                        <td>{{ data.grossAmount | currency:'INR':'':'1.2-2':'en-IN'}}</td>
                        <td>{{ data.transactionAmount | currency:'INR':'':'1.2-2':'en-IN'}}</td>
                        <td>{{ data.transactionDate | date : "shortDate" }}</td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="noRecord">
                <h5 class="text-center" [ngClass]="fontService.regularClass" jhiTranslate="error.norecord">No transactions found
                </h5>
            </div>
        </p-dialog>
        <p-dialog [(visible)]="paymentSucessDialog" [style]="{ width: '500px', height: '225px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <p-header [ngClass]="fontService.headingClass">
                Payment Alert Box
            </p-header>
            <h1 style="text-align: center;font-size: 18px;">{{ paymentSucessText }}</h1>
            <ng-template pTemplate="footer">
                <button pButton pRipple class="p-button-text custom-button" (click)="paymentSucessDialog = false">
                    <span class="button-label" [ngClass]="fontService.buttonClass" label="Ok"
                        (click)="redirect()">Ok</span>
                </button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="viewDoc" [style]="{ width: '1000px', height: '800px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <ng-template pTemplate="body">
                <div style='position: relative; height: 100%;'>
                    <ngx-extended-pdf-viewer *ngIf="vFileExt === 'pdf'" [src]="vFileUrl"></ngx-extended-pdf-viewer>
                    <img *ngIf="vFileExt !== 'pdf'" [src]="vFileUrl" style="display: block;" height="800px" />
                </div>
            </ng-template>
        </p-dialog>
        <p-dialog [(visible)]="reasonDialog" [style]="{ width: '500px', height: '350px' }" [modal]="true" [baseZIndex]="1000" styleClass="ib-dialog">
            <p-header [ngClass]="fontService.headingClass">
                Reject Dialog:
            </p-header>
            <div class="form-group mb-4">
                <label class="form-control-label req" for="reason" [ngClass]="fontService.labelClass">Reject
                    Reason</label><br />
                <textarea id="reason" name="reason" rows="3" [(ngModel)]="rejectionReason" cols="100" maxlength="150" oninput="this.value = this.value.replace(/[^A-Za-z0-9 ,-.]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"></textarea>
                <div *ngIf="requiredError" class="mt-2">
                    <small class="text-danger">please mention the reason for reject</small>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple class="p-button-danger">
                    <span class="button-label" [ngClass]="fontService.buttonClass" label="Reject"
                        (click)="rejectDialog()">Reject</span>
                </button>
            </ng-template>

        </p-dialog>