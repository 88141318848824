import { StatutoryAccountDetail, StatutoryDeductionType } from "src/app/module/statutory-account-config/statutory-account-model";
import { ImplementingAgencyDto } from "../payment-voucher/payment-voucher.model";
import { LevelMaster } from "src/app/module/level-master/level-master";
import { FinYear } from "src/app/module/limit-translist/limit-translist.module";
import { CommercialVendorsDto } from "src/app/module/commercial-vendors/commercial-vendors";

export class StatutoryVoucherGeneration {
    statutoryTransactionDto:StatutoryTransaction;
    statutoryBillDescription:StatutoryBillDescription[];
  }
  
  export class StatutoryTransaction{
    id:number;
    transactionAmount:number;
    totalAmount:number;
    deductionMonth:string;
    deductionType:StatutoryDeductionType;
    statutoryAccountDetail:StatutoryAccountDetail;
    implementingAgency:ImplementingAgencyDto;
    levelMaster:LevelMaster;
    finYear:FinYear;
    levelOneSign:string;
    levelTwoSign:string;
    transactionStatus:string;
    transactionDate:Date;
    voucherNumber:string;
    year:number;
    signCount:number;
    signMessage:string;
    signStatus:boolean;
    rejectReason:string;
    utrNo:string;
    remarks:string;
    journalNumber:string;
    activeFlag:string;
    isEditable:boolean;
    createdBy:number;
    updatedBy:number;
    createdOn:Date;
    updatedOn:Date;
    checkTransactionStatus: boolean;
    reInitialize: boolean;
    workId:string;
    paymentType:string;
    vendor:CommercialVendorsDto;
  }
  
  export class StatutoryBillDescription{
    id:number;
    workId:string;
    statutoryTransactionId:number;
    statutoryDeductionType:StatutoryDeductionType;
    deductionAmount:number;
    deductionPercentage:number;
    voucherNumber:string;
    vendorName:string;
    vendorGstNumber:string;
    vendorPanNumber:string;
    activeFlag:string;
    createdBy:number;
    updatedBy:number;
    createdOn:Date;
    updatedOn:Date;
    isDefault: boolean;
  }
  export class StatutoryDeductionDto{
    statutoryDeductionListDtos:StatutoryDeductionList;
    totalAmount:number;
  }

  export class StatutoryDeductionList{
  vendorName:string;
  vendorGstNumber:string;
  vendorPanNumber:string;
  voucherNumber:string;
  deductionPercentage:number;
  deductionAmount:number;	
  }