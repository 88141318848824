<div class="row bg">
    <div class="col-7 mx-auto login-form">
        <div class="text-center">
            <img style="width:45%" src="/./assets/img/indian-bank-logo 1.png" alt="Image">
        </div>
        <div class="row mt-4 gx-0 align-items-center">
            <div class="col-2" style="display:table">
                <img class="tn-logo" src="/./assets/img/ib-rdp-tng-logo.svg" alt="Image">
            </div>
            <div class="col-10 ">
                <p>அனைத்து கிராம அண்ணா மறுமலர்ச்சித் திட்டம் AGAMT-II (2023-2024)</p>
            </div>
        </div>
        <div class="font-single mb-4 mt-4">Login </div>
        <div class="login-content" *ngIf="!showOtp">
            <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
                <div class="form-group mb-4">
                    <input id="username" type="text" id="orangeForm-email" class="form-control full" formControlName="username" uppercase name="username" autocomplete="off" placeholder="USERNAME">
                </div>
                <div class="form-group mb-4 eye-container">
                    <input id="password" name="password" autocomplete="off" formControlName="password" id="orangeForm-pass" class="form-control full" placeholder="PASSWORD" [type]="hide ? 'password' : 'text'">
                    <i class="pi pi-eye eye" *ngIf="hide" (click)="hide=false"></i>
                    <i class="pi pi-eye-slash eye" *ngIf="!hide" (click)="hide=true"></i>
                </div>
                <div *ngIf="inValidUser" class="mb-4">
                    <small class="text-danger">Please enter valid username and password</small>
                </div>
                <div class="row  form-group mb-4">
                    <div class="col-6 text-center" *ngIf="spinner"> <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></div>
                    <div class="col-6" *ngIf="!spinner"><img class="img" [src]='image' /></div>
                    <div class="col-1 refresh">
                        <i class="pi pi-refresh" style="font-size: 1.2rem" (click)="loadCaptcha()"></i>
                    </div>
                    <div class="col-5"><input id="captcha" type="text" id="captcha" class="form-control full" formControlName="captcha" oninput="this.value = this.value.replace(/[^0-9A-Za-z]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" onblur="this.value =this.value.trim();"
                            placeholder="ENTER CAPTCHA">

                    </div>
                    <div *ngIf="inValidCaptcha" class="text-end mt-2">
                        <small class="text-danger">Incorrect Captcha.Please try again..</small>
                    </div>
                    <div *ngIf="captchaExpireError" class="text-end mt-2">
                        <small class="text-danger">{{captchaExpireError}}</small>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="col-6 text-center" *ngIf="btnSpinner"> <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></div>
                    <button *ngIf="!btnSpinner" type="submit" class="btn btn-color">Login</button>
                </div>
                <div class="footer-logo">
                    <button type="button" (click)="navToForgotPassword()" class="btn btn-link btn-sm">Forgot Password?</button>
                </div>
            </form>
        </div>
        <div class="login-content" *ngIf="showOtp">
            <form class="form" role="form" (ngSubmit)="loginOtp()" [formGroup]="otpForm">
                <div class="form-group mb-2">
                    <div class="mt-2 mb-2" style="background-color: lightgreen;padding: 1px;">
                        OTP has been sent to the Mobile Number: {{mobileNo}}
                    </div>
                    <input id="otp" name="otp" maxlength="6" autocomplete="off" formControlName="otp" type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1').replace(/\s{2,}/g, ' ');" (input)="validateOtp(otpInput.value)" #otpInput
                        class="form-control full" placeholder="ENTER OTP">
                    <div *ngIf="otpError && enableSigninButton" class="mt-2">
                        <small style="font-size: 30px;" class="text-danger">{{otpError}}</small>
                    </div>
                </div>
                <div class="mb-4">
                    Resend OTP in: {{ otpTimer }} seconds
                </div>
                <div class="row">
                    <div class="col">
                        <button *ngIf="enableSigninButton" type="submit" class="btn btn-secondary">Sign In</button>
                    </div>
                    <div class="col text-end">
                        <button type="button" [disabled]="!enableResendButton" (click)="getUserOtp('reSend')" class="btn btn-secondary">Resend OTP</button>
                    </div>
                </div>
                <div class="footer-logo mt-2">
                    <button type="button" (click)="navToLogin()" class="btn btn-link btn-sm">Back to Login</button>
                </div>
            </form>
        </div>
    </div>
</div>