import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LimitSetService } from './limit-set.service';
import { LimitSet } from './limit-set';
import { SearchResult } from "src/app/shared/models/search-result";
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Font } from 'chartjs-plugin-datalabels/types/options';
import { FontService } from 'src/app/shared/font-service/font.service';



@Component({
  selector: 'app-limit-set',
  templateUrl: './limit-set.component.html',
  styleUrls: ['./limit-set.component.scss']
})
export class LimitSetComponent implements OnInit  {

  limitSetDialog: boolean = false;
  cols: any[];
  searchResult: SearchResult<LimitSet> = new SearchResult<LimitSet>();
 

  limitSet?: LimitSet;

  selectedLimitSet: LimitSet[] = [];

  submitted?: boolean;

  selectedMapping: string;
  itemsPerPage: any =10;
  page: number;
  filterProps: any;


  constructor(private limitSetService: LimitSetService, 
	private messageService: MessageService, 
	private confirmationService: ConfirmationService, 
	private spinner:NgxSpinnerService,
	public fontService:FontService,
	private router:Router) { }

  ngOnInit() {
   this.cols = [
	{ field: 'levelType', header: 'Level Type' ,jhiTranslate: 'tableHead.levelType', width: 13, isSelectcolumn: true ,type:"dropDown",isFilterable: true},
	{
        field: 'levelMaster',
        header: 'Level Master',
        jhiTranslate: 'tableHead.levelMaster',
        width: 25,
        isSelectcolumn: true,
        type: 'dropDown',
        isFilterable: true,
      },
	// { field: 'levelMaster', header: 'Level Code',jhiTranslate: 'tableHead.lgdCode', width: 25, isSelectcolumn: true ,type: "dropDown_with_other_field",Dfield: "code",isFilterable: false},
	{ field: 'implementingAgency', header: 'IA Name', jhiTranslate: 'tableHead.iA.name', isSelectcolumn: true ,type:"dropDown",isFilterable: true},
	{ field: 'programmeUtilizedAmount', header: 'Programme Utilized Amount', width: 13, isSelectcolumn: true,type:'amount' },
	{ field: 'adminUtilizedAmount', header: 'Admin Utilized Amount', width: 13, isSelectcolumn: true,type:'amount' },
	{ field: 'programmeAllocationAmount', header: 'Programme Allocation Amount' , width: 13, isSelectcolumn: true, type:'amount' },
	{ field: 'programmeFundAuthorizationAmount', header: 'Programme Fund Authorization Amount' , width: 13, isSelectcolumn: true, type:'amount' },
	{ field: 'adminAllocationAmount', header: 'Admin Allocation Amount' , width: 13, isSelectcolumn: true, type:'amount' },
	{ field: 'adminFundAuthorizationAmount', header: 'Admin Fund Authorization Amount' , width: 13, isSelectcolumn: true, type:'amount' },
	{ field: 'finYear', header: 'Fin Year',jhiTranslate: 'iACompo.limitTrans.fin.year.name', width: 25, isSelectcolumn: true ,type:"dropDown",isFilterable: true},
  ];
  }

  loadPage(page?: number, dontNavigate?: boolean): void {
		this.spinner.show()
		const pageToLoad: number = page ?? this.page ?? 1;
	
		let filterQuery = {
		  page: pageToLoad - 1,
		  size: this.itemsPerPage,
		};
    this.filterProps = this.filterProps ?? []
		this.limitSetService
		  .filter({ ...filterQuery},this.filterProps)
		  .subscribe(
			(res: HttpResponse<LimitSet[]>) => {
			  this.onSuccess(res.body, res.headers, pageToLoad, !dontNavigate);
			},
			() => {
			  this.onError();
			}
		  );
	  }

	  protected onSuccess(
		data: LimitSet[]|null,
		headers: HttpHeaders,
		page: number,
		navigate: boolean
	  ): void {
		this.searchResult.total = Number(headers.get('X-Total-Count'));
		this.spinner.hide()
		this.page = page;
		if (navigate) {
		  this.router.navigate(["/limit-set"], {
			queryParams: {
			  page: this.page-1,
			  size: this.itemsPerPage,
			  
			},
		  });
		}
		this.searchResult.items = data ?? [];
		this.searchResult = { ...this.searchResult };
	  }
	
	  protected onError(): void {
	  }

	

	onLazyLoad(event) {
		this.filterProps = event.filterDefinitions
		this.itemsPerPage = event.size;
		this.loadPage(event.page);
	  }

  openNew() {
    this.submitted = false;
    this.limitSetDialog = true;
  }

  

  hideDialog() {
    this.limitSetDialog = false;
    this.submitted = false;
  }

  saveAgency() {
    this.submitted = true;
    this.limitSetDialog = false;

  }

  

 

}

