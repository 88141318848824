import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from 'src/app/auth/request/request-util';
import { environment } from 'src/environments/environment';
import { tender } from './vendor-mapping-model';


@Injectable({ providedIn: 'root' })
export class TenderPageService {
  protected resourceSaveUrl =
    environment.SERVER_API_URL + 'api/saveVendorMapping';
    protected resourceFilterUrl =
    environment.SERVER_API_URL + 'api/getTenderMapping';
    protected resourceEditUrl = 
    environment.SERVER_API_URL + 'api/getTenderAndVendorList';
    protected approveTendorMappingUrl = environment.SERVER_API_URL + 'api/approveTendorMapping';
    protected rejectTendorMappingUrl = environment.SERVER_API_URL + 'api/rejectTendorMapping';
   
  
  constructor(protected http: HttpClient) {}
    save(tender?: tender){
    return this.http.post(this.resourceSaveUrl, tender, {
      observe: 'response',
    });
  }

  getVendorMapping(id: number): Observable<HttpResponse<tender>> {
    return this.http.get<tender>(`${this.resourceEditUrl}?tenderMappingId=${id}`, {
      observe: 'response',
    });
  }

  filter(req?: any,filter?:any): Observable<any> {
    const options = createRequestOption(req);
      return this.http
      .get(this.resourceFilterUrl,{ params:options, observe: 'response' })
  }

  approveVendorMapping(id:number): Observable<any> {
    return this.http.post(`${this.approveTendorMappingUrl}?id=${id}`,null,{responseType: 'text'});
  }
  rejectVendorMapping(id:number): Observable<any> {
    return this.http.post(`${this.rejectTendorMappingUrl}?id=${id}`,null,{responseType: 'text'});
  }
  

}
