import { LimitTranslistComponent } from './module/limit-translist/limit-translist.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { DashboardComponent } from './dashBoards/dashboard/dashboard.component';
import { AccountSnaComponent } from './module/account-sna/account-sna.component';
import { AgencyComponent } from './module/agency/agency.component';
import { CommercialVendorsComponent } from './module/commercial-vendors/commercial-vendors.component';
import { ComponentTypeComponent } from './module/component-type/component-type.component';
import { DepartmentListComponent } from './module/department-list/department-list.component';
import { FundsTransactionComponent } from './module/funds-transaction/funds-transaction.component';
import { FundsComponent } from './module/funds/funds.component';
import { LevelMasterComponent } from './module/level-master/level-master.component';
import { LevelTypeComponent } from './module/level-type/level-type.component';
import { LimitSetComponent } from './module/limit-set/limit-set.component';
import { MajorComponentComponent } from './module/major-component/major-component.component';
import { MinorComponentComponent } from './module/minor-component/minor-component.component';
import { PersonalVendorsComponent } from './module/personal-vendors/personal-vendors.component';
import { ProfileComponent } from './module/profile/profile.component';
import { SchemeListComponent } from './module/scheme-list/scheme-list.component';
import { SubComponentComponent } from './module/sub-component/sub-component.component';
import { UserComponent } from './module/user/user.component';
import { PaymentVoucherComponent } from './payments/payment-voucher/payment-voucher.component';
import { PaymentModuleComponent } from './payments/payment-module/payment-module.component';
import { UserRouteAccessService } from './auth/user-route-access.service';
import { ErrorComponent } from './shared/error/error.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { EmployeeComponent } from './module/employee/employee.component';
import { LocalBodyContributionComponent } from './module/local-body-contribution/local-body-contribution.component';
import { ProjectComponent } from './project/project.component';
import { ComponentNameComponent } from './component-name/component-name.component';
import { DynamicReportTableComponent } from './dynamic-report-table/dynamic-report-table.component';
import { StatutoryPaymentComponent } from './payments/statutory-payment/statutory-payment.component';
import { VendorPaymentVoucherComponent } from './payments/vendor-payment-voucher/vendor-payment-voucher.component';
import { VendorPaymentModuleComponent } from './payments/vendor-payment-module/vendor-payment-module.component';
import { OpeningBalanceComponent } from './module/opening-balance/opening-balance.component';
import { DistrictDashboardComponent } from './dashBoards/dashboard/district-dashboard/district-dashboard.component';
import { StatutoryAccountConfigComponent } from './module/statutory-account-config/statutory-account-config.component';
import { StatutoryPaymentModuleComponent } from './payments/statutory-payment-module/statutory-payment-module.component';
import { StatutoryPaymentVoucherComponent } from './payments/statutory-payment-voucher/statutory-payment-voucher.component';
import { FailureTransactionComponent } from './payments/failure-transaction/failure-transaction.component';
import { DisputedPaymentListComponent } from './payments/disputed-payment-list/disputed-payment-list.component';
import { PaymentStatusComponent } from './drillDown-report/payment-status/payment-status.component';
import { VoucherStatusComponent } from './drillDown-report/voucher-status/voucher-status.component';
import { VendorReportComponent } from './drillDown-report/vendor-report/vendor-report.component';
import { LimitReportComponent } from './drillDown-report/limit-report/limit-report.component';
import { BlockDashboardComponent } from './dashBoards/block-dashboard/block-dashboard.component';
import { VendorMappingTableComponent } from './module/vendor-mapping-table/vendor-mapping-table.component';
import { VendorMappingPageComponent } from './module/vendor-mapping-page/vendor-mapping-page.component';
import { MiscellaneousPaymentTableComponent } from './payments/miscellaneous-payment-table/miscellaneous-payment-table.component';
import { MiscellaneousPaymentVoucherComponent } from './payments/miscellaneous-payment-voucher/miscellaneous-payment-voucher.component';
import { EBPaymentModuleComponent } from './payments/eb-payment-module/eb-payment-module.component';
import { EBPaymentVoucherComponent } from './payments/eb-payment-voucher/eb-payment-voucher.component';

const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "login",
          component: SigninComponent
        },
        {
          path: "",
          redirectTo: "login",
          pathMatch:"full"

        },
        {
          path: "ForgotPassword",
          component:ForgotPasswordComponent
        },
        {
          path: "ResetPassword",
          component:ResetPasswordComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "state-dashboard",
          component:DashboardComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "block-dashboard",
          component:BlockDashboardComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "district-dashboard",
          component:DistrictDashboardComponent,
          canActivate: [UserRouteAccessService] 
        },
        
        
        
        {
          path: "profile",
          component:ProfileComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "district-profile",
          component:ProfileComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "block-profile",
          component:ProfileComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "user",
          component:UserComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "block-user",
          component:UserComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "agency",
          component:AgencyComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "personal-vendors",
          component:PersonalVendorsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "commercial-vendors",
          component:CommercialVendorsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "employee",
          component:EmployeeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "account-sna",
          component:AccountSnaComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "department-list",
          component:DepartmentListComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "scheme-list",
          component:SchemeListComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "funds",
          component:FundsComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "opening-balance",
          component:OpeningBalanceComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "funds-transaction",
          component:FundsTransactionComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "limit-set",
          component:LimitSetComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "limit-translist",
          component:LimitTranslistComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "local-body-contribution",
          component:LocalBodyContributionComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "project",
          component:ProjectComponent,
          canActivate: [UserRouteAccessService]
        },
        {
          path: "level-type",
          component:LevelTypeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "level-master",
          component:LevelMasterComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "component-type",
          component:ComponentTypeComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "major-component",
          component:MajorComponentComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "minor-component",
          component:MinorComponentComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "sub-component",
          component:SubComponentComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "WorkPayment",
          component:PaymentModuleComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "PaymentVoucher",
          component:PaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "VendorPaymentVoucher",
          component:VendorPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "VendorPayment",
          component:VendorPaymentModuleComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: 'dynamicReport',
          component:DynamicReportTableComponent,
          canActivate: [UserRouteAccessService],
          pathMatch:"prefix" 
        },
        {
          path: "StatutoryPayment",
          component:StatutoryPaymentComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "StatutoryAccount",
          component:StatutoryAccountConfigComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "StatutoryPaymentList",
          component:StatutoryPaymentModuleComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "StatutoryPaymentVoucher",
          component:StatutoryPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: 'failureTransaction',
          component:FailureTransactionComponent,
          canActivate: [UserRouteAccessService],
          
        },
        {
          path: 'DisputedPaymentList',
          component:DisputedPaymentListComponent,
          canActivate: [UserRouteAccessService],          
        },
        {
          path: "drilldownpaymentstatus",
          component:PaymentStatusComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "drilldownvoucherstatus",
          component:VoucherStatusComponent,
          canActivate: [UserRouteAccessService] 
        },

        {
          path: "drilldownvendorstatus",
          component:VendorReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "drilldownlimitReport",
          component:LimitReportComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: 'tender-table',
          component: VendorMappingTableComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'tender-page',
          component: VendorMappingPageComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'miscellaneous-payment-table',
          component: MiscellaneousPaymentTableComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: 'miscellaneous-payment-page',
          component: MiscellaneousPaymentVoucherComponent,
          canActivate: [UserRouteAccessService],
        },
        {
          path: "EBPayment",
          component:EBPaymentModuleComponent,
          canActivate: [UserRouteAccessService] 
        },
        {
          path: "EBPaymentVoucher",
          component:EBPaymentVoucherComponent,
          canActivate: [UserRouteAccessService] 
        },
        { path: '**', redirectTo: '/error', pathMatch: 'full' },

        {
          path: "error",
          component:ErrorComponent												
        },
        
      ],
      { enableTracing: false }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }